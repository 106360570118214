import { createGlobalStyle, css } from "styled-components";

import fontEot from "./icons/icomoon.eot";
import fontSvg from "./icons/icomoon.svg";
import fontTtf from "./icons/icomoon.ttf";
import fontWoff from "./icons/icomoon.woff";

const icons = {
  close: "\\e90f",
  "arrow-nextPrev": "\\e90e",
  bookmark: "\\e900",
  facebook: "\\e901",
  lightbulb: "\\e902",
  linkedin: "\\e903",
  location: "\\e904",
  mail: "\\e905",
  menu: "\\e906",
  micro: "\\e907",
  pen: "\\e908",
  presselib: "\\e909",
  quote: "\\e90a",
  search: "\\e90b",
  twitter: "\\e90c",
  user: "\\e90d",
};

export default createGlobalStyle`
  @font-face {
    font-family: "icons";
    src: url("${fontEot}?gyvcmj");
    src: url("${fontEot}?gyvcmj#iefix") format("embedded-opentype"),
      url("${fontTtf}?gyvcmj") format("truetype"),
      url("${fontWoff}?gyvcmj") format("woff"),
      url("${fontSvg}?gyvcmj##icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${Object.entries(icons).map(
    ([key, character]) => css`
      .icon-${key}::before {
        content: "${character}";
      }
    `
  )}

`;
