import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";

const contactQuery = gql`
  mutation SendContact($data: ContactsPublicInput) {
    public {
      contacts {
        success: sendContact(data: $data)
      }
    }
  }
`;

export function useContact() {
  const [mutation, result] = useMutation(contactQuery);

  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "public.contacts.success"),
  ];
}
