import { Helmet } from "react-helmet";

import { url } from "../../../common/util.js";
import GoogleTagManager from "../../components/GoogleTagManager.jsx";
import MatomoAnalytics from "../../components/MatomoAnalytics.jsx";
import ScrollToTop from "../../components/ScrollToTop.jsx";
import Wysistat from "../../components/Wysistat.jsx";
import logoBase from "../../img/logo-presselib.png";
import { useNavigationIndex } from "../../services/navigation.js";

export default function Root({ children }) {
  const index = useNavigationIndex();

  const name = index.title;
  const indexUrl = `https://${index.parameters.hostname}${index.alias}`;
  const logo = url(indexUrl, logoBase);
  return (
    <>
      <ScrollToTop />
      {index && (
        <Helmet titleTemplate={`%s - ${index.title}`}>
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#e95035" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              //"@type": "NewsMediaOrganization", // Peut-être un souci avec le partage FB
              "@type": "Organization",
              name,
              url: indexUrl,
              logo,
              sameAs: [
                "https://www.facebook.com/pages/Presselib/1428634504037732",
                "https://www.instagram.com/presselib_/",
                "https://www.linkedin.com/company/presselib/",
                "https://www.youtube.com/channel/UC0pTnBQDYCfHMWr0UVGiMPg/",
                "https://twitter.com/PresseLib",
              ],
            })}
          </script>
        </Helmet>
      )}
      {Boolean(index?.parameters?.googleTagManagerId) && (
        <GoogleTagManager id={index.parameters.googleTagManagerId} />
      )}
      {Boolean(index?.parameters?.wysistatId) && (
        <Wysistat name={index.parameters.wysistatId} />
      )}
      {Boolean(index?.parameters?.matomoAnalyticsCloudDomain) && (
        <MatomoAnalytics domain={index.parameters.matomoAnalyticsCloudDomain} />
      )}
      {children}
    </>
  );
}
