import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";

import styled, { keyframes } from "styled-components";

import { usePwa } from "./Pwa.jsx";

import {
  getMessaging,
  getToken,
  deleteToken,
  onMessage,
} from "firebase/messaging";
import app from "./firebase.js";
import { useLocalStorage } from "./util/localStorage.js";
import { FormButton } from "./components/forms.jsx";
import { fcmToNotification } from "../common/notifications.js";
import Icon from "./components/Icon.jsx";
import { color, theme, z } from "./util/style.js";

const messaging = typeof window !== "undefined" && getMessaging(app);

const VAPID_KEY =
  "BG0_umDLzslzUApXd-GTQgPUQXW4y0NnsufGp8GdxSs2ZFbJqDoAzqeSDn_EnXqZjeYCSSb5qaZCCxH0S4xNPIw";

const NotificationsContext = createContext();

export function useNotifications() {
  return useContext(NotificationsContext);
}

export function NotificationsProvider({ children }) {
  /* 
    granted: permission accordée sur le navigateur
    denied: permission refusée par le navigateur
    unknown: permission non modifiée
    default: permission par défaut (toujours demander)
  */
  const navigatorPermission =
    typeof window === "undefined" || !window.Notification
      ? "denied"
      : window.Notification?.permission;

  /* 
    granted: permission accordée par l'utilisateur
    denied: permission révoquée par l'utilisateur
    unknown: permission non modifiée
  */
  const [userPermission, setUserPermission] = useLocalStorage(
    "notifications-status",
    "unknown"
  );

  const { registration } = usePwa();

  const [token, setToken] = useState();

  const [registerTokenOnServer] = useMutation(gql`
    mutation REGISTER($token: String!) {
      public {
        notifications {
          register(token: $token)
        }
      }
    }
  `);

  const [unregisterTokenFromServer] = useMutation(gql`
    mutation UNREGISTER($token: String!) {
      public {
        notifications {
          unregister(token: $token)
        }
      }
    }
  `);

  const register = useCallback(async () => {
    if (!registration || token) return;
    try {
      const token = await getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey: VAPID_KEY,
      });
      if (!token) return;

      setToken(token);
      await registerTokenOnServer({ variables: { token } });
    } catch (e) {
      // Don't do anything
    }
  }, [registration, token, registerTokenOnServer]);

  const unregister = useCallback(async () => {
    if (!registration || !token) return;
    try {
      const deleted = await deleteToken(messaging);
      if (!deleted) return;

      setToken(null);
      await unregisterTokenFromServer(token);
    } catch (e) {
      // Do nothing
    }
  }, [registration, token, unregisterTokenFromServer]);

  /*** NOTIFICATIONS ***/

  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(
    (notification) => {
      setNotifications((notifications) => [...notifications, notification]);
    },
    [setNotifications]
  );

  const removeNotification = useCallback(
    (notification) => {
      setNotifications((notifications) =>
        notifications.filter(({ id }) => id !== notification.id)
      );
    },
    [setNotifications]
  );

  /** Notification listener */
  useEffect(() => {
    if (typeof window === "undefined" || !token) return;

    const timeouts = [];
    const unsubscribe = onMessage(messaging, (payload) => {
      const notification = fcmToNotification(payload);
      addNotification(notification);
      timeouts.push(setTimeout(() => removeNotification(notification), 60000));
    });

    return () => {
      unsubscribe();
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [token, addNotification, removeNotification]);

  const toggleActive = useCallback(
    (active) => {
      setUserPermission(active ? "granted" : "denied");
    },
    [setUserPermission]
  );

  /** If user agreed, either via navigator or popin, let's try to display notifications */
  const active =
    userPermission === "granted" || navigatorPermission === "granted";

  useEffect(() => {
    if (active) register();
    else unregister();
  }, [active, register, unregister]);

  return (
    <>
      {/* If we already asked for permission, either via navigator or popin, don't ask again */
      navigatorPermission !== "granted" &&
        navigatorPermission !== "denied" &&
        userPermission !== "granted" &&
        userPermission !== "denied" && (
          <Popin
            onAccept={() => toggleActive(true)}
            onReject={() => toggleActive(false)}
          />
        )}
      {Boolean(notifications?.length > 0) && (
        <Notifications>
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              {...notification}
              onDelete={() => removeNotification(notification)}
            />
          ))}
        </Notifications>
      )}
      <NotificationsContext.Provider
        value={[
          { active: active, disabled: navigatorPermission === "denied" },
          toggleActive,
        ]}
      >
        {children}
      </NotificationsContext.Provider>
    </>
  );
}

const TitleContainer = styled.div`
  position: absolute;
  right: 0;
  top: -20px;
  display: flex;
  align-items: stretch;
  z-index: 1;
`;

const Title = styled.div`
  background-color: ${color("black")};
  color: ${color("white")};
  display: inline-block;
  font-family: ${theme("fonts.secondary")};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2em;
  padding: 0.35em 0.7em;
  height: 40px;
`;

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${color("greyBg")};
  transition: background-color 0.3s ease;

  &:hover {
    background: ${color("greyBgDarker")};
  }
`;

const CloseButtonPopin = styled(CloseButton)`
  background: ${color("greyBg")};

  &:hover {
    background: ${color("greyBgDarker")};
  }
`;

const CloseButtonNotification = styled(CloseButton)`
  background: ${color("orange")};
  &:hover {
    --icon-hover: 1;
    background: ${color("orangeDarker")};
  }
`;

const Popin = styled(function({ onAccept, onReject, className }) {
  return (
    <div className={className}>
      <TitleContainer>
        <Title>Notifications</Title>
        <CloseButtonPopin onClick={onReject}>
          <Icon name="close" size={24} variant="black" />
        </CloseButtonPopin>
      </TitleContainer>
      <p>
        Vous pouvez désormais recevoir des alertes sur votre appareil lorsqu'un
        nouvel article important est publié !
      </p>
      <div className="Popin-actions">
        <FormButton onClick={onAccept} white>
          Ça m'intéresse !
        </FormButton>
      </div>
    </div>
  );
})`
  background: ${color("orange")};
  color: white;
  max-width: 300px;
  padding: 30px;
  position: fixed;
  right: 0;
  top: 100px;
  width: 100%;
  z-index: ${z("popin")};

  .Popin-actions {
    margin-top: 12px;
    text-align: center;
  }
`;

const notificationAppears = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const Notifications = styled.div`
  position: fixed;
  top: 100px;
  right: 0;
  z-index: ${z("popin")};
  max-width: 300px;
  width: 100%;
`;

const Notification = styled(function({
  href,
  title,
  body,
  onDelete,
  className,
}) {
  return (
    <div className={className}>
      <TitleContainer>
        <CloseButtonNotification onClick={onDelete}>
          <Icon
            name="alert"
            size={24}
            hoverName="close"
            variant="white"
            hoverVariant="white"
          />
        </CloseButtonNotification>
      </TitleContainer>
      <a href={href} rel="noopener noreferrer">
        <div className="Notification-title">{title}</div>
        <div className="Notification-content">{body}</div>
      </a>
    </div>
  );
})`
  animation: ${notificationAppears} 0.3s;
  background: ${color("greyBg")};
  cursor: pointer;
  display: block;
  margin-bottom: 30px;
  padding: 12px;
  transition: all 0.3s;
  position: relative;

  &:hover {
    background: ${color("greyBgDarker")};
  }

  a {
    color: ${color("black")};
  }

  .Notification-title {
    font-weight: bold;
  }
  .Notification-content {
    font-size: 12px;
  }
`;
