import Ads from "../../components/Ads.jsx";
import { Col, Row } from "../../components/layout.jsx";
import Tiles from "../../components/Tiles.jsx";
import { useAds } from "../../services/ads.js";
import { useNavigation } from "../../services/navigation.js";
import { color, context, styled } from "../../util/style.js";
import SimplePageTemplate from "./SimplePageTemplate.jsx";

const Sidebar = styled.div`
  padding-left: 60px;
  ${context("for-desktop")`
    padding-left: 24px;
  `}
  ${context("for-tablet-landscape")`
    border-top: 1px solid ${color("border")};
    margin-top: 48px;
    padding-top: 40px;
    padding-left: 0;
  `}
`;

const Lede = styled.div`
  margin-bottom: 40px;
  color: ${color("greyTag")};
  font-size: 14px;
  line-height: 22px;
`;

export default function TwoColumnsPageTemplate({
  summary,
  children,
  ...props
}) {
  const [{ page, template }] = useNavigation();
  const [ads] = useAds([
    `${page.kind}:top`,
    `${page.kind}:bottom`,
    "default:top",
    "default:bottom",
  ]);

  return (
    <SimplePageTemplate
      {...props}
      Top={
        <>
          <Ads list={ads} tag={`${page.kind}:top`} tagDefault="default:top" />
          {props.Top}
        </>
      }
      Bottom={
        <>
          <Ads
            list={ads}
            tag={`${page.kind}:bottom`}
            tagDefault="default:bottom"
          />
        </>
      }
    >
      <Row>
        <Col kind="main" as="main">
          {Boolean(summary || page.summary) && (
            <Lede>{summary || page.summary}</Lede>
          )}
          {children}
        </Col>
        <Col kind="aside" as="aside">
          <Sidebar>
            <Tiles aside tiles={template?.asideTiles} />
          </Sidebar>
        </Col>
      </Row>
    </SimplePageTemplate>
  );
}
