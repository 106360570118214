import { useEffect, useRef } from "react";

const scriptRegExp = /<(?:[^:]+:)?script([^>]*?)>(.*?)<\/(?:[^:]+:)?script>/gims;
function htmlSplitScripts(htmlOriginal) {
  const matches = [...(htmlOriginal || "").matchAll(scriptRegExp)];
  return [
    matches.map(([_, attrsStr, content]) => {
      const attributes = attrsStr
        .trim()
        .split(/\s+/)
        .filter(Boolean)
        .map((attrStr) => attrStr.split("=", 2))
        .reduce(
          (all, [prop, value]) => ({
            ...all,
            [prop]:
              typeof value === "undefined"
                ? prop
                : (value || "").replace(/^['"]/, "").replace(/['"]$/, ""),
          }),
          {}
        );
      return {
        ...attributes,
        content,
      };
    }),
    matches.reduce(
      (html, [scriptStr]) => html.replace(scriptStr, ""),
      htmlOriginal || ""
    ),
  ];
}

// https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx

export function Script({ insertIntoRef, content, src, ...props }) {
  useEffect(() => {
    if (typeof window === "undefined") return;

    if (content) {
      const evaluateScript = new Function(content);
      evaluateScript();
      return;
    }

    const insertInto = insertIntoRef ? insertIntoRef.current : document.body;
    if (!insertInto || !src) return;

    const script = document.createElement("script");
    if (src) {
      script.setAttribute("src", src);
    }
    for (let attr in props) {
      script.setAttribute(attr, props[attr]);
    }
    insertInto.appendChild(script);
    return () => {
      insertInto.removeChild(script);
    };
  }, [src, content, props]);

  return null;
}

export default function HtmlCode({ content }) {
  const block = useRef();
  const [scripts, html] = htmlSplitScripts(content);
  return (
    <>
      <div ref={block} dangerouslySetInnerHTML={{ __html: html }} />
      {scripts.map((scriptProps, index) => (
        <Script
          key={`script-${index}`}
          insertIntoRef={block}
          {...scriptProps}
        />
      ))}
    </>
  );
}
