import { useMemo } from "react";

import { format } from "../../common/date.js";
import dashedBorder from "../img/motif-lines.svg";
import { useCheckFavorite } from "../services/articles.js";
import { getImageProps } from "../util/medias.js";
import {
  color,
  context,
  font,
  ifelse,
  is,
  isnot,
  styled,
  theme,
} from "../util/style.js";
import ArticleKind from "./ArticleKind.jsx";
import PageLink from "./PageLink.jsx";
import TagList from "./TagList.jsx";
import ToggleFavoriteBase from "./ToggleFavorite.jsx";

const Tag = styled(function({ className, ...props }) {
  return <span className={`tag ${className}`} {...props} />;
})`
  color: ${color("orange")};
  font-size: 0.7em;
  font-weight: bold;
  padding-right: 0.5em;
  text-transform: uppercase;
`;

export function TitleWithTag({ children: title }) {
  const [tag, titleWithoutTag] = title.split(/ [-—–] /, 2);
  return tag && titleWithoutTag ? (
    <>
      <Tag>{tag}</Tag>
      {titleWithoutTag}
    </>
  ) : (
    title
  );
}

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  align-self: flex-start;

  ${context("for-tablet-landscape")`
    float: left;
    margin-right: 16px;
    max-width: 160px;
  `}
  ${context("for-phone")`
    max-width: 100px;
  `}

  a {
    display: block;
    position: relative;
  }
`;

const TitleHeadlineContainer = styled.div`
  bottom: 0;
  margin: 32px;
  position: absolute;
  z-index: ${theme("z.titleHeadline")};
  ${context("for-phone")`
    margin: 16px 24px;
  `}
`;

const TitleHeadline = styled(function({ children, className }) {
  return (
    <h2 className={className}>
      <TitleWithTag>{children}</TitleWithTag>
    </h2>
  );
})`
  background-color: ${color("orange")};
  box-shadow: 10px 0 0 ${color("orange")}, -10px 0 0 ${color("orange")};
  box-decoration-break: clone;
  color: white;
  display: inline;
  font-size: 34px;
  font-weight: 700;
  line-height: 45px;
  padding: 2px 0;
  word-wrap: break-word;
  ${is("mini")`
    font-size: 28px;
  `}
  ${context("for-tablet-landscape")`
    font-size: 24px;
    line-height: 35px;
    ${is("mini")`
      font-size: 20px;
    `}
  `}
  ${context("for-phone")`
    display: none;
  `}
  .tag {
    color: ${color("orange")};
    background: white;
    font-size: 0.5em;
    padding: 0;
    box-shadow: 10px 0 0 white,-10px 0 0 white;
    float: left;
    margin-top: -45px;
  }
`;

const Image = styled.img`
  background-color: ${color("image.placeholder")};
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease;
  object-fit: cover;
  width: 100%;
`;

const ImageBorder = styled.figure`
  border-bottom: 5px solid ${color("border")};
  display: block;
  position: relative;
  &::after {
    background-color: ${color("orange")};
    bottom: -5px;
    content: "";
    display: block;
    height: 5px;
    max-width: 120px;
    position: absolute;
    width: 30%;
  }

  &:hover {
    ${Image} {
      opacity: 0.7;
    }
  }
`;

const ImageMask = styled.div`
  overflow: hidden;
  position: relative;
  padding-top: 56.25%; /* 16/9 */
`;

const TextContainer = styled.div`
  flex: 1;
  ${context("for-tablet-landscape")`
    overflow: hidden;
  `}
`;

const TitleLink = styled(PageLink)``;

const Title = styled(function({ children, className }) {
  return (
    <h2 className={className}>
      <TitleWithTag>{children}</TitleWithTag>
    </h2>
  );
})`
  color: ${color("black")};
  display: block;
  font-size: 18px;
  line-height: 22px;
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    transition: color 0.3s ease;
  }
  ${is("mini")`
    font-size: 16px;
  `}
  ${context("for-phone")`
    font-size: 14px;
    line-height: 20px;
  `}
`;

const Description = styled.div`
  color: ${color("greyTxt")};
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  ${context("for-phone")`
    display: none;
  `}
`;
const Date = styled.div`
  color: ${color("greyTxt")};
  font-family: ${font("secondary")};
  font-size: 14px;
  line-height: 20px;
  margin-top: 0;
`;

const TagsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  ${context("for-phone")`
    margin-top: 8px;
  `}
`;

const ArticleKindContainer = styled.div`
  ${isnot("noImage")`
    position: absolute;
    z-index: ${theme("z.articleKind")};
    top: 16px;
    left: 16px;
    ${context("for-tablet-landscape")`
      left: 0;
      top: 0;
      width: 100%;
    `}
  `}
  ${is("smallWidth")`
    height: 6px;
    overflow: hidden;
    transition: height ease 150ms;
    width: 100%;
    &:hover {
      height: 100%;
    }
  `}
`;

const MainTitle = styled.h1`
  background-color: ${color("black")};
  color: ${color("white")};
  display: inline-block;
  font-family: ${font("secondary")};
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2em;
  position: relative;
  top: 1.175em;
  margin-top: -1.175em;
  padding: 0.35em 0.7em;
  z-index: 1;

  ${context("for-tablet-landscape")`
  font-size: 24px;
`}

  ${context("for-phone")`
  font-size: 20px;
`}

&:after {
    background-color: white;
    background-image: url(${dashedBorder});
    background-repeat: repeat;
    background-size: 4px 4px;
    content: "";
    height: 10px;
    display: block;
    margin: 0.35em -0.7em -0.35em -0.7em;
  }
`;

const ToggleFavorite = styled(ToggleFavoriteBase)`
  float: left;
  margin-left: -6px;
  margin-right: 0px;
`;

export default styled(function ElementInList({
  id,
  kind,
  page,
  pageParameters,
  featured,
  title,
  date,
  withDate,
  summary,
  image,
  tags,
  hideFavorite,
  className,
  mini = false,
  noImage = false,
  smallWidth,
  mainTitle,
}) {
  const [favorite, { refetch: refetchCheckFavorite }] = useCheckFavorite(id);

  const ArticleKindContainerPrepared = useMemo(
    () =>
      function ArticleKindContainerPrepared({ children }) {
        return (
          <ArticleKindContainer noImage={noImage} smallWidth={smallWidth}>
            {children}
          </ArticleKindContainer>
        );
      },
    [noImage, smallWidth]
  );
  return (
    <>
      {!!mainTitle && <MainTitle>{mainTitle}</MainTitle>}
      <article className={className}>
        {!noImage && (
          <ImageContainer>
            <PageLink
              to={page}
              parameters={pageParameters}
              // eslint-disable-next-line no-irregular-whitespace
              aria-label={`Lire l'article « ${title} »`}
            >
              <ArticleKind
                kind={kind}
                container={ArticleKindContainerPrepared}
              />
              {featured && (
                <TitleHeadlineContainer>
                  <TitleHeadline mini={mini}>{title}</TitleHeadline>
                </TitleHeadlineContainer>
              )}
              <ImageBorder>
                <ImageMask>
                  <Image
                    loading="lazy"
                    width={mini ? 188 : 750}
                    height={mini ? 105 : 420}
                    {...getImageProps(image, mini ? "list-mini" : "list")}
                  />
                </ImageMask>
              </ImageBorder>
            </PageLink>
          </ImageContainer>
        )}
        <TextContainer>
          <TitleLink
            to={page}
            parameters={pageParameters}
            // eslint-disable-next-line no-irregular-whitespace
            aria-label={`Lire l'article « ${title} »`}
          >
            <Title mini={mini}>{title}</Title>
          </TitleLink>
          {!smallWidth && (
            <>
              {Boolean(withDate) && Boolean(date) && (
                <Date>Publié le {format(date, "dd MMMM yyyy")}</Date>
              )}
              <Description>{summary}</Description>
              <TagsContainer>
                {Boolean(!hideFavorite) && (
                  <ToggleFavorite
                    simple
                    size={22}
                    favorite={favorite}
                    article={id}
                    onUpdate={() => refetchCheckFavorite()}
                  />
                )}
                <TagList orange tags={tags} />
              </TagsContainer>
            </>
          )}
        </TextContainer>
      </article>
    </>
  );
})`
  ${is("mini")`
    display: flex;
    flex-direction: row;
    align-items: center;

    overflow: hidden;
    width: 100%;
    ${ImageContainer} {
      margin-bottom: 0;
      margin-right: 16px;
      max-width: 160px;
    }
    ${Description} {
      margin-top: 8px;
    }
    ${Date} {
      margin-top: 0;
    }
    ${TagsContainer} {
      margin-top: 8px;
    }
    ${TextContainer} {
      overflow: hidden;
    }
    ${isnot("noImage")`
      ${ArticleKindContainer} {
        top: 0px;
        left: 0px;
      }
    `}
    ${isnot("image")`
      ${ImageBorder} {
        border-bottom-color: transparent;
        &::after {
          display: none;
        }
      }
    `}

    ${context("for-tablet-portrait")`
      ${ImageContainer} {
        display: none;
      }
    `}

    ${context("for-phone")`
      ${Description} {
        display: none;
      }
      ${Title} {
        font-weight: normal;
      }
    `}
    ${is("smallWidth")`
      ${ImageContainer} {
        order: 2;
        margin-bottom: 0;
        margin-right: 0;
        margin-left: 8px;
        max-width: 100px;
      }
      ${Title} {
        font-weight: normal;
      }
    `}
  `}

  ${is("featured")`  
    ${context("for-tablet-landscape")`
      display: block;
    `}
    ${ImageContainer} {
      float: none;
      ${context("for-tablet-landscape")`
        margin-right: 0;
        max-width: none;
      `}
      ${context("for-phone")`
        margin-bottom: 16px;
      `}
    }
    ${TitleLink} {
      display: none;
      ${context("for-phone")`
        display: block;
      `}
    }
    ${Description} {
      color: ${color("black")};
      ${context("for-phone")`
        display: block;
      `}
    }
    ${ArticleKindContainer} {
      left: initial;
      right: 16px;
      top: 16px;
    }
    ${ifelse("mainTitle")`  
      ${context("for-tablet-landscape")`
        ${ArticleKindContainer} {
          left: initial;
          right: 16px;
          top: 16px;
          width: auto;
        }
      `}
      ${context("for-phone")`
        ${ArticleKindContainer} {
          left: 16px;
          right: initial;
          top: 42px;
          width: auto;
        }
      `} 
    ``
      ${context("for-tablet-landscape")`
        ${ArticleKindContainer} {
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
        }
      `}
    `}
  `}

  ${is("aside")`
    ${Title} {
      font-size: 15px;
    }
  `}
`;
