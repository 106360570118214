import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import _pick from "lodash/pick";
import _mapValues from "lodash/mapValues";

import {
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import { Col, Row } from "../components/layout.jsx";
import {
  Form,
  FormButton,
  FormField,
  FormInput,
} from "../components/forms.jsx";
import Tiles from "../components/Tiles.jsx";
import { useNavigationPage } from "../services/navigation.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";
import { useUserPasswordResetRequest } from "../services/auth.js";
import ConfirmationPopup from "../components/ConfirmationPopup.jsx";

const PasswordResetRequestForm = styled(Form)`
  max-width: 555px;
  margin-bottom: 40px;
`;

function usePasswordResetRequestForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(Yup.object().shape({})),
  });
}

export default function PasswordResetRequest() {
  const page = useNavigationPage();
  const [success, setSuccess] = useState(false);

  const {
    register,
    setError,
    watch,
    clearErrors,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = usePasswordResetRequestForm();

  const [
    userPasswordResetRequest,
    userPasswordResetRequestResult,
    { loading, called, graphQLErrors: passwordResetRequestErrors },
  ] = useUserPasswordResetRequest();

  const onSubmit = useCallback(
    function(data) {
      userPasswordResetRequest(data.email);
    },
    [clearErrors, userPasswordResetRequest]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(passwordResetRequestErrors).forEach((err) => {
        switch (err.code) {
          default:
            setError("_global", err);
        }
      });
      filterBadUserInputErrors(passwordResetRequestErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (userPasswordResetRequestResult === false) {
        setError("_global", {
          message:
            "La demande de réinitialisation du mot de passe a échoué. Veuillez réessayer plus tard.",
        });
      }
      if (
        hasErrors(passwordResetRequestErrors) ||
        !userPasswordResetRequestResult
      )
        return;

      // en cas de succès, affiche une popup
      setSuccess(true);

      // clear le form
      try {
        reset();
      } catch (e) {
        console.log(e);
      }
    },
    [loading, userPasswordResetRequestResult, passwordResetRequestErrors]
  );

  return (
    <TwoColumnsPageTemplate>
      <ConfirmationPopup
        title="Demande de réinitialisation de mot de passe"
        open={success}
        onClose={() => setSuccess(false)}
      >
        Un email vous a été envoyé avec la procédure pour réinitialiser votre
        mot de passe. Si vous ne le recevez pas, vérifiez vos courriers
        indésirables ou que l'adresse que vous avez saisie est correcte.
      </ConfirmationPopup>
      <PasswordResetRequestForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        errors={errors}
      >
        <Row>
          <Col span={8} sm={12} fixed>
            <FormField
              label="Adresse email"
              required
              errors={errors}
              name="email"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : c.vaultier@email.com"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
        </Row>
        <FormButton type="submit">Valider</FormButton>
      </PasswordResetRequestForm>

      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
