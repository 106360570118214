import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import { paginatedGql, toStandardResult } from "../util/graphql.js";

const searchResultSchema = `
  id
  alias
  kind
  title
  summary
  image { id name version credits description }
  tags
  date
`;

const searchQuery = gql`
  query Search($filter: SearchFilterPublicInput, $pagination: PaginationInput) {
    public {
      search(filter: $filter, pagination: $pagination) {
        ${paginatedGql(searchResultSchema)}
      }
    }
  }
`;

export function useSearch({ search } = {}, pagination) {
  const result = useQuery(searchQuery, {
    variables: {
      pagination,
      filter: {
        ...(!!search && { search }),
      },
    },
    skip: !search,
  });

  return toStandardResult(result, "public.search");
}
