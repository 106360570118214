import typographyStyle from "../../../../../app/templates/common/TypographyStyle.jsx";
import { getImageProps } from "../../../../../app/util/medias.js";
import { color, context, is, styled } from "../../../../../app/util/style.js";
import { Image } from "../image/ReactImageTile.jsx";

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;

  & > .column:first-child {
    flex: 2;
    flex-basis: 66%;
  }
  & > .column:last-child {
    flex: 1;
    flex-basis: 33%;
  }

  margin: 0 -10px;
  & > .column {
    margin: 0 10px;
  }

  ${context("for-phone")`
    flex-direction: column-reverse;

    margin: -10px 0;
    & > .column {
      margin: 10px 0;
    }
  `}
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 8px;
  color: white;
`;

const Text = styled.div`
  ${typographyStyle}

  &,
  & > * {
    font-size: 14px !important;
    line-height: 22px !important;
    color: white !important;
  }
`;

export default styled(function ReactPortraitTile({ tile, options, className }) {
  return (
    <section className={`${className || ""} tile-container tile-portrait`}>
      <Container>
        <div className="column">
          {!!tile.title && <Title>{tile.title}</Title>}
          <Text dangerouslySetInnerHTML={{ __html: tile.text }} />
        </div>
        {!!tile.withImage && (
          <div className="column">
            <Image image={tile.image} caption={tile.mediaCaption} />
          </div>
        )}
      </Container>
    </section>
  );
})`
  background-color: ${color("green")};
  padding: 24px;

  ${is("options.aside")`
    ${Container} {
      ${context("for-desktop")`
        flex-direction: column-reverse;  
        margin: -10px 0;
        & > .column {
          margin: 10px 0;
        }
      `}
      ${context("for-tablet-landscape")`
        flex-direction: row-reverse;  
        margin: 0 -10px;
        & > .column {
          margin: 0 10px;
        }
      `}
      ${context("for-phone")`
        flex-direction: column-reverse;  
        margin: -10px 0;
        & > .column {
          margin: 10px 0;
        }
      `}
    }
  `}
`;
