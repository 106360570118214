import { NavLink } from "react-router-dom";

import { buildPath } from "../../common/alias.js";

export default function PageLink({ to, parameters = {}, children, ...props }) {
  if (!to) {
    return (
      <a href="#" {...props}>
        {children}
      </a>
    );
  }

  let href = buildPath(to.alias, parameters);

  // Raccourcis pour les redirections
  switch (to.kind) {
    case "redirection":
      if (!to.parameters) break; // au cas où on n'a pas les paramètres, on peut utiliser le lien direct
      switch (to.parameters.kind) {
        case "page":
          if (to.parameters.page) {
            href = buildPath(to.parameters.page.alias, parameters);
          }
          break;
        case "url": {
          href = to.parameters.url;
          let target = to.parameters.target === "_blank" ? "_blank" : "";
          return (
            <a href={href} target={target} rel="nofollow noreferrer" {...props}>
              {children || to.title || href}
            </a>
          );
        }
      }
      break;
  }

  const rel = (to?.parameters?.page || to).hideFromSearchEngine
    ? "nofollow"
    : null;

  return (
    <NavLink to={href} rel={rel} exact={href === "/"} {...props}>
      {children || to.title || ""}
    </NavLink>
  );
}
