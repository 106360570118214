import { context, color, font, styled } from "../util/style.js";
import BasePageLink from "./PageLink.jsx";
import Icon from "./Icon.jsx";

const Page = styled.li``;

const PageLink = styled(BasePageLink)`
  color: ${color("orange")};
  transition: color 0.3s ease;
  &:hover {
    text-decoration: underline;
  }
`;

const PagesList = styled(function({ pages, className }) {
  return (
    <ul className={className}>
      {pages.map((page) => (
        <Page key={page.id}>
          <PageLink to={page}>{page.title || page.name}</PageLink>
        </Page>
      ))}
    </ul>
  );
})`
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;

  & > ${Page} + ${Page}::before {
    background-color: ${color("greyTag")};
    border-radius: 50px;
    content: "";
    display: inline-block;
    margin: 0 6px;
    height: 4px;
    position: relative;
    top: -2px;
    width: 4px;
  }
`;

const Title = styled.div`
  font-weight: 700;
`;

const BookmarkIcon = styled(Icon)`
  vertical-align: middle;
`;

export default styled(function({ title, pages = [], className }) {
  return (
    <div className={className}>
      {!!title && (
        <Title>
          <BookmarkIcon name="bookmark" variant="black" size={24} />
          {title}
        </Title>
      )}
      <PagesList pages={pages} />
    </div>
  );
})`
  background-color: ${color("greyBg")};
  display: inline-flex;
  padding: 1.35em;
  ${context("for-tablet-landscape")`
    padding: 1em;
  `}
`;
