import PagesGrid from "../components/PagesGrid.jsx";
import Separator from "../components/Separator.jsx";
import Tiles from "../components/Tiles.jsx";
import { useNavigationPage } from "../services/navigation.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

export default function Group() {
  const page = useNavigationPage();

  const subPages = (page.children || []).filter(
    (page) => !page.hideFromNavigation
  );

  return (
    <TwoColumnsPageTemplate>
      {subPages.length > 0 && (
        <>
          <PagesGrid pages={subPages} />
          <Separator />
        </>
      )}
      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
