import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import HttpStatusRoute from "../components/HttpStatusRoute.jsx";

import PageRedirect from "../components/PageRedirect.jsx";
import { useNavigationPageParameters } from "../services/navigation.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

export default function Redirection() {
  const pageParameters = useNavigationPageParameters();
  useEffect(
    function() {
      if (
        pageParameters.kind === "url" &&
        pageParameters.url &&
        pageParameters.target === "_blank"
      ) {
        window.open(pageParameters.url);
      }
    },
    [pageParameters]
  );

  const pageRedirect =
    (pageParameters.kind === "page" && pageParameters.page) || null;

  switch (pageParameters.kind) {
    case "page": {
      if (!pageRedirect) return <span>Redirection en cours...</span>;
      return <PageRedirect to={pageRedirect} />;
    }
    case "url":
      switch (pageParameters.target) {
        case "_blank":
          /* eslint-disable react/jsx-no-target-blank */
          return (
            <TwoColumnsPageTemplate>
              <span>
                La page{" "}
                <a href={pageParameters.url} target="_blank">
                  {pageParameters.url}
                </a>{" "}
                a été ouverte dans une nouvelle fenêtre.
              </span>
            </TwoColumnsPageTemplate>
          );
        case "":
        default:
          return (
            <>
              <TwoColumnsPageTemplate>
                <span>
                  La page{" "}
                  <a href={pageParameters.url} target="_blank">
                    {pageParameters.url}
                  </a>{" "}
                  va s'ouvrir dans cette fenêtre...
                </span>
              </TwoColumnsPageTemplate>
              <HttpStatusRoute statusCode={302} url={pageParameters.url} />
            </>
          );
      }
  }
}
