import { useLogin } from "../../components/auth/Login.jsx";
import Icon from "../../components/Icon.jsx";
import { Col, Row } from "../../components/layout.jsx";
import PageLink from "../../components/PageLink.jsx";
import { useUserSession } from "../../services/auth.js";
import {
  useNavigationIndexParameters,
  useOneOfAKindPage,
} from "../../services/navigation.js";
import { color, styled } from "../../util/style.js";
import Contacts from "./Contacts.jsx";
import TypographyStyle from "./TypographyStyle.jsx";

const FooterNavTitle = styled.div`
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 32px;
`;

const FooterNavTitleIcon = styled(Icon)`
  margin: -28px -28px -28px 0;
  vertical-align: middle;
`;

const FooterNavListItemLink = styled(PageLink)`
  color: white;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
    text-decoration: underline;
    transition: opacity 0.3s ease;
  }
`;

const FooterNavTitleLink = styled(PageLink)`
  &:hover {
    text-decoration: underline;
  }
`;

const FooterNavTitleButton = styled.button`
  &:hover {
    text-decoration: underline;
  }
`;

const FooterNavListItem = styled(function({ children, to, className }) {
  return (
    <li className={className}>
      <FooterNavListItemLink to={to}>{children}</FooterNavListItemLink>
    </li>
  );
})``;

const FooterNavList = styled.ul`
  & > ${FooterNavListItem} {
    margin-bottom: 12px;
  }
`;

const ContactsContainer = styled.div`
  margin-top: 32px;
`;

const Typography = styled.div`
  ${TypographyStyle}
  a {
    color: white;
    font-weight: bolder;
  }
`;

export default styled(function FooterNav({ className }) {
  const { about, mainMenu, featuredPage } = useNavigationIndexParameters();
  const [pageAccount] = useOneOfAKindPage("privateArea");
  const { openPopin } = useLogin();
  const [session] = useUserSession();

  const privateAreaProps = !!session
    ? { to: pageAccount, as: FooterNavTitleLink }
    : { onClick: () => openPopin(), as: FooterNavTitleButton };

  return (
    <nav className={className}>
      <Row>
        <Col span="3" md="hidden">
          <FooterNavTitle>Les actualités</FooterNavTitle>
          <FooterNavList>
            {(mainMenu || [])
              .filter((page) => !page.hideFromNavigation)
              .map((page) => (
                <FooterNavListItem key={page.id} to={page}>
                  {page.title || page.name}
                </FooterNavListItem>
              ))}
          </FooterNavList>
        </Col>
        <Col span="4" md="hidden">
          <div>
            <FooterNavTitle {...privateAreaProps}>
              Votre espace lecteur
              <FooterNavTitleIcon size={32} name="user" variant="white" />
            </FooterNavTitle>
          </div>
          {!!featuredPage && !featuredPage.hideFromNavigation && (
            <>
              <FooterNavTitle as={FooterNavTitleLink} to={featuredPage}>
                {featuredPage.title || featuredPage.name}
              </FooterNavTitle>
              <FooterNavList>
                {(featuredPage.children || [])
                  .filter((page) => !page.hideFromNavigation)
                  .map((page) => (
                    <FooterNavListItem key={page.id} to={page}>
                      {page.title || page.name}
                    </FooterNavListItem>
                  ))}
              </FooterNavList>
            </>
          )}
        </Col>
        <Col span="5" md="12">
          <FooterNavTitle>À propos</FooterNavTitle>
          <Typography dangerouslySetInnerHTML={{ __html: about || "" }} />
          <ContactsContainer>
            <Contacts footer />
          </ContactsContainer>
        </Col>
      </Row>
    </nav>
  );
})`
  display: block;
  margin-bottom: 40px;
`;
