import Icon from "../../../../../app/components/Icon.jsx";
import PageLink from "../../../../../app/components/PageLink.jsx";
import { useOneOfAKindPage } from "../../../../../app/services/navigation.js";
import { color, context, styled } from "../../../../../app/util/style.js";
import dashedBorder from "./assets/motif-lines.svg";

const LinkIcon = styled(Icon)`
  margin-left: -48px;
  vertical-align: middle;
  ${context("for-tablet-landscape")`
    display: none;
  `}
`;

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Label = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${color("orange")};
`;

const Link = styled(PageLink)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${color("black")};
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    text-decoration: underline;
    transition: color 0.3s ease;
  }
`;

export default styled(function ReactLinkedArticleTile({
  tile,
  options,
  className,
}) {
  const [newsDetailPage] = useOneOfAKindPage("article");
  const article = tile.article;

  return (
    !!article && (
      <div className={`${className || ""} tile-container tile-linked-article`}>
        {!options.aside && (
          <LinkIcon name="presselib" variant="red" size={48} />
        )}
        <Container>
          <Label>À lire aussi : </Label>
          <Link to={newsDetailPage} parameters={{ alias: article.alias }}>
            {article.name}
          </Link>
        </Container>
      </div>
    )
  );
})`
  position: relative;
  &::before,
  &::after {
    content: "";
    background-image: url(${dashedBorder});
    background-repeat: repeat;
    background-size: 4px 4px;
    display: block;
    height: 10px;
    width: 100%;
  }
  &::before {
    margin-bottom: 24px;
  }
  &::after {
    margin-top: 24px;
  }
`;
