import imagePlaceholder from "../img/presselib-placeholder.png";

const cdnUri = (
  (process.env.CDN_URI || process.env.WEBSITE_URI || "") + "/"
).replace(/\/\/$/, "/");

export function getImageProps(image, mode = "original", useImageSize = false) {
  return image
    ? {
        src: getMediaUrl(image, mode),
        ...(mode !== "original" && {
          srcSet: `${getMediaUrl(image, mode + "@2x")} 2x`,
        }),
        alt: image.description || "",
        ...(useImageSize && image.width && image.height
          ? { width: image.width, height: image.height }
          : {}),
      }
    : {
        width: 1500,
        height: 840,
        src: imagePlaceholder,
      };
}

export function getMediaUrl(image, mode = "original") {
  return image
    ? `${cdnUri}media/${image.id}/${mode}/${image.version ||
        image.hash ||
        (image.updatedAt || "<no-date>").toString(36)}-${image.name}`
    : undefined;
}

const ellipsis = "...";
export function truncateName(name = "", max = 40) {
  if (name.length < max) return name;
  const maxStart = Math.round(((max - ellipsis.length) * 2) / 3);
  const maxEnd = max - ellipsis.length - maxStart;
  return name.substr(0, maxStart - 1) + ellipsis + name.substr(-(maxEnd - 1));
}
