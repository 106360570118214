import logoWithBaseline from "../../img/logo-presselib-baseline.png";
import logoWithBaseline2x from "../../img/logo-presselib-baseline@2x.png";
import logoClassic from "../../img/logo-presselib.png";
import logoClassic2x from "../../img/logo-presselib@2x.png";
import { context, styled } from "../../util/style.js";

export const LogoWithBaseLine = styled.img.attrs({
  width: 366,
  height: 95,
  src: logoWithBaseline,
  srcSet: `${logoWithBaseline2x} 2x`,
})`
  display: block;
  height: auto;
  max-width: 100%;
  ${context("for-tablet-landscape")`
    display: none;
  `}
  /* prevent blur on scale */
  transform: translateZ(0);
  image-rendering: -webkit-optimize-contrast;
  will-change: transform;
`;

export const LogoClassic = styled.img.attrs({
  width: 250,
  height: 52,
  src: logoClassic,
  srcSet: `${logoClassic2x} 2x`,
})`
  display: block;
  height: auto;
  max-width: 100%;
  display: none;
  ${context("for-tablet-landscape")`
    display: block;
  `}
  /* prevent blur on scale */
  transform: translateZ(0);
  image-rendering: -webkit-optimize-contrast;
  will-change: transform;
`;
