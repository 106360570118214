import { color, font, is, styled } from "../util/style.js";

import Icon from "../components/Icon.jsx";
import Article from "./ArticleInList.jsx";
import PageLink from "./PageLink.jsx";
import { useOneOfAKindPage } from "../services/navigation.js";

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 12px;
  border-bottom: 1px solid ${color("border")};
`;

const TitleIcon = styled(Icon)`
  color: ${color("orange")};
  margin-right: 4px;
`;

const Title = styled.h2`
  font-family: ${font("secondary")};
  font-size: 22px;
  line-height: 24px;

  ${is("aside")`
    font-size: 18px;
  `}
`;

const List = styled.div`
  clear: both;
  & > * {
    clear: both;
    border-bottom: 1px solid ${color("border")};
    padding: 12px 0;
  }
`;

const MoreLinkContainer = styled.div`
  margin-top: 8px;
  text-align: right;
`;
const MoreLink = styled.a`
  color: ${color("greyTag")};
  font-family: ${font("secondary")};
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    transition: color 0.3s ease;
  }
`;

const NoArticle = styled.div`
  margin-bottom: 20px;
`;

export default function SimpleArticlesList({
  icon,
  empty,
  articles,
  title,
  linkTo,
  linkLabel,
  hideFavorite,
}) {
  return (
    <>
      <TitleContainer>
        <TitleIcon name={icon} size={26} variant="red" />
        <Title>{title}</Title>
      </TitleContainer>
      <List>
        {articles?.map((article) => (
          <Article
            {...article}
            key={article.id}
            mini
            featured={false}
            hideFavorite={hideFavorite}
          />
        ))}
        {!articles?.length && <NoArticle>{empty}</NoArticle>}
      </List>
      {Boolean(linkTo && linkLabel) && (
        <MoreLinkContainer>
          <MoreLink as={PageLink} to={linkTo}>
            {linkLabel}
          </MoreLink>
        </MoreLinkContainer>
      )}
    </>
  );
}
