import styled from "styled-components";
import { FormButton } from "../components/forms.jsx";
import PagesGrid from "../components/PagesGrid.jsx";
import Separator from "../components/Separator.jsx";
import SimpleArticlesList from "../components/SimpleArticlesList.jsx";
import Tiles from "../components/Tiles.jsx";
import { useFavoriteArticles } from "../services/articles.js";
import { useUserSession, useUserSignOut } from "../services/auth.js";
import {
  useNavigationPage,
  useOneOfAKindPage,
} from "../services/navigation.js";
import { context } from "../util/style.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const SignoutContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > :first-child {
    margin-right: 20px;
  }

  ${context("for-phone")`
    flex-direction: column;
    & > :first-child {
      margin-right: 0;
      margin-bottom: 6px;
    }
  `}
`;

const BigSeparator = styled(Separator)`
  margin: 60px 0;
`;

const DEFAULT_LIMIT = 10;

export default function PrivateArea() {
  const page = useNavigationPage();
  const [signOut] = useUserSignOut();
  const [session] = useUserSession();
  const [articles, { ready }] = useFavoriteArticles({ limit: DEFAULT_LIMIT });
  const [favoriteArticlesPage] = useOneOfAKindPage("favorites");

  const subPages = page.children || [];

  return (
    <TwoColumnsPageTemplate>
      <SignoutContainer>
        <div>
          Vous êtes connecté(e) en tant que{" "}
          <strong>
            {`${session.firstname} ${session.lastname}`.trim() ||
              session.account?.username ||
              session.email}
          </strong>
          .
        </div>
        <FormButton ghost onClick={() => signOut()}>
          Déconnexion
        </FormButton>
      </SignoutContainer>
      {subPages.length > 0 && <PagesGrid pages={subPages} />}
      <BigSeparator />

      <SimpleArticlesList
        title="Mes derniers articles favoris"
        icon="bookmark-simple"
        empty="Vous n'avez enregistré aucun article favori"
        articles={(articles?.edges || []).map(({ node }) => node)}
        loading={!ready}
        linkTo={favoriteArticlesPage}
        linkLabel="Voir tous mes favoris"
      />
      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
