import { initializeApp } from "firebase/app";

const firebaseConfig =
  typeof window !== "undefined" &&
  window.location?.match?.(/(milleapps|localhost)/)
    ? {
        apiKey: "AIzaSyDy6V6_CdZlaTFDHv90soYf-Y9x71KXD4U",
        authDomain: "presselib-demo.firebaseapp.com",
        projectId: "presselib-demo",
        storageBucket: "presselib-demo.appspot.com",
        messagingSenderId: "330160751695",
        appId: "1:330160751695:web:fce3162f54c85d98ad163a",
      }
    : {
        apiKey: "AIzaSyAVeIbK7OsjEQCH5wtils4Tydy1McgVc7g",
        authDomain: "presselib.firebaseapp.com",
        projectId: "presselib",
        storageBucket: "presselib.appspot.com",
        messagingSenderId: "126828222154",
        appId: "1:126828222154:web:24c1eef121dc335b7a8633",
      };

export default initializeApp(firebaseConfig);
