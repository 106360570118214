import HttpStatusRoute from "../components/HttpStatusRoute.jsx";
import DefaultPage from "./DefaultPage.jsx";

export default function Error404(props) {
  return (
    <HttpStatusRoute statusCode={404}>
      <DefaultPage {...props} />
    </HttpStatusRoute>
  );
}
