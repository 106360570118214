import _escapeRegExp from "lodash/escapeRegExp";
import speakingurl from "speakingurl";

const TYPE_REGEXPS = {
  // Recherche de mot
  "%": /^([\w\-_]+)$/,
  ":": /^([\w\-_]+)$/,
  // Recherche de nombre
  "#": /^(\d+)$/,
  // Recherche d'ID
  "@": /^([\da-f]+)$/i,
  // Recherche de n'importe quel caractère sauf /
  "*": /^(.+)$/,
};

const TYPES_REGEXP = `[${_escapeRegExp(Object.keys(TYPE_REGEXPS).join(""))}]`;

const ALIAS_PART_REGEXP = new RegExp(
  `^(${TYPES_REGEXP})?([\\w\\-_]+)?(?:=([^\\?]*))?(\\?)?$`
);

function valueAsType(value, type) {
  return type === "#" ? parseInt(value, 10) : value;
}

export function cleanAlias(alias) {
  return (
    "/" +
    alias
      .replace(/^\/*(.*?)\/*$/, "$1")
      .replace(/\/+/gim, "/")
      .split("/")
      .map((value) =>
        value.match(ALIAS_PART_REGEXP) ? value : speakingurl(value)
      )
      .join("/")
      .toLowerCase()
  );
}

/**
 * Convertit un chemin pour une recherche par expression régulière
 *
 * @param {string} path Chemin, sous la forme /mon/chemin/reel
 * @returns {array} Liste des expressions régulières de la plus spécifique à la plus générique
 */
export function pathToRegExpInParts(path) {
  const pathParts = (path || "")
    .replace(/^\/*(.*?)\/*$/, "$1")
    .replace(/\/+/gim, "/")
    .split("/");
  return pathParts.reduce(
    (allParts, _, index) => [
      ...allParts,
      new RegExp(
        `^${_escapeRegExp(
          ["", ...pathParts.slice(0, pathParts.length - index - 1), ""]
            .join("/")
            .replace(/\/+/gim, "/")
        )}${TYPES_REGEXP}`,
        "i"
      ),
    ],
    [
      new RegExp(
        `^${_escapeRegExp(
          ["", ...pathParts, ""].join("/").replace(/\/+/gim, "/")
        )}${TYPES_REGEXP}`,
        "i"
      ),
    ]
  );
}

/**
 * Indique si l'alias (paramétré) correspond au chemin fourni, et retourne dans ce cas l'objet avec clé => valeur pour chaque paramètre
 *
 * @param {string} alias Alias sous la forme /mon/#alias/%variable=valeur_par_defaut?
 * @param {string} path Chemin, sous la forme /mon/chemin/reel
 * @returns {object} Paramètres, null sinon
 */
export function aliasMatchPath(alias, path) {
  const pathParts = path.split("/");
  const aliasParts = alias.split("/");
  if (pathParts.length > aliasParts.length) return null;
  const [matches] = aliasParts.reduce(
    ([matches, [current, ...pathPartsLeft]], aliasPart) => {
      if (matches === null) return [null, pathPartsLeft]; // cas de fin
      if (current === aliasPart) return [matches, pathPartsLeft]; // cas simple

      const [, type, name, defaultValue, optional] = aliasPart.match(
        ALIAS_PART_REGEXP
      );

      // cas d'un mot classique
      if (!type) {
        // pas de correspondance
        if (current !== aliasPart) {
          return optional
            ? [matches, [current, ...pathPartsLeft]] // on essaie le suivant si optionnel
            : [null, pathPartsLeft]; // sinon l'alias ne correspond pas
        }
        // correspondance
        return [matches, pathPartsLeft];
      }

      // cas d'une expression
      if (!(current || "").match(TYPE_REGEXPS[type])) {
        return typeof defaultValue !== "undefined"
          ? [
              {
                ...matches,
                [name]: valueAsType(defaultValue, type),
              },
              [current, ...pathPartsLeft],
            ] // s'il y a une valeur par défaut, on la prend
          : optional
          ? [matches, [current, ...pathPartsLeft]] // on essaie le suivant si optionnel
          : [null, pathPartsLeft]; // sinon l'alias ne correspond pas
      }
      return [
        { ...matches, [name]: valueAsType(current, type) },
        pathPartsLeft,
      ];
    },
    [{}, pathParts]
  );

  return matches;
}

/**
 * Construit une URL avec des paramètres et un alias
 *
 * @param {string} alias Alias, par exemple /actualite/%alias
 * @param {object} variables Objet avec clé = valeur pour chaque paramètre
 * @returns {string}
 */
export function buildPath(alias, variables = {}, queryParameters = null) {
  if (!variables) variables = {};
  const url = alias
    .split("/")
    .map((aliasPart) => {
      const [, type, name, defaultValue, optional] = aliasPart.match(
        ALIAS_PART_REGEXP
      );

      // on utilise l'alias normal si pas variable
      if (!type) return aliasPart;

      // on utilise la valeur de la variable si elle est définie
      if (typeof variables[name] !== "undefined") {
        // on omet dans le cas où la variable est la même valeur que par défaut ET le paramètre optionnel
        if (variables[name] == defaultValue && optional) return null; // !! comparaison == pour que '1' == 1
        return encodeURIComponent(variables[name]);
      }
      // on utilise la valeur par défaut si la variable n'est pas optionnelle
      if (!optional) {
        return defaultValue;
      }
      // sinon on ignore la partie de l'alias
      return null;
    })
    .filter((part) => part !== null)
    .join("/");
  if (!queryParameters) return url;
  const query = Object.keys(queryParameters)
    .map(
      (param) =>
        `${encodeURIComponent(param)}=${encodeURIComponent(
          queryParameters[param]
        )}`
    )
    .join("&");
  return `${url}?${query}`;
}
