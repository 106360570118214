import _omit from "lodash/omit";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Controller,
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import ConfirmationPopup from "../components/ConfirmationPopup.jsx";
import {
  Form,
  FormButton,
  FormCheckbox,
  FormField,
  FormFileUpload,
  FormInput,
  FormTextarea,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import Separator from "../components/Separator.jsx";
import Tiles from "../components/Tiles.jsx";
import * as validator from "../components/validators.js";
import {
  useNavigationPage,
  useNavigationPageParameters,
} from "../services/navigation.js";
import { useTenderInvitations } from "../services/tenderInvitations.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const TenderInvitationsForm = styled(Form)`
  max-width: 555px;
  margin-bottom: 40px;
`;

const Typography = styled.div`
  margin-top: -4px;
  ${TypographyStyle}
`;

function useTenderInvitationsForm() {
  return useForm({
    mode: "onTouched",
    resolver: yupResolver(
      Yup.object().shape({
        firstname: validator.firstname.required(
          "Veuillez renseigner votre prénom"
        ),
        lastname: validator.lastname.required("Veuillez renseigner votre nom"),
        email: validator.email.required("Veuillez renseigner votre email"),
        job: Yup.string()
          .nullable()
          .required("La fonction est obligatoire"),
        message: Yup.string()
          .nullable()
          .required("Veuillez décrire votre demande"),
        file: Yup.mixed()
          .nullable()
          .required("Veuillez envoyer un fichier"),
        address: Yup.object().shape({
          street: Yup.string()
            .nullable()
            .required("L'adresse est obligatoire"),
          postalCode: Yup.string()
            .nullable()
            .required("Obligatoire"),
          city: Yup.string()
            .nullable()
            .required("La ville est obligatoire"),
        }),
        confirmation: Yup.boolean().oneOf(
          [true],
          "Veuillez accepter les conditions"
        ),
      })
    ),
  });
}

export default function TenderInvitation() {
  const page = useNavigationPage();
  const pageParameters = useNavigationPageParameters();
  const [success, setSuccess] = useState(false);

  const {
    register,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useTenderInvitationsForm();

  const [
    sendTenderInvitation,
    tenderInvitationsResult,
    { loading, called, graphQLErrors: tenderInvitationsErrors },
  ] = useTenderInvitations();

  const onSubmit = useCallback(
    function(data) {
      sendTenderInvitation(_omit(data, ["confirmation"]));
    },
    [clearErrors, sendTenderInvitation]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(tenderInvitationsErrors).forEach((err) =>
        setError("_global", err)
      );
      filterBadUserInputErrors(tenderInvitationsErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (tenderInvitationsResult === false) {
        setError("_global", {
          message:
            "Le message n'a pas pu être envoyé. Veuillez réessayer plus tard.",
        });
      }
      if (hasErrors(tenderInvitationsErrors) || !tenderInvitationsResult)
        return;

      // en cas de succès, afficher une dialog avec le résultat
      setSuccess(true);
      // clear le form
      try {
        reset({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          job: "",
          message: "",
          address: {},
          file: null,
          confirmation: false,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [loading, tenderInvitationsResult, tenderInvitationsErrors]
  );

  return (
    <TwoColumnsPageTemplate>
      <Tiles tiles={page.contents} />

      <Separator />

      <ConfirmationPopup
        title="Message envoyé"
        open={success}
        onClose={() => setSuccess(false)}
      >
        <Typography
          dangerouslySetInnerHTML={{
            __html:
              pageParameters.successText ||
              "Votre message a été transmis à nos équipes et sera traité dans les plus brefs délais !",
          }}
        />
      </ConfirmationPopup>
      <TenderInvitationsForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        errors={errors}
      >
        <Row>
          <Col span={6} sm={12}>
            <FormField label="Prénom" required errors={errors} name="firstname">
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : Camille"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
          <Col span={6} sm={12}>
            <FormField label="Nom" required errors={errors} name="lastname">
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : Vaultier"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col span={8} sm={12}>
            <FormField
              label="Adresse email"
              required
              errors={errors}
              name="email"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : c.vaultier@email.com"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
          <Col span={4} sm={12}>
            <FormField label="Téléphone" errors={errors} name="phone">
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : 01 02 03 04 05"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
        </Row>

        <FormField label="Fonction" required errors={errors} name="job">
          {({ name, ...childProps }) => (
            <FormInput
              placeholder="Ex : Directrice"
              {...register(name)}
              {...childProps}
            />
          )}
        </FormField>

        <FormField
          label="Adresse"
          required
          errors={errors}
          name="address.street"
        >
          {({ name, ...childProps }) => (
            <FormInput
              placeholder="Ex : 5, rue des rosiers"
              {...register(name)}
              {...childProps}
            />
          )}
        </FormField>

        <Row>
          <Col span={4} sm={12}>
            <FormField
              label="Code postal"
              required
              errors={errors}
              name="address.postalCode"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : 35000"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
          <Col span={8} sm={12}>
            <FormField
              label="Ville"
              required
              errors={errors}
              name="address.city"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : Rennes"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
        </Row>

        <FormField label="Message" required errors={errors} name="message">
          {({ name, ...childProps }) => (
            <FormTextarea
              placeholder="Décrivez votre demande"
              {...register(name)}
              {...childProps}
            />
          )}
        </FormField>

        <FormField
          label="Envoyer votre document"
          required
          errors={errors}
          name="file"
        >
          {({ name, ...childProps }) => (
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <FormFileUpload
                  placeholder="Sélectionnez un fichier"
                  {...field}
                  {...childProps}
                />
              )}
            />
          )}
        </FormField>

        <FormField errors={errors} name="confirmation">
          {({ name, ...childProps }) => (
            <FormCheckbox {...register(name)} {...childProps}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html:
                    pageParameters.consentText ||
                    "J'autorise Presselib à utiliser et conserver les informations renseignées afin de répondre à ma demande.",
                }}
              />
            </FormCheckbox>
          )}
        </FormField>

        <FormButton type="submit">Envoyer</FormButton>
      </TenderInvitationsForm>
    </TwoColumnsPageTemplate>
  );
}
