import Icon from "../../components/Icon.jsx";
import { color, styled } from "../../util/style.js";

const SocialMediasItemIcon = styled(Icon)``;
const SocialMediasItemLink = styled.a`
  align-items: center;
  background-color: ${color("orange")};
  border-radius: 50px;
  color: #ffffff;
  display: flex;
  font-size: 20px;
  height: 30px;
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease;
  width: 30px;
  &:hover {
    background-color: ${color("orangeDarker")};
  }
`;

const SocialMediasItem = styled(function({
  href,
  alt,
  icon,
  className,
  ...props
}) {
  return (
    <li className={className}>
      <SocialMediasItemLink href={href} {...props}>
        <SocialMediasItemIcon name={icon} size={24} variant="white" alt={alt} />
      </SocialMediasItemLink>
    </li>
  );
})`
  display: inline-block;
`;
export default styled(function SocialMedias({ className }) {
  return (
    <ul className={className}>
      <SocialMediasItem
        href="https://www.facebook.com/PresseLib/"
        name="Facebook"
        target="_blank"
        rel="noopener noreferrer"
        icon="facebook"
        alt="Page Facebook de PresseLib"
        aria-label={`Voir la page Facebook de Presselib`}
      />
      <SocialMediasItem
        href="https://twitter.com/presselib"
        name="Twitter"
        target="_blank"
        rel="noopener noreferrer"
        icon="twitter"
        alt="Compte Twitter de PresseLib"
        aria-label={`Voir le compte Twitter de Presselib`}
      />
      <SocialMediasItem
        href="https://www.linkedin.com/company/11360726/"
        name="LinkedIn"
        target="_blank"
        rel="noopener noreferrer"
        icon="linkedin"
        alt="Page LinkedIn de PresseLib"
        aria-label={`Voir la page LinkedIn de Presselib`}
      />
      <SocialMediasItem
        href="https://www.instagram.com/presselib_/?hl=fr"
        name="Instagram"
        target="_blank"
        rel="noopener noreferrer"
        icon="instagram"
        alt="Compte Instagram de PresseLib"
        aria-label={`Voir le compte Instagram de Presselib`}
      />
    </ul>
  );
})`
  margin: -8px 0;
  display: inline-block;

  ${SocialMediasItem} {
    padding: 8px;
  }
`;
