import { Fragment } from "react";

import { ButtonIcon } from "../../components/buttons.jsx";
import { Col, Container, Row } from "../../components/layout.jsx";
import PageLink from "../../components/PageLink.jsx";
import logo from "../../img/logo-presselib-baseline-white.png";
import logo2x from "../../img/logo-presselib-baseline-white@2x.png";
import dashedBackground from "../../img/motif-lines-white.svg";
import {
  useNavigationIndex,
  useNavigationIndexParameters,
} from "../../services/navigation.js";
import { color, context, font, styled } from "../../util/style.js";
import FooterNav from "./FooterNav.jsx";
import SocialMedias from "./SocialMedias.jsx";

const FooterTitle = styled.div`
  color: white;
  display: block;
  font-family: ${font("secondary")};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
  ${context("for-tablet-portrait")`
    display: none;
  `}
`;
const FooterSeparator = styled.hr`
  border: 0;
  height: 10px;
  margin: 0;
  background-image: url(${dashedBackground});
  background-repeat: repeat;
  background-size: 4px 4px;
  margin-bottom: 40px;
`;
const FooterColumn = styled.div`
  margin-bottom: 40px;

  ${context("for-tablet-portrait")`
    margin-bottom: 32px;
  `}
`;

const LogoContainer = styled(FooterColumn)``;
const SocialMediasContainer = styled(FooterColumn)`
  text-align: center;
`;
const ToolsContainer = styled(FooterColumn)`
  text-align: right;
  ${context("for-tablet-portrait")`
    text-align: center;
  `}
`;

const Tools = styled(function({ className }) {
  const indexParameters = useNavigationIndexParameters();
  const pageContact = indexParameters.pageContact;
  const pagePodcast = indexParameters.pagePodcast;
  const pageNewsletter = indexParameters.pageNewsletter;
  return (
    <div className={className}>
      {Boolean(pageContact) && (
        <ButtonIcon
          forwardedAs={PageLink}
          to={pageContact}
          aria-label="Prendre contact"
          icon="pen"
          footer
        >
          contact
        </ButtonIcon>
      )}
      {Boolean(pagePodcast) && (
        <ButtonIcon
          forwardedAs={PageLink}
          to={pagePodcast}
          aria-label="Voir les podcasts"
          icon="micro"
          footer
        >
          podcast
        </ButtonIcon>
      )}
      {Boolean(pageNewsletter) && (
        <ButtonIcon
          forwardedAs={PageLink}
          to={pageNewsletter}
          aria-label="S'inscrire à la newsletter"
          icon="mail"
          footer
        >
          newsletter
        </ButtonIcon>
      )}
    </div>
  );
})`
  ${ButtonIcon} {
    width: 70px;
  }
`;

const Logo = styled.img.attrs({
  width: 366,
  height: 95,
  src: logo,
  srcset: `${logo2x} 2x`,
})`
  display: block;
  height: auto;
  max-width: 230px;

  ${context("for-tablet-landscape")`
    margin-left: auto;
    margin-right: auto;
  `}
`;

const FooterCopyrightsName = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 16px;
`;

const FooterCopyrightsLinks = styled.div`
  font-size: 13px;
  line-height: 17px;
  ${context("for-tablet-landscape")`
    font-size: 11px;
    line-height: 15px;
  `}
`;

const FooterCopyrightsLink = styled(PageLink)`
  color: white;
  text-transform: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterCopyrights = styled(function({ className }) {
  const indexParameters = useNavigationIndexParameters();
  const specialMenu = indexParameters.specialMenu;

  return (
    <div className={className}>
      <FooterCopyrightsName>© PresseLib</FooterCopyrightsName>
      <FooterCopyrightsLinks>
        <span>{new Date().getFullYear()}</span>

        {(specialMenu || [])
          .filter((page) => !page.hideFromNavigation)
          .map((page) => (
            <Fragment key={page.id}>
              {" - "}
              <FooterCopyrightsLink to={page}>
                {page.title}
              </FooterCopyrightsLink>
            </Fragment>
          ))}
      </FooterCopyrightsLinks>
    </div>
  );
})`
  align-items: baseline;
  display: flex;
  ${context("for-tablet-landscape")`
    flex-direction: column;
  `}
`;

export default styled(function Footer({ className }) {
  const index = useNavigationIndex();
  return (
    <footer className={className}>
      <Container>
        <Row>
          <Col span={4} md={12}>
            <LogoContainer>
              <PageLink to={index}>
                <Logo alt="PresseLib" />
              </PageLink>
            </LogoContainer>
          </Col>
          <Col span={4} md={6} sm={12}>
            <SocialMediasContainer>
              <FooterTitle>Suivez-nous sur les réseaux sociaux</FooterTitle>
              <SocialMedias />
            </SocialMediasContainer>
          </Col>
          <Col span={4} md={6} sm={12}>
            <ToolsContainer>
              <FooterTitle>PresseLib continue avec vous !</FooterTitle>
              <Tools />
            </ToolsContainer>
          </Col>
        </Row>
        <FooterSeparator />
        <FooterNav />
        <FooterCopyrights />
      </Container>
    </footer>
  );
})`
  background-color: ${color("black")};
  color: white;
  min-height: 400px;
  padding: 60px 0;
  ${context("for-tablet-landscape")`
    padding: 40px 0;
  `}
`;
