import { forwardRef } from "react";

import { color, context, font, is, styled } from "../util/style.js";
import Icon from "./Icon.jsx";

const ButtonIconComponents = {
  IconContainer: styled.div`
    align-items: center;
    background-color: ${color("greyBg")};
    border-radius: 50px;
    box-sizing: content-box;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0 auto 4px;
    position: relative;
    transition: all 0.3s ease;
    width: 40px;
  `,
  Icon: styled(Icon)`
    color: ${color("black")};
    font-size: 22px;
    transition: all 0.3s ease;
  `,
  TextContainer: styled.span`
    text-align: center;
    display: block;
    font-size: 12px;
    line-height: 15px;
    ${context("for-phone")`
      display: none;
    `}
  `,
};

export const ButtonIcon = styled(
  forwardRef(function ButtonIcon(
    { icon, footer, as, children, className, mini, ...props },
    ref
  ) {
    const Component = as || "button";
    return (
      <Component className={className} ref={ref} {...props}>
        <ButtonIconComponents.IconContainer>
          <ButtonIconComponents.Icon
            name={icon}
            size={22}
            variant="black"
            hoverVariant="red"
          />
        </ButtonIconComponents.IconContainer>
        <ButtonIconComponents.TextContainer>
          {children}
        </ButtonIconComponents.TextContainer>
      </Component>
    );
  })
)`
  color: ${color("greyTag")};
  display: inline-block;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: ${color("orange")};
    transition: all 0.3s ease;
    ${ButtonIconComponents.IconContainer} {
      background-color: ${color("greyBg2")};
      transition: all 0.3s ease;
    }
    ${ButtonIconComponents.Icon} {
      --icon-transition: opacity 0.3s ease;
      --icon-hover: 1;
    }
  }

  ${is("footer")`
    ${ButtonIconComponents.IconContainer} {
      ${context("for-tablet-portrait")`
        display: none;
      `}
    }
    ${ButtonIconComponents.TextContainer} {
      ${context("for-tablet-landscape")`
        display: inline-block;
      `}
    }
  `}

  ${is("mini")`
    ${ButtonIconComponents.TextContainer} {
      display: none;
      ${context("for-tablet-landscape")`
        display: block;
      `}
    }
  `}
`;

export const Button = styled.button`
  ${font("form.button")}
  background-color: ${color("form.button.background")};
  border-radius: 99px;
  border: 1px solid transparent;
  color: ${color("form.button.color")};
  display: inline-block;
  padding: 10px 32px;
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover, &:focus {
    background-color: ${color("form.button.hover.background")};
    border-color: ${color("form.button.hover.border")};
    color: ${color("form.button.hover.color")};
  }  

  ${is("text")`
    background-color: transparent;
    color: ${color("orange")};
    border-radius: 0;
    border: 0;
    padding: 0;
    &:hover, &:focus {
      text-decoration: underline;
      background-color: transparent;
    }
  `}

  ${is("$variant")`
    background-color: ${({ theme, $variant }) =>
      theme.colors?.form.button[$variant]?.background};
    border-color: ${({ theme, $variant }) =>
      theme.colors?.form.button[$variant]?.border}; 
    color: ${({ theme, $variant }) =>
      theme.colors?.form.button[$variant]?.color}; 

    &:hover, &:focus {
      background-color: ${({ theme, $variant }) =>
        theme.colors?.form.button[$variant]?.hover?.background};
      border-color: ${({ theme, $variant }) =>
        theme.colors?.form.button[$variant]?.hover?.border}; 
      color: ${({ theme, $variant }) =>
        theme.colors?.form.button[$variant]?.hover?.color}; 
    }
  `}

  ${is("$mini")`
    font-size: 13px;
    line-height: 13px;
    padding: 10px 16px;
  `}
`;
