import { useOneOfAKindPage } from "../services/navigation.js";
import ElementInList from "./ElementInList.jsx";

export default function ArticleInList({ ...props }) {
  const [newsDetailPage] = useOneOfAKindPage("article");

  return (
    <ElementInList
      {...props}
      page={newsDetailPage}
      pageParameters={{ alias: props.alias }}
    />
  );
}
