import Article from "../../../../../app/components/ArticleInList.jsx";
import Icon from "../../../../../app/components/Icon.jsx";
import PageLink from "../../../../../app/components/PageLink.jsx";
import {
  color,
  font,
  ifelse,
  is,
  styled,
} from "../../../../../app/util/style.js";
import dashedBorder from "./assets/motif-lines.svg";

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
`;

const TitleIcon = styled(Icon)`
  color: ${color("orange")};
  margin-right: 4px;
`;

const Title = styled.h2`
  font-family: ${font("secondary")};
  font-size: 22px;
  line-height: 24px;

  ${is("aside")`
    font-size: 18px;
  `}
`;

const List = styled.div`
  clear: both;
  & > * {
    clear: both;
    border-bottom: 1px solid ${color("border")};
    padding: 8px 0;
  }

  ${ifelse("withFeaturedArticle")`
    border-top:  1px solid ${color("border")};
    margin-top: 30px;
  ``
    &::before {
      background-image: url(${dashedBorder});
      background-repeat: repeat;
      background-size: 4px 4px;
      content: "";
      display: block;
      height: 10px;
      margin-top: 8px;
      width: 100%;
    }
  `}
`;

const MoreLinkContainer = styled.div`
  margin-top: 8px;
  text-align: right;
`;
const MoreLink = styled.a`
  color: ${color("greyTag")};
  font-family: ${font("secondary")};
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    transition: color 0.3s ease;
  }
`;

export default styled(function ReactArticlesTile({ tile, options, className }) {
  if (!tile || (!tile.featuredArticle && !tile.articles?.length)) return null; // Problème sur la décoration (ex : page inexistante)
  return (
    <div className={`${className || ""} tile-container tile-articles`}>
      {(!!tile.title || tile.page) && (!tile.featuredArticle || options.aside) && (
        <TitleContainer>
          <TitleIcon
            name="presselib"
            size={options.aside ? 22 : 26}
            variant="red"
          />
          <Title aside={!!options.aside}>
            {tile.page ? tile.page?.name || tile.page?.title : tile.title}
          </Title>
        </TitleContainer>
      )}
      {!!tile.featuredArticle && (
        <Article
          {...tile.featuredArticle}
          smallWidth={!!options.aside}
          featured={!options.aside}
          aside={!!options.aside}
          mainTitle={
            !options.aside &&
            (tile.page ? tile.page?.name || tile.page?.title : tile.title)
          }
        />
      )}
      <List withFeaturedArticle={!!tile.featuredArticle}>
        {tile.articles?.map((article) => (
          <Article
            {...article}
            key={article.id}
            mini
            smallWidth={!!options.aside}
            aside={!!options.aside}
            featured={false}
          />
        ))}
      </List>
      {tile.page ? (
        <MoreLinkContainer>
          <MoreLink as={PageLink} to={tile.page}>
            Tous les articles
          </MoreLink>
        </MoreLinkContainer>
      ) : (
        tile.link && (
          <MoreLinkContainer>
            <MoreLink
              href={tile.link}
              target={tile.linkTarget}
              title={tile.linkTitle}
            >
              {tile.linkText}
            </MoreLink>
          </MoreLinkContainer>
        )
      )}
    </div>
  );
})``;
