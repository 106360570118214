import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

import { tilesFragment, toStandardResult } from "../util/graphql.js";

const NavigationContext = createContext();

export const NavigationProvider = NavigationContext.Provider;

const pageSchema = `
  id
  title
  alias
  kind
  hideFromSearchEngine
  hideFromNavigation
  tags
  parameters
`;

const pageSchemaForNavigation = `
  ${pageSchema}
  ancestors {
    ${pageSchema}
  }
  children {
    id
    kind
    title
    alias
    hideFromNavigation
    image {
      id
      name
      version
      credits
      description
    }
    children {
      id
      kind
      title
      alias
      hideFromNavigation
      image {
        id
        name
        version
        credits
        description
      }
    }
  }
  summary
  image {
    id
    name
    version
    credits
    description
  }
  imageLinkParameters {
    link
    title
    target
  }
  imageAsBackground
  seo {
    title
    description
    keywords
    useFullTitle
  }
  contents {
    ${tilesFragment()}
  }
`;

const navigationQuery = gql`
  query FetchNavigationByPath($path: String!) {
    public {
      navigation {
        infos: fetchByPath(path: $path) {
          id
          index {
            ${pageSchema}
          }
          page {
            ${pageSchemaForNavigation}
          }
          parameters
          template {
            id
            asideTiles {
              ${tilesFragment()}
            }
          }
          data
        }
      }
    }
  }
`;

export function useNavigationByPath() {
  const location = useLocation();
  const result = useQuery(navigationQuery, {
    variables: { path: location.pathname },
  });

  const [infos, meta] = toStandardResult(result, "public.navigation.infos"); // On a toujours une page si elle existe, même en transition vers une autre
  return [infos, meta];
}

export function useNavigation() {
  const { meta, ...infos } = useContext(NavigationContext);
  return [infos, meta];
}

export function useNavigationPage() {
  const [navigationPage] = useNavigation();
  return navigationPage ? navigationPage.page : null;
}

export function useNavigationIndex() {
  const [navigationPage] = useNavigation();
  return navigationPage && navigationPage.page
    ? navigationPage.page.ancestors && navigationPage.page.ancestors.length
      ? navigationPage.page.ancestors[0]
      : navigationPage.page
    : null;
}

export function useNavigationPageParameters() {
  const [navigationPage] = useNavigation();
  return navigationPage ? navigationPage.page.parameters : null;
}

export function useNavigationParameters() {
  const [navigationPage] = useNavigation();
  return navigationPage ? navigationPage.parameters : null;
}

export function useNavigationIndexParameters() {
  const [navigationPage] = useNavigation();
  return navigationPage ? navigationPage.index.parameters : null;
}

function pageWithChildrenSchema(pageSchema, levelOfChildren = 0) {
  return levelOfChildren > 0
    ? `
      ${pageSchema}
      children {
        ${pageWithChildrenSchema(pageSchema, levelOfChildren - 1)}
      }
    `
    : pageSchema;
}

const pagesByTagQuery = (levelOfChildren) => gql`
  query FindPagesByTag($tag: String!) {
    public {
      navigation {
        pages: findPagesByTag(tag: $tag) {
          ${pageWithChildrenSchema(pageSchema, levelOfChildren)}
        }
      }
    }
  }
`;

export function usePagesByTag(tag, levelOfChildren = 0) {
  const result = useQuery(pagesByTagQuery(levelOfChildren), {
    variables: { tag },
    skip: !tag,
  });

  return toStandardResult(result, "public.navigation.pages");
}

const oneOfAKindPageQuery = (forNavigation) => gql`
  query FindOneOfAKindPage($kind: String!) {
    public {
      navigation {
        page: fetchPageByKind(kind: $kind) {
          ${forNavigation ? pageSchemaForNavigation : pageSchema}
        }
      }
    }
  }
`;

export function useOneOfAKindPage(kind, forNavigation) {
  const result = useQuery(oneOfAKindPageQuery(forNavigation), {
    variables: { kind },
  });

  return toStandardResult(result, "public.navigation.page");
}

function getGqlRecursiveLevels(gql, levels) {
  return (
    gql +
    (levels > 0
      ? `
      children {
        ${getGqlRecursiveLevels(gql, levels - 1)}
      }`
      : ``)
  );
}

export function usePagesTreeByPageId(id, levels = 2) {
  const result = useQuery(
    gql`
      query FetchPagesTree($id: ID!) {
        public {
          navigation {
            page: fetchPageById(id: $id) {
              ${getGqlRecursiveLevels(pageSchema, levels)}
            }
          }
        }
      }
    `,
    {
      variables: { id },
      skip: !id,
    }
  );

  return toStandardResult(result, "public.navigation.page");
}
