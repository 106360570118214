import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Controller,
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import ConfirmationPopup from "../components/ConfirmationPopup.jsx";
import ContentLoading from "../components/ContentLoading.jsx";
import {
  Form,
  FormButton,
  FormField,
  FormInput,
  FormSection,
  FormSelect,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import NewsletterPreferencesForm from "../components/NewsletterPreferencesForm.jsx";
import PageRedirect from "../components/PageRedirect.jsx";
import Tiles from "../components/Tiles.jsx";
import * as validator from "../components/validators.js";
import {
  useNavigationIndex,
  useNavigationPage,
  useNavigationPageParameters,
} from "../services/navigation.js";
import {
  modelToType,
  typeToModel,
  useNewsletterPreferencesUpdate,
  useNewsletterUnsubscribe,
  usePreferences,
} from "../services/newsletter.js";
import { useLocationQuery } from "../util/router.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const NewsletterPreferencesUpdateForm = styled(Form)`
  max-width: 555px;
  margin-bottom: 40px;
`;

function useNewsletterPreferencesUpdateForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        firstname: validator.firstname.required(
          "Veuillez renseigner votre prénom"
        ),
        lastname: validator.lastname.required("Veuillez renseigner votre nom"),
        profileKind: Yup.string()
          .oneOf(
            ["professional", "personal"],
            "Veuillez choisir un profil valide"
          )
          .required("Veuillez choisir votre profil"),
      })
    ),
  });
}

export default function NewsletterPreferences() {
  const index = useNavigationIndex();

  const query = useLocationQuery();
  const token = query.get("token");

  const [preferences, { loading, errors }] = usePreferences(token);

  if (!token || (!loading && !preferences)) return <PageRedirect to={index} />;

  return loading ? (
    <ContentLoading />
  ) : (
    <PageContent preferences={preferences} token={token} />
  );
}

function PageContent({ preferences, token }) {
  const page = useNavigationPage();
  const pageParameters = useNavigationPageParameters();
  const [success, setSuccess] = useState(false);

  const {
    register,
    watch,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useNewsletterPreferencesUpdateForm(modelToType(preferences));

  const [
    newsletterPreferencesUpdate,
    newsletterPreferencesUpdateResult,
    { loading, called, graphQLErrors: newsletterPreferencesUpdateErrors },
  ] = useNewsletterPreferencesUpdate(token);

  const onSubmit = useCallback(
    function(data) {
      newsletterPreferencesUpdate(typeToModel(data));
    },
    [newsletterPreferencesUpdate]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(newsletterPreferencesUpdateErrors).forEach((err) =>
        setError("_global", err)
      );
      filterBadUserInputErrors(
        newsletterPreferencesUpdateErrors
      ).forEach((err) => setError(err.path, err));
      if (newsletterPreferencesUpdateResult === false) {
        setError("_global", {
          message:
            "La mise à jour de vos préférences a échoué. Veuillez réessayer plus tard.",
        });
      }
      if (
        hasErrors(newsletterPreferencesUpdateErrors) ||
        !newsletterPreferencesUpdateResult
      )
        return;

      // en cas de succès, afficher une dialog avec le résultat
      setSuccess(true);
      // clear le form
      try {
        reset(modelToType(newsletterPreferencesUpdateResult));
      } catch (e) {
        console.log(e);
      }
    },
    [
      loading,
      newsletterPreferencesUpdateResult,
      newsletterPreferencesUpdateErrors,
    ]
  );

  return (
    <TwoColumnsPageTemplate>
      <ConfirmationPopup
        title="Préférences"
        open={success}
        onClose={() => setSuccess(false)}
      >
        Vos préférences ont été correctement enregistrées.
      </ConfirmationPopup>
      <NewsletterPreferencesUpdateForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        errors={errors}
      >
        <FormSection title="Votre identité">
          <Row>
            <Col span={8} sm={12} fixed>
              <FormField label="Adresse email">
                {() => (
                  <FormInput
                    placeholder="Ex : c.vaultier@email.com"
                    readonly
                    value={preferences.email}
                  />
                )}
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={6} sm={12}>
              <FormField
                label="Prénom"
                required
                errors={errors}
                name="firstname"
              >
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : Camille"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
            <Col span={6} sm={12}>
              <FormField label="Nom" required errors={errors} name="lastname">
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : Vaultier"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>

          <Row>
            <Col span={8} sm={12} fixed>
              <FormField
                label="C'est un mail"
                errors={errors}
                name="profileKind"
                required
              >
                {({ name, ...childProps }) => (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        placeholder="Choisir..."
                        {...field}
                        {...childProps}
                        options={[
                          {
                            value: "personal",
                            label: "personnel",
                          },
                          {
                            value: "professional",
                            label: "professionnel",
                          },
                        ]}
                      />
                    )}
                  />
                )}
              </FormField>
            </Col>
          </Row>
        </FormSection>

        <NewsletterPreferencesForm {...{ control, register, watch, errors }} />

        <FormSection>
          <FormButton type="submit">Enregistrer les préférences</FormButton>
        </FormSection>
      </NewsletterPreferencesUpdateForm>

      {preferences.active ? (
        <NewsletterUnsubscribeForm token={token} />
      ) : (
        <div>Vous ne recevez pas la newsletter</div>
      )}

      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}

function NewsletterUnsubscribeForm({ token }) {
  const [success, setSuccess] = useState(false);
  const {
    clearErrors,
    setError,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [
    newsletterUnsubscribe,
    newsletterUnsubscribeResult,
    { loading, errors: newsletterUnsubscribeErrors },
  ] = useNewsletterUnsubscribe(token);

  const onSubmit = useCallback(
    function() {
      newsletterUnsubscribe();
    },
    [newsletterUnsubscribe]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      filterErrorMessages(newsletterUnsubscribeErrors).forEach((message) =>
        setError("_global", { message })
      );
      filterBadUserInputErrors(newsletterUnsubscribeErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (newsletterUnsubscribeResult === false) {
        setError("_global", {
          message:
            "La mise à jour de vos préférences a échoué. Veuillez réessayer plus tard.",
        });
      }
      if (
        hasErrors(newsletterUnsubscribeErrors) ||
        !newsletterUnsubscribeResult
      )
        return;

      // en cas de succès, afficher une dialog avec le résultat
      setSuccess(true);
      // clear le form
      try {
        reset(modelToType(newsletterUnsubscribeResult));
      } catch (e) {
        console.log(e);
      }
    },
    [loading, newsletterUnsubscribeResult, newsletterUnsubscribeErrors]
  );

  return (
    <>
      <ConfirmationPopup
        title="Préférences"
        open={success}
        onClose={() => setSuccess(false)}
      >
        Vous avez bien été désinscrit de notre newsletter. Vous ne recevrez plus
        de message de notre part.
      </ConfirmationPopup>
      <NewsletterPreferencesUpdateForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        errors={errors}
      >
        <FormButton text type="submit">
          Se désinscrire de la newsletter
        </FormButton>
      </NewsletterPreferencesUpdateForm>
    </>
  );
}
