import _omit from "lodash/omit";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Controller,
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import ConfirmationPopup from "../components/ConfirmationPopup.jsx";
import {
  Form,
  FormButton,
  FormCheckbox,
  FormField,
  FormInput,
  FormSelect,
  FormSeparator,
  FormTextarea,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import Tiles from "../components/Tiles.jsx";
import * as validator from "../components/validators.js";
import { useContact } from "../services/contact.js";
import {
  useNavigationPage,
  useNavigationPageParameters,
} from "../services/navigation.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const ContactForm = styled(Form)`
  max-width: 555px;
  margin-bottom: 40px;
`;

const Typography = styled.div`
  margin-top: -4px;
  ${TypographyStyle}
`;

function useContactForm() {
  return useForm({
    mode: "onTouched",
    resolver: yupResolver(
      Yup.object().shape({
        kind: Yup.string()
          .nullable()
          .required("Indiquez votre profil"),
        firstname: validator.firstname.required(
          "Veuillez renseigner votre prénom"
        ),
        lastname: validator.lastname.required("Veuillez renseigner votre nom"),
        email: validator.email.required("Veuillez renseigner votre email"),
        // companyName: Yup.string().required(
        //   "Veuillez renseigner le nom de votre société"
        // ),
        title: Yup.string()
          .nullable()
          .required("L'objet du message est obligatoire"),
        message: Yup.string()
          .nullable()
          .required("Veuillez décrire votre demande"),
        confirmation: Yup.boolean().oneOf(
          [true],
          "Veuillez accepter les conditions"
        ),
      })
    ),
  });
}

export default function Contact() {
  const page = useNavigationPage();
  const pageParameters = useNavigationPageParameters();
  const [success, setSuccess] = useState(false);

  const {
    register,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useContactForm();

  const kind = watch("kind");
  const isAdvertiser = Boolean(kind && kind.value === "advertiser");

  const [
    contact,
    contactResult,
    { loading, called, graphQLErrors: contactErrors },
  ] = useContact();

  const onSubmit = useCallback(
    function(data) {
      contact(_omit(data, ["confirmation"]));
    },
    [clearErrors, contact]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(contactErrors).forEach((err) =>
        setError("_global", err)
      );
      filterBadUserInputErrors(contactErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (contactResult === false) {
        setError("_global", {
          message:
            "Le message n'a pas pu être envoyé. Veuillez réessayer plus tard.",
        });
      }
      if (hasErrors(contactErrors) || !contactResult) return;

      // en cas de succès, afficher une dialog avec le résultat
      setSuccess(true);
      // clear le form
      try {
        reset({
          kind: "",
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          companyName: "",
          companyDetails: "",
          title: "",
          message: "",
          confirmation: false,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [loading, contactResult, contactErrors]
  );

  return (
    <TwoColumnsPageTemplate>
      <Tiles tiles={page.contents} />

      <ConfirmationPopup
        title="Message envoyé"
        open={success}
        onClose={() => setSuccess(false)}
      >
        <Typography
          dangerouslySetInnerHTML={{
            __html:
              pageParameters.successText ||
              "Votre message a été transmis à nos équipes et sera traité dans les plus brefs délais !",
          }}
        />
      </ConfirmationPopup>
      <ContactForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        errors={errors}
      >
        <Row>
          <Col span={8} sm={12} fixed>
            <FormField label="Vous êtes ?" errors={errors} required name="kind">
              {({ name, ...childProps }) => (
                <Controller
                  name={name}
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      placeholder="Choisir..."
                      {...field}
                      {...childProps}
                      options={[
                        { value: "advertiser", label: "Je suis annonceur" },
                        {
                          value: "reader",
                          label: "Je suis lecteur ou lectrice",
                        },
                        { value: "other", label: "Autre" },
                      ]}
                    />
                  )}
                />
              )}
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col span={6} sm={12}>
            <FormField label="Prénom" required errors={errors} name="firstname">
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : Camille"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
          <Col span={6} sm={12}>
            <FormField label="Nom" required errors={errors} name="lastname">
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : Vaultier"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col span={8} sm={12}>
            <FormField
              label="Adresse email"
              required
              errors={errors}
              name="email"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : c.vaultier@email.com"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
          <Col span={4} sm={12}>
            <FormField label="Téléphone" errors={errors} name="phone">
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : 01 02 03 04 05"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
        </Row>

        <FormSeparator />

        {isAdvertiser && (
          <>
            <FormField>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: pageParameters.advertisersText || "",
                }}
              />
            </FormField>

            <Row>
              <Col span={8} sm={12} fixed>
                <FormField
                  label="Société"
                  required={isAdvertiser}
                  errors={errors}
                  name="companyName"
                >
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="Ex : Vaultier Co."
                      {...register(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
            </Row>

            <FormField
              label="Votre activité"
              errors={errors}
              name="companyDetails"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="Ex : Régie publicitaire, agence de communication..."
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>

            <FormSeparator />
          </>
        )}

        <FormField label="Objet" required errors={errors} name="title">
          {({ name, ...childProps }) => (
            <FormInput
              placeholder="Nature de votre demande"
              {...register(name)}
              {...childProps}
            />
          )}
        </FormField>

        <FormField label="Message" required errors={errors} name="message">
          {({ name, ...childProps }) => (
            <FormTextarea
              placeholder="Décrivez votre demande"
              {...register(name)}
              {...childProps}
            />
          )}
        </FormField>

        <FormField errors={errors} name="confirmation">
          {({ name, ...childProps }) => (
            <FormCheckbox {...register(name)} {...childProps}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html:
                    pageParameters.consentText ||
                    "J'autorise Presselib à utiliser et conserver les informations renseignées afin de répondre à ma demande.",
                }}
              />
            </FormCheckbox>
          )}
        </FormField>

        <FormButton type="submit">Envoyer</FormButton>
      </ContactForm>
    </TwoColumnsPageTemplate>
  );
}
