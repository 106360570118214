import { useEffect, useMemo } from "react";

import { useMediaContext } from "../util/context.js";
import { color, styled } from "../util/style.js";
import HtmlCode from "./HtmlCode.jsx";

const AdContainer = styled.div`
  text-align: center;
  ins img {
    border: 1px solid ${color("border")};
    height: auto;
    max-width: 100%;
  }
  ins > img {
    margin: 0 0 24px 0;
  }
  ins a {
    display: inline-block;
    line-height: 0;
    margin: 0 0 24px 0;
    position: relative;
    &:before {
      background: ${color("border")};
      color: ${color("blackLight")};
      content: "Publicité";
      font-size: 9px;
      line-height: 100%;
      left: 0;
      padding: 4px 6px;
      position: absolute;
      top: 0;
      text-transform: uppercase;
    }
  }
`;

export default function Ads({ list, tag, tagDefault = null }) {
  const context = useMediaContext({
    phone: 576,
    desktop: 999999,
  });
  const filteredList = useMemo(
    function() {
      const contextList = (list || [])
        .filter(Boolean)
        .filter(
          ({ contexts }) =>
            !!context &&
            (!contexts || !contexts.length || contexts.indexOf(context) !== -1)
        );
      let results = contextList.filter(
        ({ tags }) => !tag || tags.indexOf(tag) !== -1
      );
      if (!results.length && !!tagDefault) {
        results = contextList.filter(
          ({ tags }) => !tagDefault || tags.indexOf(tagDefault) !== -1
        );
      }
      return results;
    },
    [list, tag, tagDefault, context]
  );

  // Force le rafraîchissement des publicités Revive
  useEffect(function() {
    const to = setTimeout(function() {
      if (!window || !window.reviveAsync) return;
      for (let reviveKey in window.reviveAsync) {
        if (
          window.reviveAsync[reviveKey] &&
          window.reviveAsync[reviveKey].refresh
        )
          window.reviveAsync[reviveKey].refresh();
      }
    }, 500);
    return () => clearTimeout(to);
  }, []);

  return (
    <>
      {filteredList.map(
        ({ htmlCode, id }) =>
          Boolean(htmlCode) && (
            <AdContainer key={id}>
              <HtmlCode content={htmlCode} />
            </AdContainer>
          )
      )}
    </>
  );
}
