import ReactTilesRenderer from "../../common/modules/tiles/renderers/ReactTilesRenderer.jsx";
import { is, styled } from "../util/style.js";

export default styled(function Tiles({ className, aside, tiles }) {
  const flatTiles = (tiles || []).map(({ data, ...meta }) => ({
    ...meta,
    ...data,
  }));
  return (
    <div className={className}>
      <ReactTilesRenderer tiles={flatTiles} options={{ aside }} />
    </div>
  );
})`
  .tile--typography {
    font-size: 18px;
    ${is("aside")`
      font-size: 14px;
    `}
  }
`;
