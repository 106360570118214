import _orderBy from "lodash/orderBy";
import _uniq from "lodash/uniq";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import { buildPath } from "../../common/alias.js";
import { useNavigation } from "../services/navigation.js";
import { useLocationQuery } from "../util/router.js";
import { color, is, styled } from "../util/style.js";
import Icon from "./Icon.jsx";

function Link({ href, after, disabled, isActive, ...props }) {
  if (!href) return <span {...props} />;
  return <NavLink exact to={href} isActive={isActive} {...props} />;
}

const PaginationItem = styled(Link)`
  color: ${color("greyTag")};
  transition: background-color 0.3s ease;

  ${is("disabled")`
    pointer-events: none;
    opacity: 0.3;
  `}

  &:hover {
    background-color: ${color("greyBg")};
    color: ${color("orange")};
    transition: background-color 0.3s ease;
    --icon-transition: opacity 0.3s ease;
    --icon-hover: 1;
  }
  &.active {
    background-color: ${color("orange")};
    color: white;
    &:hover {
      background-color: ${color("orange")};
      color: white;
    }
  }
`;

const IconRevert = styled(Icon)`
  transform: rotate(180deg);
`;

export function usePagination(limit = 10, scrollToTop = true) {
  const query = useLocationQuery();
  const pageNumber = parseInt(query.get("p") || "1", 10);
  const pagination = {
    after: (pageNumber - 1) * limit,
    limit,
  };

  useEffect(() => {
    if (!scrollToTop) return;
    window.scrollTo(0, 0);
  }, [scrollToTop, pageNumber]);

  return pagination;
}

export default styled(function Pagination({
  result: {
    totalCount,
    pageInfo: { endCursor, hasPreviousPage, hasNextPage },
  },
  pagination: { after, limit },
  queryParameters = {},
  className,
}) {
  const [{ page, parameters }] = useNavigation();
  const totalPages = totalCount && limit ? Math.ceil(totalCount / limit) : 0;
  const currentPage =
    1 +
    (limit
      ? typeof after !== "undefined"
        ? Math.ceil(parseInt(after, 10) / limit)
        : Math.ceil(parseInt(endCursor, 10) / limit)
      : 0);

  const mandatoryPages = _orderBy(
    _uniq([
      // First pages
      1,
      2,
      3,
      // Pages around current
      currentPage - 1,
      currentPage,
      currentPage + 1,
      // Last pages
      totalPages - 2,
      totalPages - 1,
      totalPages,
    ])
  ).filter((page) => page >= 1 && page <= totalPages);

  const pages = mandatoryPages.reduce((pages, page, index) => {
    const previousPage = mandatoryPages[index - 1];

    const distance = page - previousPage;

    const previous =
      // display nothing if page just after previous (or no previous page)
      !previousPage || previousPage === "separator" || distance === 1
        ? null
        : // display page if there is just one page between pages
        distance === 2
        ? page - 1
        : // display separator if 2 pages or more between mandatory pages
          "separator";

    return [...pages, ...[previous, page].filter(Boolean)];
  }, []);

  const generatePagePath = (p) =>
    buildPath(page.alias, parameters, {
      ...queryParameters,
      ...(p !== 1 && { p }),
    });

  return (
    <div className={className}>
      <Helmet>
        {Boolean(hasPreviousPage) && (
          <link rel="prev" href={generatePagePath(currentPage - 1)} />
        )}
        {Boolean(hasNextPage) && (
          <link rel="next" href={generatePagePath(currentPage + 1)} />
        )}
      </Helmet>
      <PaginationItem
        disabled={!hasPreviousPage}
        href={generatePagePath(currentPage - 1)}
        after={endCursor - limit}
        rel="prev"
        aria-label="Articles précédents"
        isActive={() => false}
      >
        <Icon name="arrow-left" size={22} hoverVariant="red" />
      </PaginationItem>
      {totalPages ? (
        pages.map((page, index) =>
          page === "separator" ? (
            <PaginationItem key={"separator" + index} disabled={true}>
              ...
            </PaginationItem>
          ) : (
            <PaginationItem
              key={"page" + page}
              href={generatePagePath(page)}
              isActive={(match) => match && page === currentPage}
              after={(page - 1) * limit}
              // active={page === currentPage}
            >
              {page}
            </PaginationItem>
          )
        )
      ) : (
        <PaginationItem isActive={(match) => match}>0</PaginationItem>
      )}
      {/* <PaginationItem active>1</PaginationItem>
      <PaginationItem forwardedAs={<a />}>2</PaginationItem>
      <PaginationItem forwardedAs={<a />}>3</PaginationItem> */}
      <PaginationItem
        disabled={!hasNextPage}
        href={generatePagePath(currentPage + 1)}
        after={endCursor + 1}
        rel="next"
        aria-label="Articles suivants"
        isActive={() => false}
      >
        <IconRevert name="arrow-left" size={22} hoverVariant="red" />
      </PaginationItem>
    </div>
  );
})`
  display: flex;
  justify-content: center;
  margin: 24px 0;
  & > * {
    align-items: center;
    border-radius: 50px;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 0 6px;
    width: 30px;
  }
`;
