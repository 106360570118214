import styled from "styled-components";

import dashedLines from "../img/motif-lines.svg";

export default styled.hr`
  background-image: url(${dashedLines});
  background-repeat: repeat;
  background-size: 4px 4px;
  border: 0;
  content: "";
  display: block;
  height: 10px;
  margin: 30px 0;
  width: 100%;
`;
