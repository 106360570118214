export * as Yup from "yup";
export { useForm, useWatch, Controller } from "react-hook-form";
export { yupResolver } from "@hookform/resolvers/yup";

export function hasErrors(errors) {
  return !!errors && !!errors.length;
}

export function filterErrorMessages(errors) {
  return (errors || [])
    .map(({ message, extensions }) => ({
      message,
      code: extensions?.exception?.code,
    }))
    .filter((e) => !!e.message);
}

export function filterBadUserInputErrors(errors, path = null) {
  return (errors || [])
    .map(({ extensions: { code, errors } = {} }) =>
      code !== "BAD_USER_INPUT"
        ? []
        : path
        ? errors.filter((error) => error.path === path)
        : errors
    )
    .filter((x) => !!x && x.length)
    .reduce((all, errors) => [...all, ...(errors || [])], []);
}

export function filterCustomErrors(
  errors,
  mapErrors = {},
  globalField = "_global",
  path = null // utile ?
) {
  return (errors || [])
    .map(({ extensions: { code, errors } = {} }) =>
      code !== "INTERNAL_SERVEr_ERROR"
        ? []
        : path
        ? errors.filter((error) => error.path === path)
        : errors
    )
    .filter((x) => !!x && x.length)
    .reduce((all, errors) => [...all, ...(errors || [])], []);
}
