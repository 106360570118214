import { useState } from "react";
import Popup from "reactjs-popup";

import TypographyStyle from "../../../../../app/templates/common/TypographyStyle.jsx";
import { getImageProps } from "../../../../../app/util/medias.js";
import { context, styled } from "../../../../../app/util/style.js";

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.6);
  }
  &-content {
    ${TypographyStyle}

    max-width: 100vw;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    pointer-events: none !important;
    padding: 40px;

    figure {
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: scale-down;
      }
      cite {
        color: white;
      }
    }
    ${context("for-tablet-landscape")`
      padding: 0;
    `}
  }
`;

const Typography = styled.div.attrs({ className: "tile--typography" })`
  ${TypographyStyle}
`;

export function Image({ image, caption, link, linkTarget, linkTitle }) {
  const [modalCarouselOpened, setModalCarouselOpened] = useState(false);

  function displayModalCarousel() {
    setModalCarouselOpened(true);
  }
  function closeModalCarousel() {
    setModalCarouselOpened(false);
  }

  return (
    <>
      <Typography>
        <figure>
          {!!image && !!link ? (
            <a
              href={link}
              target={linkTarget}
              rel={linkTarget === "_blank" ? "nofollow noreferrer" : ""}
              title={linkTitle}
            >
              <img
                loading="lazy"
                alt=""
                {...getImageProps(image, "content", true)}
              />
            </a>
          ) : (
            <button onClick={() => displayModalCarousel()}>
              <img
                loading="lazy"
                alt=""
                {...getImageProps(image, "content", true)}
              />
            </button>
          )}
          {!!caption && <figcaption>{caption}</figcaption>}
          {!!(image && image.credits) && (
            <cite className="credits">{image.credits}</cite>
          )}
        </figure>
      </Typography>
      <StyledPopup
        modal
        lockScroll
        closeOnDocumentClick
        open={modalCarouselOpened}
        onClose={closeModalCarousel}
      >
        <figure>
          <img loading="lazy" alt="" {...getImageProps(image, "original")} />
          {!!(image && image.credits) && (
            <cite className="credits">{image.credits}</cite>
          )}
        </figure>
      </StyledPopup>
    </>
  );
}

export default function ReactImageTile({ tile, options, className }) {
  return (
    <div className={`${className || ""} tile-container tile-image`}>
      <Image
        image={tile.image}
        caption={tile.caption}
        link={tile.link}
        linkTarget={tile.linkTarget}
        linkTitle={tile.linkTitle}
      />
    </div>
  );
}
