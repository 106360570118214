import { useNavigationIndexParameters } from "../../services/navigation.js";
import { color, context, font, is, styled } from "../../util/style.js";

const ContactsItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

const ContactsItemLabel = styled.div`
  opacity: 0.6;
  color: ${color("black")};
`;

const ContactsItemValues = styled.div`
  ${context("for-tablet-landscape")`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  `}
`;

const ContactsItemValue = styled.span`
  color: ${color("black")};
  line-height: 200%;

  margin-left: 4px;
  transition: color 0.3s ease;
  ${context("for-desktop")`
    margin-left: 0;
  `}
  &:hover {
    color: ${color("orange")};
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  & + & {
    margin-left: 24px;
    ${context("for-tablet-landscape")`
      margin-left: 0;
    `}
  }
`;

export default styled(function Contacts({ className }) {
  const indexParameters = useNavigationIndexParameters();

  return (
    <div className={className}>
      {(indexParameters.phone1 || indexParameters.email1) && (
        <ContactsItem>
          <ContactsItemLabel>Rédaction&nbsp;:</ContactsItemLabel>
          <ContactsItemValues>
            {indexParameters.phone1 && (
              <ContactsItemValue
                as="a"
                href={"tel:" + indexParameters.phone1.replace(/[^0-9]+/gi, "")}
                rel="noopener nofollow"
              >
                {indexParameters.phone1}
              </ContactsItemValue>
            )}
            {indexParameters.email1 && (
              <ContactsItemValue
                as="a"
                href={"mailto:" + indexParameters.email1}
                rel="noopener nofollow"
              >
                {indexParameters.email1}
              </ContactsItemValue>
            )}
          </ContactsItemValues>
        </ContactsItem>
      )}
      {(indexParameters.phone2 || indexParameters.email2) && (
        <ContactsItem>
          <ContactsItemLabel>Annonceurs&nbsp;:</ContactsItemLabel>
          <ContactsItemValues>
            {indexParameters.phone2 && (
              <ContactsItemValue
                as="a"
                href={"tel:" + indexParameters.phone2.replace(/[^0-9]+/gi, "")}
                rel="noopener nofollow"
              >
                {indexParameters.phone2}
              </ContactsItemValue>
            )}
            {indexParameters.email2 && (
              <ContactsItemValue
                as="a"
                href={"mailto:" + indexParameters.email2}
                rel="noopener nofollow"
              >
                {indexParameters.email2}
              </ContactsItemValue>
            )}
          </ContactsItemValues>
        </ContactsItem>
      )}
    </div>
  );
})`
  font-family: ${font("secondary")};
  font-size: 16px;
  line-height: 22px;

  ${is("footer")`
    ${ContactsItemLabel}, ${ContactsItemValue} {
      color: white;
    }
  `}
`;
