import { useEffect } from "react";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { buildPath } from "../../../common/alias.js";
import Icon from "../../components/Icon.jsx";
import { useNavigation, useOneOfAKindPage } from "../../services/navigation.js";
import { useLocationQuery } from "../../util/router.js";
import { color, context, font, is, styled } from "../../util/style.js";

const SearchInput = styled.input`
  background-color: ${color("border")};
  border: 0;
  height: 40px;
  line-height: 40px;
  padding: 0 48px 0 12px;
  width: 100%;

  ::placeholder {
    color: ${color("greyTag")};
    font-size: 14px;
    font-weight: 700;
  }
`;

const Submit = styled.button.attrs({ type: "submit" })`
  background-color: ${color("orange")};
  color: white;
  font-size: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
`;

const SearchIcon = styled(Icon)`
  vertical-align: middle;
`;

export default styled(function SearchBar({ onSearch, className }) {
  const query = useLocationQuery();
  const defaultValue = query.get("q");
  const [_, { ready }] = useNavigation();

  const [searchPage] = useOneOfAKindPage("search");
  const [search, setSearch] = useState(defaultValue);
  const history = useHistory();

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (!search) return;
      if (onSearch) onSearch(search);
      history.push(buildPath(searchPage.alias, {}, { q: search }));
    },
    [search, searchPage, history]
  );

  useEffect(() => {
    if (!ready || !defaultValue) return;
    setSearch(defaultValue);
  }, [defaultValue, setSearch]);

  return (
    <form onSubmit={handleSubmit} className={className}>
      <SearchInput
        type="text"
        placeholder="Rechercher"
        value={search || ""}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Submit>
        <SearchIcon name="search" variant="white" />
      </Submit>
    </form>
  );
})`
  position: relative;
`;
