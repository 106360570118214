import { useState } from "react";
import Popup from "reactjs-popup";
import styled from "styled-components";
import TypographyStyle from "../../../../../app/templates/common/TypographyStyle.jsx";
import { getImageProps } from "../../../../../app/util/medias.js";
import { Image } from "../image/ReactImageTile.jsx";

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.6);
  }
  &-content {
    max-width: 750px;
    width: 100%;

    figure {
      cite {
        color: white;
      }
    }
  }
`;

const Typography = styled.div.attrs({ className: "tile--typography" })`
  ${TypographyStyle}
`;

export default function ReactStandardTile({ tile, options, className }) {
  const [modalCarouselOpened, setModalCarouselOpened] = useState(false);

  function displayModalCarousel() {
    setModalCarouselOpened(true);
  }
  function closeModalCarousel() {
    setModalCarouselOpened(false);
  }

  return (
    <div
      className={`${className ||
        ""} tile-standard tile-container tile-columns ${
        options.aside ? "tile--aside" : ""
      } tile-columns--${tile.mediaPosition} tile--typography`}
    >
      {!!(tile.withImage || tile.withVideo) && (
        <div className="media-column">
          <figure>
            {!!tile.image && (
              <Image
                image={tile.image}
                caption={tile.mediaCaption}
                link={tile.mediaLink}
                linkTarget={tile.mediaLinkTarget}
                linkTitle={tile.mediaLinkTitle}
              />
            )}
            {!!tile.videoUrlEmbed && (
              <>
                <div className="iframe-container ratio-169">
                  <iframe title={`Video ${tile.id}`} src={tile.videoUrlEmbed} />
                </div>
                {!!tile.mediaCaption && (
                  <figcaption>{tile.mediaCaption}</figcaption>
                )}
                {!!tile.image && tile.image.credits && (
                  <cite className="credits">&copy; {tile.image.credits}</cite>
                )}
              </>
            )}
          </figure>
        </div>
      )}
      <div className="main-column">
        <div dangerouslySetInnerHTML={{ __html: tile.text }} />
      </div>
    </div>
  );
}
