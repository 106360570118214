import { add, isAfter } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { keyframes } from "styled-components";

import {
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import * as validator from "../components/validators.js";
import newsletterImage from "../img/popin_newsletter_presselib.png";
import newsletterImage2x from "../img/popin_newsletter_presselib@2x.png";
import { useNewsletterQuickSubscription } from "../services/newsletter.js";
import { color, font, is, styled, z } from "../util/style.js";
import ConfirmationPopup from "./ConfirmationPopup.jsx";
import {
  Form,
  FormButton,
  FormCheckbox,
  FormField,
  FormInput,
} from "./forms.jsx";
import Icon from "./Icon.jsx";

const hasLocalStorage = typeof localStorage !== "undefined" && !!localStorage;

const NEWSLETTER_QUICK_SUBSCRIPTION_CLOSED =
  "presselib-newsletter-popin-closed";
const DELAY_IN_MINUTES = 60 * 24 * 30; // 1 mois sans popin de newsletter si non connecté

const TitleContainer = styled.div`
  position: absolute;
  right: 0;
  top: -20px;
  display: flex;
  align-items: stretch;
  z-index: 1;
`;

const Title = styled.div`
  background-color: ${color("black")};
  color: ${color("white")};
  display: inline-block;
  font-family: ${font("secondary")};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2em;
  padding: 0.35em 0.7em;
  height: 40px;
`;

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${color("orange")};
  transition: background-color 0.3s ease;

  &:hover {
    background: ${color("orangeDarker")};
  }
`;

const NewsletterInputContainer = styled.div`
  position: relative;
  ${FormInput} {
    padding-left: 40px;
  }
  ${Icon} {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const NewsletterImage = styled.img`
  display: block;
  margin: 0 auto -20px auto;
`;

const slideIn = keyframes`
  from {
    right: -500px;
  }
`;

function useNewsletterQuickSubscriptionForm() {
  return useForm({
    mode: "onTouched",
    resolver: yupResolver(
      Yup.object().shape({
        email: validator.email.required("Veuillez renseigner votre email"),
        consent: Yup.boolean().oneOf(
          [true],
          "Veuillez accepter les conditions"
        ),
      })
    ),
  });
}

export default styled(function NewsletterQuickSubscription({ className }) {
  const lastDateClosed =
    hasLocalStorage &&
    new Date(localStorage.getItem(NEWSLETTER_QUICK_SUBSCRIPTION_CLOSED));

  const lastDateClosedPlusDelay =
    lastDateClosed && add(lastDateClosed, { minutes: DELAY_IN_MINUTES });

  const [success, setSuccess] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(
    function() {
      if (
        lastDateClosedPlusDelay &&
        !isAfter(new Date(), lastDateClosedPlusDelay)
      )
        return;
      const to = setTimeout(() => setOpened(true), 12000);
      return () => to && clearTimeout(to);
    },
    [lastDateClosedPlusDelay]
  );

  function closeNewsletter() {
    if (hasLocalStorage)
      localStorage.setItem(
        NEWSLETTER_QUICK_SUBSCRIPTION_CLOSED,
        new Date().toISOString()
      );
    setOpened(false);
  }

  return (
    <>
      <ConfirmationPopup
        title="Inscription réussie"
        open={success}
        onClose={() => setSuccess(false)}
      >
        Vous avez été inscrit à la newsletter.
      </ConfirmationPopup>
      <NewsletterPopup
        opened={opened}
        onClose={() => closeNewsletter()}
        onSuccess={() => {
          setSuccess(true);
          closeNewsletter();
        }}
      />
    </>
  );
})``;

const NewsletterPopup = styled(function({ onClose, onSuccess, className }) {
  const {
    register,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    handleSubmit,
  } = useNewsletterQuickSubscriptionForm();

  const [
    newsletterSubscription,
    newsletterSubscriptionResult,
    { loading, called, graphQLErrors: newsletterSubscriptionErrors },
  ] = useNewsletterQuickSubscription();

  const onSubmit = useCallback(
    function(data) {
      newsletterSubscription({ email: data.email });
    },
    [clearErrors, newsletterSubscription]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(newsletterSubscriptionErrors).forEach((err) => {
        switch (err.code) {
          case "user-duplicate":
            setError("_global", {
              message: "Vous êtes déjà inscrit(e) à notre newsletter",
            });
            break;
          default:
            setError("_global", err);
        }
      });
      filterBadUserInputErrors(newsletterSubscriptionErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (newsletterSubscriptionResult === false) {
        setError("_global", {
          message: "L'inscription a échoué. Veuillez réessayer plus tard.",
        });
      }
      if (
        hasErrors(newsletterSubscriptionErrors) ||
        !newsletterSubscriptionResult
      )
        return;

      // en cas de succès, afficher une dialog avec le résultat
      onSuccess();
      // clear le form
      try {
        reset();
      } catch (e) {
        console.log(e);
      }
    },
    [
      loading,
      called,
      newsletterSubscriptionResult,
      newsletterSubscriptionErrors,
    ]
  );

  return (
    <div className={className}>
      <TitleContainer>
        <Title>Abonnez-vous</Title>
        <CloseButton onClick={onClose}>
          <Icon name="close" size={24} variant="white" />
        </CloseButton>
      </TitleContainer>

      <NewsletterImage
        src={newsletterImage}
        srcSet={`${newsletterImage2x} 2x`}
      />

      <Form
        onSubmit={handleSubmit(onSubmit)}
        loading={String(loading ? "loading" : "")}
        errors={errors}
        errorColor="#73281A"
      >
        <FormField required errors={errors} name="email" errorColor="#73281A">
          {({ name, ...childProps }) => (
            <NewsletterInputContainer>
              <Icon name="mail" size={32} />
              <FormInput
                {...register(name)}
                {...childProps}
                placeholder="Votre adresse e-mail"
              />
            </NewsletterInputContainer>
          )}
        </FormField>

        <FormField errors={errors} name="consent" errorColor="#73281A">
          {({ name, ...childProps }) => (
            <FormCheckbox {...register(name)} {...childProps}>
              Je consens à l'utilisation de mon adresse email afin de recevoir
              les articles Presselib.
            </FormCheckbox>
          )}
        </FormField>

        <ButtonContainer>
          <FormButton type="submit" black>
            S'inscrire
          </FormButton>
        </ButtonContainer>
      </Form>
    </div>
  );
})`
  position: fixed;
  bottom: 60px;
  right: 0;
  background: ${color("green")};
  z-index: ${z("popin")};
  max-width: 300px;
  padding: 30px;
  animation: ${slideIn} 1s 0.5s backwards;
  display: none;

  ${is("opened")`
    display: block;
  `}

  ${FormCheckbox} {
    color: ${color("white")};
  }

  ${FormInput}, ${FormCheckbox} .fake {
    background: ${color("white")};
  }
`;
