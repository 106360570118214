import { useLogin } from "../../components/auth/Login.jsx";
import Icon from "../../components/Icon.jsx";
import PageLink from "../../components/PageLink.jsx";
import { useUserSession } from "../../services/auth.js";
import {
  useNavigationIndexParameters,
  useOneOfAKindPage,
} from "../../services/navigation.js";
import { color, is, styled } from "../../util/style.js";

const MenuNavItemLink = styled.button`
  font-size: 13px;
  line-height: 17px;
  opacity: 0.6;
  color: ${color("black")};
  position: relative;
  transition: color 0.3s ease, opacity 0.3s ease;
  &:hover {
    opacity: 1;
    color: ${color("orange")};
    text-decoration: underline;
    transition: color 0.3s ease;
  }
`;

const MenuNavItemLinkIcon = styled(Icon)`
  margin: -8px -2px -8px -6px;
  opacity: 0.6;
`;

const MenuNavItem = styled(function({
  icon,
  children,
  to,
  onClick,
  className,
}) {
  return (
    <li className={className}>
      {!!icon && <MenuNavItemLinkIcon name={icon} variant="black" size={24} />}
      <MenuNavItemLink {...(to ? { as: PageLink, to } : { onClick })}>
        {children}
      </MenuNavItemLink>
    </li>
  );
})`
  display: inline-block;
  ${is("emphased")`
    ${MenuNavItemLink} {
      font-weight: 700;
    }
  `}
`;

const MenuNavList = styled.ul`
  & + & {
    margin-top: 16px;
  }
  > ${MenuNavItem}:not(:last-child) {
    margin: 0 8px 8px 0;
  }
`;

export default styled(function MenuSecondaryNav({ onOpenLogin, className }) {
  const { specialMenu, featuredPage } = useNavigationIndexParameters();
  const [pageAccount] = useOneOfAKindPage("privateArea");
  const { openPopin } = useLogin();
  const [session] = useUserSession();

  const privateAreaProps = !!session
    ? { to: pageAccount }
    : {
        onClick: () => {
          onOpenLogin();
          openPopin();
        },
      };

  return (
    <nav className={className}>
      <MenuNavList>
        <MenuNavItem icon="user" emphased {...privateAreaProps}>
          Espace lecteur
        </MenuNavItem>
        {!!featuredPage && !featuredPage.hideFromNavigation && (
          <>
            <MenuNavItem to={featuredPage} emphased>
              Éco-système
            </MenuNavItem>
            {(featuredPage.children || [])
              .filter((page) => !page.hideFromNavigation)
              .map((page) => (
                <MenuNavItem key={page.id} to={page}>
                  {page.title || page.name}
                </MenuNavItem>
              ))}
          </>
        )}
      </MenuNavList>
      <MenuNavList>
        {(specialMenu || [])
          .filter((page) => !page.hideFromNavigation)
          .map((page) => (
            <MenuNavItem key={page.id} to={page}>
              {page.title || page.name}
            </MenuNavItem>
          ))}
      </MenuNavList>
    </nav>
  );
})``;
