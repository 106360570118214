import { Helmet } from "react-helmet";

import { buildPath } from "../../common/alias.js";
import { format } from "../../common/date.js";
import Ads from "../components/Ads.jsx";
import ArticleComments from "../components/ArticleComments";
import ArticleKind from "../components/ArticleKind.jsx";
import ArticlesListSpecial from "../components/ArticlesListSpecial.jsx";
import ContentLoading from "../components/ContentLoading.jsx";
import { TitleWithTag } from "../components/ElementInList.jsx";
import HttpStatusRoute from "../components/HttpStatusRoute.jsx";
import { Container } from "../components/layout.jsx";
import SocialShare from "../components/SocialShare.jsx";
import TagList from "../components/TagList.jsx";
import Tiles from "../components/Tiles.jsx";
import ToggleFavorite from "../components/ToggleFavorite.jsx";
import { useAds } from "../services/ads.js";
import {
  useArticleByAlias,
  useCheckFavorite,
  useSimilarArticlesList,
} from "../services/articles.js";
import {
  useNavigation,
  useNavigationParameters,
} from "../services/navigation.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import { getImageProps, getMediaUrl } from "../util/medias.js";
import { useLocationQuery } from "../util/router.js";
import { color, context, font, styled } from "../util/style.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const ArticleContainer = styled.article`
  margin-bottom: 40px;
`;

const ArticleHeader = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  ${context("for-tablet-landscape")`
    flex-direction: column;
  `}
`;

const ArticleHeaderTags = styled.div`
  display: flex;
  align-items: center;
`;

const ArticleHeaderData = styled.div`
  color: ${color("greyTag")};
  font-family: ${font("secondary")};
  font-size: 14px;
  line-height: 18px;
  ${context("for-tablet-landscape")`
    align-self: flex-end;
    margin-top: 8px;
  `}
  & > * {
    display: inline-block;
    margin-left: 40px;
    ${context("for-tablet-landscape")`
      display: block;
      margin-left: 0;
      text-align: right;
    `}
    &:first-child {
      margin-left: 0;
    }
  }
`;

const ArticleHeaderSeparator = styled.div`
  border-bottom: 5px solid ${color("border")};
  display: block;
  margin-bottom: 32px;
  position: relative;
  &::after {
    background-color: ${color("orange")};
    bottom: -5px;
    content: "";
    display: block;
    height: 5px;
    max-width: 120px;
    position: absolute;
    width: 30%;
  }
`;

const ArticleKindContainer = styled.div`
  margin-bottom: 16px;
`;

const ArticleTitle = styled(function({ children, className }) {
  return (
    <h1 className={className}>
      <TitleWithTag>{children}</TitleWithTag>
    </h1>
  );
})`
  display: block;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 16px;
  ${context("for-phone")`
    font-size: 24px;
    line-height: 30px;
  `}
`;
const ArticleSubtitle = styled.div`
  font-size: 20px;
  line-height: 30px;
  ${context("for-phone")`
    font-size: 18px;
    line-height: 28px;
  `}
`;

const ArticleHead = styled.div`
  margin-bottom: 40px;
`;

const MainImage = styled.div`
  background: ${color("greyBg2")};
  line-height: 0;
  margin-bottom: 40px;
  text-align: center;
  img {
    height: auto;
    margin: 0 auto;
    max-width: 100%;
    width: auto;
  }
`;

const Lede = styled.div`
  color: ${color("green")};
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 24px;
`;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 24px;
  ${context("for-tablet-landscape")`
    flex-wrap: wrap;
  `}
`;

const SocialContainer = styled.div`
  border-right: 1px solid ${color("border")};
  margin-right: 24px;
  padding-right: 40px;
  position: sticky;
  top: 160px;
  width: 70px;
  ${context("for-tablet-landscape")`
    border-bottom: 1px solid ${color("border")};
    border-right: 0;
    flex: 0 0 100%;
    margin-bottom: 24px;
    margin-right: 0;
    position: static;
    padding-bottom: 24px;
    padding-right: 0;
    top: 0;
    width: auto;
  `}
`;

const TilesContainer = styled.div`
  flex: 1;
  width: calc(100% - 94px);
  ${context("for-tablet-landscape")`
    width: 100%;
  `}
`;

const CommentsSection = styled.section`
  margin-bottom: 40px;
  ${context("for-phone")`
    margin-bottom: 24px;
  `}
`;

const Typography = styled.div`
  ${TypographyStyle}
`;

const MS_IN_A_WEEK = 1000 * 3600 * 24 * 7;

const APP_BASE_URI = (process.env.WEBSITE_URI || "").replace(/\/$/, "");

export default function Article() {
  const [{ page, parameters }] = useNavigation();
  const { alias } = useNavigationParameters() || {};
  const query = useLocationQuery();
  const previewToken = query.get("preview");

  const [article, { loading, refetch }] = useArticleByAlias(
    alias,
    previewToken
  );
  const [favorite, { refetch: refetchCheckFavorite }] = useCheckFavorite(
    article?.id
  );

  const [similarArticles] = useSimilarArticlesList(article, { limit: 3 });
  const [ads] = useAds(["article:middle", "article:top"]);

  const fullUrl = APP_BASE_URI + buildPath(page.alias, parameters);

  if (!article) {
    if (loading) return <ContentLoading />; //TODO composant standard
    return (
      <HttpStatusRoute statusCode="404">
        <TwoColumnsPageTemplate>
          Cet article a probablement été supprimé ou votre lien est incorrect
        </TwoColumnsPageTemplate>
      </HttpStatusRoute>
    );
  }

  return (
    <article itemScope itemType="https://schema.org/NewsArticle">
      <TwoColumnsPageTemplate
        Header={false}
        Top={
          <>
            <Container>
              <ArticleHeader>
                <ArticleHeaderTags>
                  <ToggleFavorite
                    article={article.id}
                    favorite={favorite}
                    onUpdate={() => refetchCheckFavorite()}
                  />
                  {article.tags && <TagList tags={article.tags} orange />}
                </ArticleHeaderTags>
                <ArticleHeaderData>
                  {article.authors && article.authors.length && (
                    <span>
                      Auteur{article.authors.length > 1 ? "s" : ""} :{" "}
                      {article.authors.map(({ id, name }) => (
                        <span
                          key={id}
                          itemProp="author"
                          itemScope
                          itemType="https://schema.org/Person"
                        >
                          {name}
                        </span>
                      ))}
                    </span>
                  )}
                  {article.publishedAt ? (
                    <>
                      <span>
                        Publié le{" "}
                        <time
                          itemProp="datePublished"
                          dateTime={article.publishedAt}
                        >
                          {format(
                            article.publishedAt,
                            "dd MMMM yyyy 'à' HH'h'mm"
                          )}
                        </time>
                      </span>
                      {+new Date(article.publishedLastAt) -
                        +new Date(article.publishedAt) >
                        MS_IN_A_WEEK && (
                        <span>
                          Mis à jour le{" "}
                          <time
                            itemProp="dateModified"
                            dateTime={article.publishedLastAt}
                          >
                            {format(
                              article.publishedLastAt,
                              "dd MMMM yyyy 'à' HH'h'mm"
                            )}
                          </time>
                        </span>
                      )}
                    </>
                  ) : (
                    <span>Non publié - Prévisualisation</span>
                  )}
                </ArticleHeaderData>
              </ArticleHeader>
              <ArticleHeaderSeparator />
              <ArticleHead>
                <ArticleKind
                  kind={article.kind}
                  container={ArticleKindContainer}
                />
                <ArticleTitle itemProp="name">{article.title}</ArticleTitle>
                <ArticleSubtitle itemProp="abstract">
                  {article.summary}
                </ArticleSubtitle>
              </ArticleHead>
            </Container>
          </>
        }
      >
        <Helmet>
          {Boolean(previewToken) && <meta name="robots" content="noindex" />}
          <title>{article.seo?.title || article.title}</title>
          {Boolean(article.hideFromSearchEngine) && (
            <meta name="robots" content="noindex" />
          )}
          <meta
            name="description"
            content={article.seo?.description || article.summary}
          />
          <meta
            name="keywords"
            content={
              article.seo?.keywords ||
              (article.tags || [])
                .map((tag) => tag.replace(/^\w+:/, ""))
                .join(",")
            }
          />
          <meta property="og:url" content={fullUrl} />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content={article.seo?.title || article.title}
          />
          <meta
            property="og:description"
            content={article.seo?.description || article.summary}
          />
          <meta
            property="og:image"
            content={getMediaUrl(article.image, "article-head")}
          />
          <meta property="fb:app_id" content="136529520296393" />
          <link rel="canonical" href={fullUrl} />
        </Helmet>
        <ArticleContainer>
          {!!article.image && (
            <MainImage>
              <Typography>
                <figure>
                  <img
                    alt=""
                    {...getImageProps(article.image, "article-head")}
                  />
                  {!!article.imageCaption && (
                    <figcaption>{article.imageCaption}</figcaption>
                  )}
                  {!!article.image.credits && (
                    <cite className="credits">{article.image.credits}</cite>
                  )}
                </figure>
              </Typography>
            </MainImage>
          )}
          {!!article.introduction && <Lede>{article.introduction}</Lede>}

          {article.kind !== "short" && (
            <Ads list={ads} tag={"article:middle"} />
          )}
          <Content itemProp="articleBody">
            <SocialContainer>
              <SocialShare
                article={article}
                commentsCount={article.comments?.edges?.length}
              />
            </SocialContainer>
            <TilesContainer>
              <Tiles tiles={article.contents} />
            </TilesContainer>
          </Content>
        </ArticleContainer>

        {article.kind === "short" && <Ads list={ads} tag={"article:middle"} />}

        <div style={{ position: "relative", top: -150 }} id="comments" />
        {/* For anchor */}
        <CommentsSection>
          <ArticleComments
            article={article}
            onUpdate={refetch}
            introduction={
              Boolean(page.parameters?.commentsIntroduction) && (
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: page.parameters?.commentsIntroduction || "",
                  }}
                />
              )
            }
          />
        </CommentsSection>

        <ArticlesListSpecial
          title="À lire aussi"
          titleIcon="presselib"
          articles={(similarArticles?.edges || []).map(({ node }) => node)}
        />
      </TwoColumnsPageTemplate>
    </article>
  );
}
