import { useEffect, useState } from "react";
import styled from "styled-components";
import _pick from "lodash/pick";
import _mapValues from "lodash/mapValues";

import {
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
} from "../components/base-forms/forms.js";
import Tiles from "../components/Tiles.jsx";
import { useNavigationPage } from "../services/navigation.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";
import { useUserCompleteAccount } from "../services/auth.js";
import { useLocationQuery } from "../util/router.js";
import { useLogin } from "../components/auth/Login.jsx";
import ContentLoading from "../components/ContentLoading.jsx";

import ok from "../img/ok@2x.png";
import ko from "../img/ko@2x.png";

const Typography = styled.div`
  margin-top: -4px;
  ${TypographyStyle}
`;

export default function CompleteAccount() {
  const page = useNavigationPage();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const query = useLocationQuery();
  const token = query.get("token");

  const { openPopin } = useLogin();

  const [
    completeAccount,
    completeAccountResult,
    { loading, called, graphQLErrors: completeAccountErrors },
  ] = useUserCompleteAccount(token);

  useEffect(
    function() {
      completeAccount();
    },
    [token]
  );

  useEffect(
    function() {
      if (loading) return setError();
      if (!called) return;
      filterErrorMessages(completeAccountErrors).forEach((err) => {
        switch (err.code) {
          case "token-no-match":
          case "invalid-token":
            setError("Le lien d'activation a déjà été utilisé ou a expiré.");
            return;
          default:
            setError(err.message);
            return;
        }
      });
      filterBadUserInputErrors(completeAccountErrors).forEach((err) =>
        setError(err.message)
      );
      if (completeAccountResult === false) {
        setError(
          "Impossible de finaliser le compte. Veuillez réessayer plus tard"
        );
      }
      if (hasErrors(completeAccountErrors) || !completeAccountResult) return;

      // en cas de succès, affiche une popup
      setSuccess(true);
    },
    [loading, completeAccountResult, completeAccountErrors]
  );

  return (
    <TwoColumnsPageTemplate>
      {success ? (
        <Typography>
          <p>
            <img src={ok} style={{ width: 100 }} />
          </p>
          <h2>Votre compte a été activé</h2>
          <p>
            Vous pouvez désormais{" "}
            <a style={{ cursor: "pointer" }} onClick={() => openPopin()}>
              vous connecter
            </a>{" "}
            en utilisant vos identifiants.
          </p>
        </Typography>
      ) : error ? (
        <Typography>
          <p>
            <img src={ko} style={{ width: 100 }} />
          </p>
          <h2>Votre compte n'a pas été activé</h2>
          <p>
            Nous n'avons pas pu activer votre compte pour la raison suivante :{" "}
            <strong>{error}</strong>
          </p>
        </Typography>
      ) : (
        <ContentLoading />
      )}

      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
