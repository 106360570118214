import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import { keyframes } from "styled-components";

import { buildPath } from "../../../common/alias.js";
import { useLogin } from "../../components/auth/Login.jsx";
import { ButtonIcon } from "../../components/buttons.jsx";
import Icon from "../../components/Icon.jsx";
import { Container } from "../../components/layout.jsx";
// import NotificationsToggle from "../../components/NotificationsToggle.jsx";
import PageLink from "../../components/PageLink.jsx";
import { useUserSession, useUserSignOut } from "../../services/auth.js";
import {
  useNavigation,
  useNavigationIndex,
  useNavigationIndexParameters,
  useOneOfAKindPage,
} from "../../services/navigation.js";
import { useLocationQuery } from "../../util/router.js";
import {
  color,
  context,
  eq,
  font,
  ifelse,
  is,
  isnot,
  styled,
  theme,
} from "../../util/style.js";
import { LogoClassic, LogoWithBaseLine } from "./Logos.jsx";
import Menu from "./Menu.jsx";

const TopBarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const TopBarOurPhilosophy = styled.span`
  font-family: ${font("secondary")};
  font-size: 13px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
`;
const TopBarOurPhilosophyLink = styled(PageLink)`
  &:hover {
    text-decoration: underline;
  }
`;
const TopBarAccount = styled.div`
  align-items: center;
  display: flex;
  padding: 0 8px;
`;
const TopBarAccountIcon = styled(Icon)`
  vertical-align: middle;
  margin: -14px 0px;
  transform: translateY(-1px);
`;

const TopBarAccountPart = styled.span`
  padding: 8px;
  background-color: white;
  color: ${color("black")};
  line-height: 16px;
`;

const TopBarAccountLabel = styled(TopBarAccountPart)`
  font-family: ${font("secondary")};
  font-weight: bolder;
  font-size: 14px;
`;

const TopBarAccountLink = styled(TopBarAccountPart).attrs((props) => ({
  as: props.as || "button",
}))`
  font-family: ${font("primary")};
  font-size: 12px;
  padding: 8px 16px;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    --icon-hover: 1;
  }

  ${ifelse("orange")`
    background-color: ${color("orange")};
    color: white;

    &:hover {
      background-color: ${color("orangeDarker")};
    }
  ``
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      background: ${color("orange")};
      margin-left: 16px;
      margin-right: 16px;
      transition: all 0.3s ease;
    }

    background-color: white;
    color: ${color("orange")};

    &:hover {
      background-color: ${color("greyBg")};
    }
  `}
`;
const RootPageLink = styled(PageLink)`
  color: ${color("black")};
`;

const TopBar = styled(function({ className }) {
  const index = useNavigationIndex();
  const { pagePhilosophy } = index.parameters;
  const [pageRegistration] = useOneOfAKindPage("signup");
  const [pageAccount] = useOneOfAKindPage("privateArea");

  const { openPopin } = useLogin();
  const [signOut] = useUserSignOut();
  const [session] = useUserSession();

  return (
    <div className={className}>
      <Container>
        <TopBarContainer>
          <TopBarOurPhilosophy>
            <RootPageLink to={index}>presselib.com</RootPageLink>
            {" - "}
            <TopBarOurPhilosophyLink to={pagePhilosophy}>
              découvrez notre philosophie
            </TopBarOurPhilosophyLink>
          </TopBarOurPhilosophy>
          <TopBarAccount>
            {/* <NotificationsToggle /> */}
            <TopBarAccountLabel>
              <TopBarAccountIcon name="presselib" variant="red" size={24} />{" "}
              Espace lecteur
            </TopBarAccountLabel>
            {!!session ? (
              <>
                <TopBarAccountLink as={PageLink} to={pageAccount}>
                  {`${session.firstname} ${session.lastname}`.trim() ||
                    session.account?.username ||
                    session.email}
                </TopBarAccountLink>
                <TopBarAccountLink onClick={() => signOut()} orange>
                  <TopBarAccountIcon name="logout" variant="white" size={20} />
                </TopBarAccountLink>
              </>
            ) : (
              <>
                {!!pageRegistration && (
                  <TopBarAccountLink as={PageLink} to={pageRegistration}>
                    Inscription
                  </TopBarAccountLink>
                )}
                <TopBarAccountLink onClick={() => openPopin()} orange>
                  Connexion
                </TopBarAccountLink>
              </>
            )}
          </TopBarAccount>
        </TopBarContainer>
      </Container>
    </div>
  );
})`
  background-color: #f5f4f4;
  ${context("for-tablet-landscape")`
    display: none;
  `}
  transition: max-height .3s;
`;

const LogoBarContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
const LogoBarMenuContainer = styled.div`
  min-width: 50px;
`;
const LogoBarLogoContainer = styled.div`
  display: inline-block;

  ${eq("size", "large")`
    margin: 32px 0 32px 60px;
    max-width: 370px;
  `}

  ${eq("size", "medium")`
    margin: 16px 0 16px 60px;
    max-width: 275px;
  `}

  ${eq("size", "mini")`
    margin: 12px 0 12px 60px;
    max-width: 190px;
  `}

  ${context("for-tablet-landscape")`
    margin: 16px 0;
    max-width: 250px;
  `}
  
  ${context("for-phone")`
    max-width: 150px;
  `}
`;
const LogoBarActions = styled.div`
  ${context("for-tablet-landscape")`
    display: none;
  `}
  ${ButtonIcon} {
    width: 70px;
  }
`;
const LogoBarLogin = styled.div`
  min-width: 50px;
  text-align: right;
  display: none;
  ${context("for-tablet-landscape")`
    display: block;
  `}
`;

const slideIn = keyframes`
  from {
    position: relative;
    top: -20px;
  }
  to {
    position: relative;
    top: 0;
  }
`;
const MenuPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: block !important;
  }
  &-content {
    width: 100%;
    margin: initial !important;
    max-height: 100vh;
    overflow-y: auto;
    animation: ${slideIn} 0.3s ease-in-out;
  }
`;

const LogoBar = styled(function({ mini, className }) {
  const [session] = useUserSession();
  const location = useLocation();
  const [{ index, page }] = useNavigation();
  const { openPopin } = useLogin();

  const [pageAccount] = useOneOfAKindPage("privateArea");
  const { pageContact, pagePodcast, pageNewsletter } = index.parameters;
  const onHome = page.kind === "index";

  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    setMenuOpened(false);
  }, [location]);

  return (
    <div className={className}>
      <Container>
        <LogoBarContainer>
          <LogoBarMenuContainer>
            <MenuPopup
              trigger={
                <ButtonIcon
                  aria-label="Ouvrir le menu principal"
                  icon="menu"
                  mini={mini}
                >
                  menu
                </ButtonIcon>
              }
              modal
              open={menuOpened}
              onOpen={() => setMenuOpened(true)}
              onClose={() => setMenuOpened(false)}
              lockScroll
            >
              {(close) => <Menu onClose={close} />}
            </MenuPopup>
          </LogoBarMenuContainer>
          <LogoBarLogoContainer
            size={mini ? "mini" : onHome ? "large" : "medium"}
          >
            <PageLink to={index}>
              <LogoWithBaseLine alt="PresseLib, l'information d'ici au quotidien" />
              <LogoClassic alt="PresseLib, l'information d'ici au quotidien" />
            </PageLink>
          </LogoBarLogoContainer>
          <LogoBarActions>
            {Boolean(pageContact) && (
              <ButtonIcon
                forwardedAs={PageLink}
                to={pageContact}
                aria-label="Prendre contact"
                icon="pen"
                mini={mini}
              >
                contact
              </ButtonIcon>
            )}
            {Boolean(pagePodcast) && (
              <ButtonIcon
                forwardedAs={PageLink}
                to={pagePodcast}
                aria-label="Voir les podcasts"
                icon="micro"
                mini={mini}
              >
                podcast
              </ButtonIcon>
            )}
            {Boolean(pageNewsletter) && (
              <ButtonIcon
                forwardedAs={PageLink}
                to={pageNewsletter}
                aria-label="S'inscrire à la newsletter"
                icon="newsletter"
                mini={mini}
              >
                newsletter
              </ButtonIcon>
            )}
          </LogoBarActions>
          <LogoBarLogin>
            <ButtonIcon
              aria-label={session ? "Espace lecteur" : "Connexion au compte"}
              icon="user"
              mini={mini}
              {...(session
                ? { forwardedAs: PageLink, to: pageAccount }
                : { onClick: () => openPopin() })}
            >
              compte
            </ButtonIcon>
          </LogoBarLogin>
        </LogoBarContainer>
      </Container>
    </div>
  );
})`
  background-color: white;
  ${context("for-tablet-landscape")`
    border-bottom: 1px solid ${color("border")};
  `}
`;

const MenuBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const MenuBarMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;
const MenuItemIcon = styled(Icon).attrs({ variant: "white" })`
  font-size: 20px;
  margin-right: 4px;
  position: relative;
`;
const MenuItemLink = styled(PageLink)`
  align-items: center;
  color: white;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 100%;
  line-height: 20px;
  padding: 16px;
  transition: background-color 0.2s ease;
  &.active {
    background-color: ${color("orange")};
  }
  &:hover {
    background-color: ${color("orange")};
    transition: background-color 0.2s ease;
  }
`;
const MenuBarMenuItem = styled(function({ to, icon, children, className }) {
  return (
    <li className={className}>
      <MenuItemLink to={to}>
        {!!icon && <MenuItemIcon name={icon} />} {(to && to.title) || children}
      </MenuItemLink>
    </li>
  );
})``;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  align-items: center;
  display: flex;
  height: 100%;
  width: 140px;
  margin-left: auto;
  padding: 16px 40px 16px 16px;
  transition: background-color 0.2s ease, width 0.4s ease;
  &:hover,
  &:focus {
    background-color: ${color("blackLight")};
  }
  &:focus {
    width: 100%;
  }
  ${is("value")`
    width: 100%;
  `}
  &::placeholder {
    color: white;
  }
`;

const SearchSubmit = styled.button.attrs({ type: "submit" })`
  background-color: transparent;
  color: white;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transition: background-color 0.2s ease;
  width: 40px;

  &:hover,
  &:focus {
    background-color: ${color("orange")};
  }
`;

const SearchIcon = styled(Icon).attrs({ size: 24 })`
  vertical-align: middle;
`;

const MenuBarSearchInput = styled(function({ onSearch, className }) {
  const query = useLocationQuery();
  const defaultValue = query.get("q");

  const [searchPage] = useOneOfAKindPage("search");
  const [search, setSearch] = useState(defaultValue);
  const history = useHistory();

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (search) {
        if (onSearch) onSearch(search);
        history.push(buildPath(searchPage.alias, {}, { q: search }));
      }
    },
    [search, searchPage, history]
  );

  useEffect(() => {
    setSearch(defaultValue);
  }, [defaultValue, setSearch]);

  return (
    <form onSubmit={handleSubmit} className={className}>
      <SearchInput
        type="text"
        placeholder="Rechercher"
        value={search || ""}
        onChange={(e) => setSearch(e.target.value)}
      />
      <SearchSubmit>
        <SearchIcon name="search" variant="white" />
      </SearchSubmit>
    </form>
  );
})`
  position: relative;
  width: 210px;
`;

function fetchIconFromPage(page) {
  const iconResult = page?.tags
    ?.map((value) => value.match(/^icon:(.+)$/i))
    .find(Boolean);
  if (!iconResult) return {};
  return { icon: iconResult[1] };
}

const MenuBar = styled(function({ className }) {
  const indexParameters = useNavigationIndexParameters();
  const mainMenu = indexParameters.mainMenu;
  return (
    <div className={className}>
      <Container>
        <MenuBarContainer>
          <nav>
            <MenuBarMenu>
              {(mainMenu || [])
                .filter((page) => !page.hideFromNavigation)
                .map((page) => (
                  <MenuBarMenuItem
                    {...fetchIconFromPage(page)}
                    key={page.id}
                    to={page}
                  />
                ))}
            </MenuBarMenu>
          </nav>
          <MenuBarSearchInput placeholder="Rechercher" />
        </MenuBarContainer>
      </Container>
    </div>
  );
})`
  background-color: ${color("black")};
  ${context("for-tablet-landscape")`
    display: none;
  `}
`;

export default styled(function Header({ className }) {
  const [scrolled, setScrolled] = useState();

  useEffect(() => {
    function handleScroll() {
      setScrolled(window.scrollY > 0);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <TopBar />
      <header className={className}>
        <LogoBar mini={scrolled} />
        <MenuBar />
      </header>
    </>
  );
})`
  position: sticky;
  top: 0;
  z-index: ${theme("z.header")};
`;
