import _chunk from "lodash/chunk";
import _partition from "lodash/partition";
import { useMemo } from "react";

import { color, context, styled } from "../util/style.js";
import Article from "./ArticleInList.jsx";
import { Col, Row } from "./layout.jsx";

const ArticleContainer = styled.div`
  margin-bottom: 60px;
  ${context("for-tablet-landscape")`
    margin-bottom: 48px;
  `}
  ${context("for-phone")`
    margin-bottom: 32px;
  `}
`;

const Separator = styled.div`
  background-color: ${color("border")};
  border: 0;
  height: 1px;
  margin: 0 0 40px 0;
  ${context("for-phone")`
    margin: 0 0 24px 0;
  `}
`;

export default function ArticlesList({
  articles,
  mainTitle,
  forceFirstFeatured,
  children,
}) {
  const prettyList = useMemo(
    function() {
      if (!articles || !articles.length) return [];
      const [featuredArticles, nonFeaturedArticlesList] = _partition(
        articles,
        "isCurrentlyFeatured"
      );
      if (Boolean(forceFirstFeatured) && !featuredArticles.length) {
        // On force la première actualité en featured
        featuredArticles.push(nonFeaturedArticlesList.shift());
      }
      const nonFeaturedArticles = _chunk(nonFeaturedArticlesList, 2); // On prend les articles non phares 2 par 2
      const prettyList = [];
      let limit = 20;
      let indexNF = 0;
      for (
        let indexF = 0;
        indexF < featuredArticles.length ||
        indexNF < nonFeaturedArticles.length;

      ) {
        if (--limit <= 0) {
          console.log("Frein d'urgence !"); // Normalement jamais utilisé
          break;
        }
        if (Boolean(forceFirstFeatured) && !indexF) {
          // On force la première actualité en featured
          prettyList.push(featuredArticles[indexF++]);
          continue;
        }
        if (indexF >= featuredArticles.length) {
          // On met l'ensemble des articles non phares à la fin quoi qu'il arrive
          for (; indexNF < nonFeaturedArticles.length; ) {
            prettyList.push(nonFeaturedArticles[indexNF++]);
          }
          continue;
        }
        for (
          let nfToInsert = Math.floor(
            (nonFeaturedArticles.length - indexNF) /
              (featuredArticles.length - indexF + 1)
          );
          nfToInsert > 0 && indexNF < nonFeaturedArticles.length;
          nfToInsert--
        ) {
          prettyList.push(nonFeaturedArticles[indexNF++]);
        }
        prettyList.push(featuredArticles[indexF++]);
      }
      return prettyList;
    },
    [articles, forceFirstFeatured]
  );

  if (!prettyList.length) return null;

  const firstArticle =
    Boolean(forceFirstFeatured) && prettyList.length > 0 && prettyList[0];
  const nextArticles = firstArticle
    ? prettyList.slice(1, 2)
    : prettyList.slice(0, 2);
  const lastArticles = prettyList.length > 2 ? prettyList.slice(2) : [];

  return (
    <>
      {Boolean(firstArticle) && (
        <>
          <ArticleContainer>
            <Article {...firstArticle} mainTitle={mainTitle} featured />
          </ArticleContainer>
          <Separator />
        </>
      )}

      {nextArticles.length > 0 &&
        nextArticles.map((articleOrArticles, index) =>
          Array.isArray(articleOrArticles) ? (
            <Row key={index}>
              {articleOrArticles.map((article) => (
                <Col span={6} md={12} key={article.id}>
                  <ArticleContainer>
                    <Article {...article} featured={false} />
                  </ArticleContainer>
                </Col>
              ))}
              {Boolean(articleOrArticles.length % 2) && (
                <Col span={6} md={12} />
              )}
            </Row>
          ) : (
            <ArticleContainer key={index}>
              <Article {...articleOrArticles} featured />
            </ArticleContainer>
          )
        )}

      {children}

      {lastArticles.length > 0 &&
        lastArticles.map((articleOrArticles, index) =>
          Array.isArray(articleOrArticles) ? (
            <Row key={index}>
              {articleOrArticles.map((article) => (
                <Col span={6} md={12} key={article.id}>
                  <ArticleContainer>
                    <Article {...article} featured={false} />
                  </ArticleContainer>
                </Col>
              ))}
              {Boolean(articleOrArticles.length % 2) && (
                <Col span={6} md={12} />
              )}
            </Row>
          ) : (
            <ArticleContainer key={index}>
              <Article {...articleOrArticles} featured />
            </ArticleContainer>
          )
        )}
    </>
  );
}
