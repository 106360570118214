import Popup from "reactjs-popup";

import {
  useArticleAddToFavorite,
  useArticleRemoveFromFavorite,
} from "../services/articles.js";
import { useUserSession } from "../services/auth.js";
import { color, font, styled, is } from "../util/style.js";
import { useLogin } from "./auth/Login.jsx";
import Icon from "./Icon.jsx";

const MarkAsFavorite = styled.button`
  background: ${color("greyBg")};
  border-radius: 99px;
  color: ${color("black")};
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
  line-height: 24px;
  padding: 4px 12px 4px 4px;
  vertical-align: baseline;
  transition: color 0.3s;

  &:hover {
    color: ${color("orange")};
    --icon-hover: 1;
  }

  ${Icon} {
    float: left;
  }
  .text {
    ${font("form.button")}
  }

  ${is("loggedOut")`
    color: ${color("greyTxt")}
  `}

  ${is("favorite")`
    color: ${color("orange")};
    &:hover {
      color: ${color("black")};
    }
  `}
`;

const MarkAsFavoriteSimple = styled.button`
  cursor: pointer;
  margin-right: 6px;
  margin-left: -6px;
`;

const FavoritePopup = styled(Popup)`
  &-content {
    background: ${color("orange")};
    color: white;
    font-size: 12px;
    line-height: 16px;
    padding: 12px;
    max-width: 300px;
    &::after {
      content: "";
      position: absolute;
      bottom: -6px;
      left: 6px;
      height: 0;
      width: 0;
      border-top: 6px solid ${color("orange")};
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }
`;

export default function ToggleFavorite({
  favorite,
  onUpdate,
  size = 24,
  article,
  simple,
}) {
  const [session] = useUserSession();
  const { openPopin } = useLogin();

  const [addToFavorite] = useArticleAddToFavorite(article);
  const [removeFromFavorite] = useArticleRemoveFromFavorite(article);

  async function handleAddFavorite() {
    await addToFavorite();
    if (onUpdate) onUpdate();
  }

  async function handleRemoveFavorite() {
    await removeFromFavorite();
    if (onUpdate) onUpdate();
  }

  const Component = simple ? MarkAsFavoriteSimple : MarkAsFavorite;

  if (!session) {
    return (
      <FavoritePopup
        position="top left"
        closeOnDocumentClick
        on={["hover", "focus"]}
        trigger={
          <Component
            onClick={() =>
              openPopin({
                introduction:
                  "Connectez-vous à votre espace lecteur pour pouvoir ajouter des contenus à vos favoris.",
              })
            }
            loggedOut
          >
            <Icon
              block
              name="bookmark-simple"
              variant="grey"
              hoverName="bookmark-fill"
              hoverVariant="red"
              size={size}
            />
            {Boolean(!simple) && <span className="text">Lire plus tard</span>}
          </Component>
        }
      >
        Vous devez avoir un compte pour ajouter ce contenu à vos favoris
      </FavoritePopup>
    );
  }

  return (
    <FavoritePopup
      position="top left"
      closeOnDocumentClick
      on={["hover", "focus"]}
      trigger={
        <Component
          favorite={favorite}
          onClick={favorite ? handleRemoveFavorite : handleAddFavorite}
        >
          <Icon
            block
            name={favorite ? "bookmark-fill" : "bookmark-simple"}
            variant={favorite ? "red" : "black"}
            hoverName={favorite ? "bookmark-fill" : "bookmark-simple"}
            hoverVariant={favorite ? "black" : "red"}
            size={size}
          />
          {Boolean(!simple) && (
            <span className="text">
              {favorite ? "Enregistré" : "Lire plus tard"}
            </span>
          )}
        </Component>
      }
    >
      {favorite ? "Article favori" : "Ajouter aux favoris"}
    </FavoritePopup>
  );
}
