import { css } from "styled-components";

import dashedWhiteBackground from "../../img/motif-lines-white-10.svg";
import dashedDarkBackground from "../../img/motif-lines.svg";
import quoteGreen from "../../img/quote-green.svg";
import { color, context, font } from "../../util/style.js";

const retroCompatibilityCss = css`
  img.alignright {
    float: right;
    margin: 0.4em 0 1.6em 1.6em;
  }
  img.alignleft {
    float: left;
    margin: 0.4em 1.6em 1.6em 0;
  }
  img.aligncenter {
    margin: 0.4em auto 1.6em auto;
  }

  ${context("for-phone")`
  img.alignright,
  img.alignleft,
  img.aligncenter {
    float: none;
    margin: 0.4em auto 1.6em auto;
  }
  `}
`;

export default css`
  p,
  ul,
  figure,
  blockquote,
  article {
    margin-top: 0;
    margin-bottom: 1.6em;
  }

  p {
    font-size: 1em;
    line-height: 1.7em;
  }

  a {
    color: ${color("blue")};
    text-decoration: underline;
    transition: color 0.3s ease;
    &:hover {
      color: ${color("orange")};
      transition: color 0.3s ease;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${color("black")};
    font-family: ${font("secondary")};
  }
  h1 {
    font-size: 2em;
    margin-bottom: 1em;
    line-height: 1.1em;
  }
  h2 {
    font-size: 1.8em;
    line-height: 120%;
    margin-bottom: 1em;
  }
  h3 {
    color: ${color("green")};
    font-size: 1.6em;
    line-height: 120%;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1.4em;
    line-height: 120%;
    margin-bottom: 1em;
  }
  h5 {
    font-size: 1.2em;
    line-height: 120%;
    margin-bottom: 1em;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 16px;
      overflow: hidden;
      padding-left: 30px;
      position: relative;
    }
  }
  ul {
    li::before {
      background-color: ${color("orange")};
      content: "";
      height: 8px;
      left: 0;
      position: absolute;
      top: 0.4em;
      width: 8px;
    }
  }
  ol {
    counter-reset: li;
    li {
      counter-increment: li;

      &::before {
        content: counter(li);
        font-family: ${font("secondary")};
        font-weight: 700;
        color: ${color("orange")};
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }
  p + ul,
  p + ol {
    margin-top: -1em;
  }

  button,
  .button {
    background-color: ${color("greyBg")};
    border-radius: 99px;
    color: ${color("black")};
    display: inline-block;
    font-size: 1em;
    line-height: 1.7em;
    padding: 8px 24px;
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${color("orange")};
      color: white;
      transition: all 0.3s ease;
    }
  }

  img {
    margin: 0;
    max-width: 100%;
    height: auto;
    display: block;
  }
  figure {
    a,
    button {
      background: none;
      border: none;
      display: block;
      font-size: 0;
      line-height: 0;
      padding: 0;
      border-radius: 0;
      overflow: hidden;
      text-decoration: none;
      width: 100%;

      img {
        transition: opacity 0.3s ease;
      }

      &:hover {
        background: none;
        img {
          opacity: 0.7;
        }
      }
    }

    img {
      width: 100%;
    }
    img + figcaption,
    button + figcaption,
    .ratio-169 + figcaption,
    a + figcaption {
      background-color: ${color("black")};
      color: white;
      display: block;
      font-family: ${font("secondary")};
      font-size: 14px;
      line-height: 20px;
      padding: 8px 16px;
      background-image: url(${dashedWhiteBackground});
      background-repeat: repeat;
      background-size: 4px 4px;
    }
    cite {
      color: ${color("greyTxt")};
      display: block;
      font-size: 10px;
      line-height: 16px;
      padding: 3px 6px;
      text-align: right;
    }
  }

  hr {
    border: 0;
    background-image: url(${dashedDarkBackground});
    background-repeat: repeat;
    background-size: 4px 4px;
    display: block;
    height: 10px;
    width: 100%;
    margin: 40px 0;
    ${context("for-phone")`
      margin: 28px 0;
    `}
  }

  blockquote {
    color: ${color("green")};
    font-family: ${font("secondary")};
    margin-left: 0;
    white-space: pre-wrap;

    &,
    & > p {
      font-size: 1.2em;
      line-height: 1.7em;
      font-weight: 700;
    }

    &::after,
    &::before {
      background-image: url(${quoteGreen});
      background-repeat: no-repeat;
      content: "";
      display: flex; /** block passe derrière l'image, et avec le background ça pose problème; flex suit le texte **/
      height: 24px;
      position: relative;
      width: 24px;
    }
    &::before {
      margin-bottom: 8px;
    }
    &::after {
      margin-top: 8px;
      transform: rotate(180deg);
    }

    + figcaption {
      font-family: ${font("secondary")};
      color: ${color("greyTag")};
      font-size: 14px;
      line-height: 18px;
      margin-top: -32px;
    }
  }

  .ratio-169 {
    width: 100%;
    padding-top: 56.25%; // 16/9
    position: relative;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .iframe-container {
    border: 0;
    display: block;
    margin: 0;
    width: 100%;

    iframe {
      border: none;
    }

    + figcaption {
      background-color: ${color("black")};
      color: white;
      display: block;
      font-family: ${font("secondary")};
      font-size: 14px;
      line-height: 20px;
      padding: 8px 16px;
      background-image: url(${dashedWhiteBackground});
      background-repeat: repeat;
      background-size: 4px 4px;
    }
  }

  p,
  figure {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${retroCompatibilityCss}
`;
