export default function ReactQuoteTile({ tile, options, className }) {
  return (
    <div
      className={`${className ||
        ""} tile-container tile-quote tile--typography`}
    >
      <figure>
        <blockquote dangerouslySetInnerHTML={{ __html: tile.quote }} />
        {!!tile.caption && <figcaption>{tile.caption}</figcaption>}
      </figure>
    </div>
  );
}
