import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import _pick from "lodash/pick";

import { toStandardResult } from "../util/graphql.js";

export const INTERESTS = {
  locations: [
    { key: "locationBearn", name: "Béarn" },
    { key: "locationPaysBasque", name: "Pays Basque" },
    { key: "locationBigorre", name: "Bigorre" },
    { key: "locationLandes", name: "Landes" },
    { key: "locationGers", name: "Gers" },
  ],
  categories: [
    { key: "categoryEconomie", name: "Économie" },
    { key: "categoryCulture", name: "Culture" },
    { key: "categoryTechnologie", name: "Technologie" },
    { key: "categorySport", name: "Sport" },
    { key: "categoryEnvironnement", name: "Environnement" },
    { key: "categoryLoisirs", name: "Loisirs" },
    { key: "categoryAgriculture", name: "Agriculture" },
    { key: "categoryGastronomie", name: "Gastronomie" },
    { key: "categorySante", name: "Santé" },
  ],
  times: [
    { key: "sendOnMorning", name: "Matin" },
    { key: "sendOnNoon", name: "Midi" },
    { key: "sendOnEvening", name: "Soir" },
  ],
};

function getSelectedKeys(object = {}) {
  return Object.entries(object)
    .filter(([_, value]) => !!value)
    .map(([key]) => key);
}

export function typeToModel(data = {}) {
  return {
    firstname: data.firstname,
    lastname: data.lastname,
    profileKind: data.profileKind,
    interests: [
      ...getSelectedKeys(data.categories),
      ...getSelectedKeys(data.locations),
      data.time,
    ],
    subscriptionKinds: getSelectedKeys(data.subscriptionKinds),
  };
}

function filterBooleanKeys(data = [], interests = []) {
  return interests.reduce(
    (keys, { key }) => ({ ...keys, [key]: data.includes(key) }),
    {}
  );
}

export function modelToType(data = {}) {
  return {
    firstname: data.firstname,
    lastname: data.lastname,
    profileKind: data.profileKind,
    categories: filterBooleanKeys(data.interests, INTERESTS.categories),
    locations: filterBooleanKeys(data.interests, INTERESTS.locations),
    time: (
      INTERESTS.times.find(({ key }) => (data.interests || []).includes(key)) ||
      INTERESTS.times[0]
    ).key,

    subscriptionKinds: {
      daily: (data.subscriptionKinds || []).includes("daily"),
      flash: (data.subscriptionKinds || []).includes("flash"),
      partners: (data.subscriptionKinds || []).includes("partners"),
    },
  };
}

const newsletterSubscriptionMutation = gql`
  mutation SubscribeNewsletter($data: NewslettersSubscriptionInput) {
    public {
      newsletters {
        success: subscribe(data: $data)
      }
    }
  }
`;

export function useNewsletterSubscription() {
  const [mutation, result] = useMutation(newsletterSubscriptionMutation);

  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "public.newsletters.success"),
  ];
}

const newsletterPreferencesUpdateMutation = gql`
  mutation UpdatePreferences($token: String!, $data: UserPreferencesInput!) {
    public {
      newsletters {
        success: updatePreferences(token: $token, data: $data) {
          id
          email
          lastname
          firstname
          profileKind
          active
          subscriptionKinds
          interests
        }
      }
    }
  }
`;

export function useNewsletterPreferencesUpdate(token) {
  const [mutation, result] = useMutation(newsletterPreferencesUpdateMutation);

  return [
    ({ subscriptionKinds, ...data }) =>
      mutation({
        variables: {
          token,
          data: {
            ...data,
            kinds: subscriptionKinds,
            active: true, // Réactivation de la newsletter si on ré-enregistre des préférences
          },
        },
      }),
    ...toStandardResult(result, "public.newsletters.success"),
  ];
}

const getPreferencesQuery = gql`
  query GetNewsletterPreferences($token: String!) {
    public {
      newsletters {
        preferences: fetchUserPreferences(token: $token) {
          id
          email
          lastname
          firstname
          profileKind
          active
          subscriptionKinds
          interests
        }
      }
    }
  }
`;

export function usePreferences(token) {
  const result = useQuery(getPreferencesQuery, {
    variables: { token },
    skip: !token,
  });

  return toStandardResult(result, "public.newsletters.preferences");
}

const newsletterUnsubscribeMutation = gql`
  mutation UnsubscribeNewsletter($token: String!) {
    public {
      newsletters {
        success: unsubscribe(token: $token) {
          id
          email
          lastname
          firstname
          profileKind
          active
          subscriptionKinds
          interests
        }
      }
    }
  }
`;

export function useNewsletterUnsubscribe(token) {
  const [mutation, result] = useMutation(newsletterUnsubscribeMutation);

  return [
    () => mutation({ variables: { token } }),
    ...toStandardResult(result, "public.newsletters.success"),
  ];
}

const newsletterQuickSubscriptionMutation = gql`
  mutation QuickSubscribeNewsletter($data: NewslettersSoftSubscriptionInput) {
    public {
      newsletters {
        success: softSubscribe(data: $data)
      }
    }
  }
`;

export function useNewsletterQuickSubscription() {
  const [mutation, result] = useMutation(newsletterQuickSubscriptionMutation);

  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "public.newsletters.success"),
  ];
}
