import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import _pick from "lodash/pick";
import _mapValues from "lodash/mapValues";

import {
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import { Col, Row } from "../components/layout.jsx";
import {
  Form,
  FormButton,
  FormField,
  FormInput,
} from "../components/forms.jsx";
import Tiles from "../components/Tiles.jsx";
import { useNavigationPage } from "../services/navigation.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";
import { useUserPasswordReset } from "../services/auth.js";
import ConfirmationPopup from "../components/ConfirmationPopup.jsx";
import { useLocationQuery } from "../util/router.js";

const PasswordResetForm = styled(Form)`
  max-width: 555px;
  margin-bottom: 40px;
`;

function usePasswordResetForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        confirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe doivent correspondre"
        ),
      })
    ),
  });
}

export default function PasswordReset() {
  const page = useNavigationPage();
  const [success, setSuccess] = useState(false);

  const query = useLocationQuery();
  const token = query.get("token");

  const {
    register,
    setError,
    watch,
    clearErrors,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = usePasswordResetForm();

  const [
    userPasswordReset,
    userPasswordResetResult,
    { loading, called, graphQLErrors: passwordResetErrors },
  ] = useUserPasswordReset(token);

  const onSubmit = useCallback(
    function(data) {
      userPasswordReset(data.password);
    },
    [clearErrors, userPasswordReset]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(passwordResetErrors).forEach((err) => {
        switch (err.code) {
          default:
            setError("_global", err);
        }
      });
      filterBadUserInputErrors(passwordResetErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (userPasswordResetResult === false) {
        setError("_global", {
          message:
            "La réinitialisation du mot de passe a échoué. Veuillez réessayer plus tard.",
        });
      }
      if (hasErrors(passwordResetErrors) || !userPasswordResetResult) return;

      // en cas de succès, affiche une popup
      setSuccess(true);

      // clear le form
      try {
        reset();
      } catch (e) {
        console.log(e);
      }
    },
    [loading, userPasswordResetResult, passwordResetErrors]
  );

  return (
    <TwoColumnsPageTemplate>
      <ConfirmationPopup
        title="Réinitialisation de mot de passe"
        open={success}
        onClose={() => setSuccess(false)}
      >
        Votre mot de passe a été mis à jour. Vous pouvez désormais vous
        connecter avec vos identifiants et votre nouveau mot de passe.
      </ConfirmationPopup>
      <PasswordResetForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        errors={errors}
      >
        <Row>
          <Col span={6} sm={12}>
            <FormField
              label="Mot de passe"
              required
              errors={errors}
              type="password"
              name="password"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="*******"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
          <Col span={6} sm={12}>
            <FormField
              label="Confirmer le mot de passe"
              required
              errors={errors}
              type="password"
              name="confirmation"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  placeholder="*******"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </Col>
        </Row>
        <FormButton type="submit">Valider</FormButton>
      </PasswordResetForm>

      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
