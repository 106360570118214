import { color, styled } from "../util/style.js";
import Icon from "./Icon.jsx";
import BasePageLink from "./PageLink.jsx";

const Page = styled.li``;

const PageLink = styled(BasePageLink)`
  color: ${color("black")};
  font-size: 14px;
  transition: color 0.3s ease;
  --icon-transition: opacity 0.3s ease;
  &:hover {
    color: ${color("orange")};
    text-decoration: underline;
    --icon-hover: 1;
  }
`;

const HomeIcon = styled(Icon)`
  vertical-align: middle;
  margin: -1px -3px 0;
`;

export default styled(function Breadcrumb({ pages, className }) {
  return (
    !!pages?.length && (
      <ol
        className={className}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {pages.map((page, index) => (
          <Page
            key={page.id}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <meta itemProp="position" content={index} />
            <PageLink to={page} itemProp="item">
              {page.kind === "index" ? (
                <>
                  <meta itemProp="name" content="À la Une" />
                  <HomeIcon
                    name="home"
                    variant="black"
                    hoverVariant="red"
                    size={24}
                  />
                  {pages.length === 1 && <span> Retour à l'accueil</span>}
                </>
              ) : (
                <span itemProp="name">{page.title || page.name}</span>
              )}
            </PageLink>
          </Page>
        ))}
      </ol>
    )
  );
})`
  display: inline-flex;
  flex-wrap: wrap;
  background-color: white;
  color: ${color("black")};
  list-style: none;
  margin: 0;
  padding: 0.7em 1.4em;

  & > ${Page} + ${Page}::before {
    background-color: ${color("black")};
    border-radius: 50px;
    content: "";
    display: inline-block;
    margin: 0 6px;
    height: 4px;
    position: relative;
    top: -2px;
    width: 4px;
  }
`;
