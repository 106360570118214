export function fcmToNotification(payload) {
  return payload
    ? {
        id: payload.messageId,
        body: payload.notification?.body,
        title: payload.notification?.title,
        href: payload.data?.link,
      }
    : null;
}
