import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Loader from "react-spinners/DotLoader";
import styled, { useTheme } from "styled-components";

import { buildPath } from "../../../common/alias.js";
import { LoginProvider } from "../../components/auth/Login.jsx";
import NewsletterQuickSubscription from "../../components/NewsletterQuickSubscription.jsx";
import { useUserSession } from "../../services/auth.js";
import { useNavigation } from "../../services/navigation.js";
import { getMediaUrl } from "../../util/medias.js";
import Footer from "../common/Footer.jsx";
import Header from "../common/Header.jsx";
import Root from "./Root.jsx";

const APP_BASE_URI = (process.env.WEBSITE_URI || "").replace(/\/$/, "");

export default function DynamicPageTemplate({ children }) {
  const [{ page, parameters }, { loading }] = useNavigation();
  const [{ isSubscriptionActive } = {}] = useUserSession();
  const fullUrl = APP_BASE_URI + buildPath(page.alias, parameters);
  useEffect(
    function() {
      if (typeof dataLayer === "undefined") return;
      const to = window.setTimeout(function() {
        // eslint-disable-next-line no-undef
        dataLayer.push({ event: "PageReady" }); // Évènement seulement lorsque la page est vraiment chargée
      }, 50);
      return () => clearTimeout(to);
    },
    [fullUrl]
  );
  return (
    <Root>
      <Helmet {...(Boolean(page.seo?.useFullTitle) && { titleTemplate: "%s" })}>
        <title>{page.seo?.title || page.title || page.name}</title>
        {Boolean(page.hideFromSearchEngine) && (
          <meta name="robots" content="noindex" />
        )}
        <meta
          name="description"
          content={page.seo?.description || page.summary || ""}
        />
        <meta name="keywords" content={page.seo?.keywords || ""} />
        <meta
          property="og:title"
          content={page.seo?.title || page.title || page.name}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={fullUrl} />
        <meta
          property="og:description"
          content={page.seo?.description || page.summary || ""}
        />
        {Boolean(page.image) && (
          <meta
            property="og:image"
            content={getMediaUrl(page.image, "article-head")}
          />
        )}
        <meta property="fb:app_id" content="136529520296393" />
        <link rel="canonical" href={fullUrl} />
      </Helmet>
      {!isSubscriptionActive && <NewsletterQuickSubscription />}
      <LoginProvider>
        <Header />
        {!page && loading ? <PageLoading /> : children}
        <Footer />
      </LoginProvider>
    </Root>
  );
}

const PageLoading = styled(function({ className }) {
  const theme = useTheme();
  return (
    <div className={className}>
      <Loader size={60} color={theme.colors.orange} css="display: block;" />
    </div>
  );
})`
  margin: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
