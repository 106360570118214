import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import * as ReactTiles from "../ReactTiles.js";
import reactTilesStyle from "../style/reactTilesStyle.js";

//TODO Fix this https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
function useMediaQuery(mediaQuery) {
  const [mediaQueryMatches, setMediaQueryMatches] = useState(false);
  const updateMediaQueryMatches = useCallback(
    function() {
      setMediaQueryMatches(matchMedia(mediaQuery).matches);
    },
    [mediaQuery, setMediaQueryMatches]
  );

  useEffect(() => {
    if (typeof window === "undefined") return;
    window.addEventListener("resize", updateMediaQueryMatches);
    updateMediaQueryMatches();
    return () => window.removeEventListener("resize", updateMediaQueryMatches);
  }, [updateMediaQueryMatches]);

  return mediaQueryMatches;
}

export default styled(function ReactTilesRenderer({
  tiles,
  className,
  options = {},
}) {
  const onSmallDevice = useMediaQuery("(max-width: 992px)");

  return (
    <div className={className}>
      {tiles
        .filter((tile) => {
          if (!tile.contexts || !tile.contexts.length) return true;
          if (onSmallDevice) return tile.contexts.indexOf("mobile") !== -1;
          else return tile.contexts.indexOf("desktop") !== -1;
        })
        .map((tile) => {
          const ReactTileComponent = ReactTiles[tile.kind];

          const tileOptions = {
            ...options,
            ...((options.tiles && options.tiles[tile.kind]) || {}),
          };

          return (
            <ReactTileComponent
              key={tile.id}
              tile={tile}
              options={tileOptions}
            />
          );
        })}
    </div>
  );
})`
  ${reactTilesStyle}
`;
