import _mapValues from "lodash/mapValues";
import _pick from "lodash/pick";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Controller,
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import ConfirmationPopup from "../components/ConfirmationPopup.jsx";
import {
  Form,
  FormButton,
  FormCheckbox,
  FormField,
  FormInput,
  FormSection,
  FormSelect,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import NewsletterPreferencesForm from "../components/NewsletterPreferencesForm.jsx";
import Tiles from "../components/Tiles.jsx";
import * as validator from "../components/validators.js";
import {
  useNavigationPage,
  useNavigationPageParameters,
} from "../services/navigation.js";
import {
  INTERESTS,
  typeToModel,
  useNewsletterSubscription,
} from "../services/newsletter.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const NewsletterSubscriptionForm = styled(Form)`
  max-width: 555px;
  margin-bottom: 40px;
`;

const Typography = styled.div`
  margin-top: -4px;
  ${TypographyStyle}
`;

function getDefaultValues() {
  return {
    email: "",
    firstname: "",
    lastname: "",
    profileKind: "",
    subscriptionKinds: {
      daily: false,
      flash: false,
      partners: false,
    },
    ..._mapValues(
      INTERESTS,
      (interests) =>
        interests.reduce(
          (interests, interest) => ({
            ...interests,
            [interest.key]: false,
          }),
          {}
        ),
      {}
    ),
    time: INTERESTS.times[0].key,
  };
}

function useNewsletterSubscriptionForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        firstname: validator.firstname.required(
          "Veuillez renseigner votre prénom"
        ),
        lastname: validator.lastname.required("Veuillez renseigner votre nom"),
        email: validator.email.required("Veuillez renseigner votre email"),
        profileKind: Yup.string()
          .oneOf(
            ["professional", "personal"],
            "Veuillez choisir un profil valide"
          )
          .required("Veuillez choisir votre profil"),
        consent: Yup.boolean().oneOf(
          [true],
          "Veuillez accepter les conditions"
        ),
      })
    ),
  });
}

export default function NewsletterSubscription() {
  const page = useNavigationPage();
  const pageParameters = useNavigationPageParameters();
  const [success, setSuccess] = useState(false);

  const {
    register,
    watch,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useNewsletterSubscriptionForm(getDefaultValues());

  const [
    newsletterSubscription,
    newsletterSubscriptionResult,
    { loading, called, graphQLErrors: newsletterSubscriptionErrors },
  ] = useNewsletterSubscription();

  const onSubmit = useCallback(
    function(data) {
      newsletterSubscription({
        ..._pick(data, ["firstname", "lastname", "email", "profileKind"]),
        ...typeToModel(data),
      });
    },
    [clearErrors, newsletterSubscription]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(newsletterSubscriptionErrors).forEach((err) => {
        switch (err.code) {
          case "user-duplicate":
            setError("_global", {
              message: "Vous êtes déjà inscrit(e) à notre newsletter",
            });
            break;
          default:
            setError("_global", err);
        }
      });
      filterBadUserInputErrors(newsletterSubscriptionErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (newsletterSubscriptionResult === false) {
        setError("_global", {
          message: "L'inscription a échoué. Veuillez réessayer plus tard.",
        });
      }
      if (
        hasErrors(newsletterSubscriptionErrors) ||
        !newsletterSubscriptionResult
      )
        return;

      // en cas de succès, afficher une dialog avec le résultat
      setSuccess(true);
      // clear le form
      try {
        reset(getDefaultValues());
      } catch (e) {
        console.log(e);
      }
    },
    [loading, newsletterSubscriptionResult, newsletterSubscriptionErrors]
  );

  return (
    <TwoColumnsPageTemplate>
      <Tiles tiles={page.contents} />

      <ConfirmationPopup
        title="Inscription réussie"
        open={success}
        onClose={() => setSuccess(false)}
      >
        Vous avez été inscrit à la newsletter.
      </ConfirmationPopup>
      <NewsletterSubscriptionForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        errors={errors}
      >
        <FormSection title="Mes informations">
          <Row>
            <Col span={8} sm={12} fixed>
              <FormField
                label="Adresse email"
                required
                errors={errors}
                name="email"
              >
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : c.vaultier@email.com"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={6} sm={12}>
              <FormField
                label="Prénom"
                required
                errors={errors}
                name="firstname"
              >
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : Camille"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
            <Col span={6} sm={12}>
              <FormField label="Nom" required errors={errors} name="lastname">
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : Vaultier"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>

          <Row>
            <Col span={8} sm={12} fixed>
              <FormField
                label="C'est un mail"
                errors={errors}
                name="profileKind"
                required
              >
                {({ name, ...childProps }) => (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        placeholder="Choisir..."
                        {...field}
                        {...childProps}
                        options={[
                          {
                            value: "personal",
                            label: "personnel",
                          },
                          {
                            value: "professional",
                            label: "professionnel",
                          },
                        ]}
                      />
                    )}
                  />
                )}
              </FormField>
            </Col>
          </Row>
        </FormSection>

        <NewsletterPreferencesForm {...{ control, register, watch, errors }} />

        <FormSection>
          <FormField errors={errors} name="consent">
            {({ name, ...childProps }) => (
              <FormCheckbox {...register(name)} {...childProps}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: pageParameters.consentText || "",
                  }}
                />
              </FormCheckbox>
            )}
          </FormField>

          <FormButton type="submit">S'inscrire</FormButton>
        </FormSection>
      </NewsletterSubscriptionForm>
    </TwoColumnsPageTemplate>
  );
}
