import { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";

import HttpStatusRoute from "./components/HttpStatusRoute.jsx";
import Account from "./pages/Account.jsx";
import Article from "./pages/Article.jsx";
import Articles from "./pages/Articles.jsx";
import ArticlesByTag from "./pages/ArticlesByTag.jsx";
import CompleteAccount from "./pages/CompleteAccount.jsx";
import Contact from "./pages/Contact.jsx";
import DefaultPage from "./pages/DefaultPage.jsx";
import Error404 from "./pages/Error404.jsx";
import Error403 from "./pages/Error403.jsx";
import Favorites from "./pages/Favorites.jsx";
import Group from "./pages/Group.jsx";
import Home from "./pages/Home.jsx";
import NewsletterPreferences from "./pages/NewsletterPreferences.jsx";
import NewsletterSubscription from "./pages/NewsletterSubscription.jsx";
import PasswordReset from "./pages/PasswordReset.jsx";
import PasswordResetRequest from "./pages/PasswordResetRequest.jsx";
import PrivateArea from "./pages/PrivateArea.jsx";
import Redirection from "./pages/Redirection.jsx";
import Search from "./pages/Search.jsx";
import Signup from "./pages/Signup.jsx";
import ValidateEmail from "./pages/ValidateEmail.jsx";
import Notices from "./pages/Notices.jsx";
import TenderInvitation from "./pages/TenderInvitation.jsx";
import { PwaProvider } from "./Pwa.jsx";
import {
  NavigationProvider,
  useNavigationByPath,
} from "./services/navigation.js";
import DynamicPageTemplate from "./templates/dynamic-page/DynamicPageTemplate.jsx";
import { useUserSession } from "./services/auth.js";
import { NotificationsProvider } from "./Notifications.jsx";

import { ThemeProvider } from "./templates/common/styles.jsx";

function memberOnly(Component) {
  return function PrivateComponent() {
    const [session] = useUserSession();
    if (!session) return <Error403 />;
    return <Component />;
  };
}

const PAGES_COMPONENTS = {
  default: DefaultPage,
  index: Home,
  redirection: Redirection,
  article: Article,
  articles: Articles,
  articlesByTag: ArticlesByTag,
  contact: Contact,
  group: Group,
  search: Search,
  error404: Error404,
  error403: Error403,
  newsletterSubscription: NewsletterSubscription,
  newsletterPreferences: NewsletterPreferences,
  signup: Signup,
  privateArea: memberOnly(PrivateArea),
  favorites: memberOnly(Favorites),
  account: memberOnly(Account),
  completeAccount: CompleteAccount,
  passwordResetRequest: PasswordResetRequest,
  passwordReset: PasswordReset,
  validateEmail: ValidateEmail,
  tenderInvitation: TenderInvitation,
  notices: Notices,
};

function App() {
  const [loadingNavigationInfos, meta] = useNavigationByPath();
  const [navigationInfos, setNavigationInfos] = useState(
    loadingNavigationInfos
  );
  useEffect(
    function() {
      if (!meta.ready || !loadingNavigationInfos) return;
      setNavigationInfos(loadingNavigationInfos);
    },
    [setNavigationInfos, loadingNavigationInfos, meta.ready]
  );

  if (
    // Server-side 404, no graphql errors
    (meta.ready && !navigationInfos) ||
    // Client-side 404
    (meta.graphQLErrors &&
      meta.graphQLErrors.find(({ message }) => message === "Page not found"))
  ) {
    return (
      <HttpStatusRoute statusCode={404}>
        <h1>Page not found</h1>
      </HttpStatusRoute>
    );
  }
  if (!navigationInfos) return <></>; // Loading

  const { page, parameters, index, template, ...restInfos } = navigationInfos;
  const Component =
    (page && PAGES_COMPONENTS[page.kind]) || PAGES_COMPONENTS["default"];

  return (
    <ThemeProvider>
      <PwaProvider>
        <NotificationsProvider>
          <NavigationProvider
            value={{
              index,
              page,
              parameters,
              template,
              meta,
              ...restInfos,
            }}
          >
            <DynamicPageTemplate>
              <Component />
            </DynamicPageTemplate>
          </NavigationProvider>
        </NotificationsProvider>
      </PwaProvider>
    </ThemeProvider>
  );
}

export default hot(App);
