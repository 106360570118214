import { Helmet } from "react-helmet";

export default function Wysistat({ name }) {
  return (
    !!name && (
      <Helmet>
        <script type="text/javascript">
          {`
            var _wsq = _wsq || [];
            _wsq.push(['_setNom', '${name}']);
            _wsq.push(['_wysistat']);
            (function(){
              var ws = document.createElement('script');
              ws.type = 'text/javascript';
              ws.async = true;
              ws.src = ('https:' == document.location.protocol ? 'https://www' : 'http://www') + '.wysistat.com/ws.jsa';
              var s = document.getElementsByTagName('script')[0]||document.getElementsByTagName('body')[0];
              s.parentNode.insertBefore(ws, s);
            })();
          `}
        </script>
      </Helmet>
    )
  );
}
