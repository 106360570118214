import { useCallback, useEffect } from "react";
import Popup from "reactjs-popup";
import { keyframes } from "styled-components";

import { useSendAlertComment } from "../services/articles.js";
import { color, styled } from "../util/style.js";
import {
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "./base-forms/forms.js";
import {
  Form,
  FormButton,
  FormField,
  FormInput,
  FormTextarea,
} from "./forms.jsx";
import Icon from "./Icon.jsx";
import * as validator from "./validators.js";

const PopupText = styled.p`
  margin-bottom: 12px;
  text-align: center;
  font-size: 14px;
`;

function useAlertCommentForm() {
  return useForm({
    mode: "onTouched",
    resolver: yupResolver(
      Yup.object().shape({
        email: validator.email.required("Veuillez renseigner votre email"),
        message: Yup.string().required(
          "Veuillez indiquer les raisons de votre signalement"
        ),
      })
    ),
  });
}

export default styled(function AlertCommentPopup({
  comment,
  article,
  onClose,
  className,
}) {
  const {
    register,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    handleSubmit,
  } = useAlertCommentForm();

  const [
    sendAlertComment,
    sendAlertCommentResult,
    { loading, called, graphQLErrors },
  ] = useSendAlertComment(article);

  const onSubmit = useCallback(
    function(data) {
      sendAlertComment(comment, data.email, data.message);
    },
    [comment, sendAlertComment]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(graphQLErrors).forEach((err) => {
        switch (err.code) {
          default:
            setError("_global", err);
        }
      });
      filterBadUserInputErrors(graphQLErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (hasErrors(graphQLErrors) || !sendAlertCommentResult) return;

      // en cas de succès, appeler le callback
      onClose();
      // clear le form
      try {
        reset();
      } catch (e) {
        console.log(e);
      }
    },
    [
      loading,
      called,
      sendAlertCommentResult,
      graphQLErrors,
      clearErrors,
      reset,
      onClose,
      setError,
    ]
  );

  useEffect(() => {
    // Fermeture de la popin, reset de champs
    if (!comment) {
      clearErrors();
      reset();
    }
  }, [!comment, clearErrors, reset]);

  return (
    <Popup
      className={className}
      onClose={onClose}
      open={!!comment}
      closeOnDocumentClick
    >
      {(close) => (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          loading={String(loading ? "loading" : "")}
          errors={errors}
        >
          <header>
            Signaler un abus sur ce commentaire
            <button onClick={close}>
              <Icon name="close" variant="black" hoverVariant="red" />
            </button>
          </header>
          <main>
            <PopupText>
              Ce commentaire vous semble ne pas correspondre à la charte du site
              ? Nous vous remercions de nous le signaler afin de prendre les
              mesures nécessaires.
            </PopupText>

            <FormField
              label="Votre adresse email"
              required
              errors={errors}
              name="email"
            >
              {({ name, ...childProps }) => (
                <FormInput
                  {...register(name)}
                  {...childProps}
                  placeholder="Ex : c.vaultier@email.com"
                />
              )}
            </FormField>
            <FormField label="Votre message" errors={errors} name="message">
              {({ name, ...childProps }) => (
                <FormTextarea
                  placeholder="Pourquoi ce message ne devrait pas être publié selon vous ?"
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          </main>

          <footer>
            <FormButton mini text key="cancel" onClick={close}>
              Annuler
            </FormButton>
            <FormButton mini key="confirm">
              J'envoie le signalement
            </FormButton>
          </footer>
        </Form>
      )}
    </Popup>
  );
})`
  &-overlay {
    background-color: ${color("overlayBackground")};
  }

  &-content {
    position: relative;
    background: white;
    max-width: 400px;

    header,
    main,
    footer {
      padding: 20px;
    }

    header {
      background: ${color("greyBg")};
      padding-right: 56px;

      button {
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }
    footer {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
