import { useEffect, useState } from "react";

import { formatDistance } from "../../common/date.js";
import {
  useArticleAddComment,
  useArticleDeleteComment,
  useArticleUpdateComment,
} from "../services/articles.js";
import { useUserSession } from "../services/auth.js";
import { useOneOfAKindPage } from "../services/navigation.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import { color, context, font, styled } from "../util/style.js";
import AlertCommentPopup from "./AlertCommentPopup.jsx";
import { useLogin } from "./auth/Login.jsx";
import { Yup, useForm, yupResolver } from "./base-forms/forms.js";
import ConfirmationPopup from "./ConfirmationPopup.jsx";
import {
  Form,
  FormButton,
  FormField,
  FormTextarea,
  useFormAction,
} from "./forms.jsx";
import Icon from "./Icon.jsx";
import PageLink from "./PageLink.jsx";
import OriginalSeparator from "./Separator.jsx";

const MAX_LENGTH_COMMENT = 1000;
const DEFAULT_COMMENTS_LIMIT = 3;
const COMMENTS_PER_PAGE = 100;

function sanitize(message) {
  return message.replace(/\n\n+/gm, "\n\n");
}

function useCommentForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        message: Yup.string()
          .nullable()
          .max(MAX_LENGTH_COMMENT, "Votre message est trop long.")
          .required("Veuillez rédiger un message"),
      })
    ),
  });
}

const Typography = styled.div`
  margin-top: -4px;
  ${TypographyStyle}
`;

const Separator = styled(OriginalSeparator)`
  margin: 0;
  margin-bottom: 24px;
  ${context("for-phone")`
    margin-bottom: 12px;
  `}
`;

const CommentsHeadline = styled.h2`
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
  padding: 6px 0;
`;

const CommentActionLink = styled(FormButton)`
  & + & {
    margin-left: 10px;
  }
`;

const CommentsExplanations = styled(function({ actions, children, ...props }) {
  return (
    <div {...props}>
      <div className="CommentsExplanations-intro">{children}</div>
      {Boolean(actions) && (
        <div className="CommentsExplanations-actions">{actions}</div>
      )}
    </div>
  );
})`
  display: flex;
  align-items: center;
  font: normal 16px/140% ${font("primary")};

  .CommentsExplanations-intro,
  .CommentsExplanations-actions {
    padding: 6px 0;
  }

  .CommentsExplanations-intro {
    flex: 1;
  }

  .CommentsExplanations-actions {
    padding-left: 24px;
    text-align: right;
  }

  .CommentsExplanations-headline {
    display: flex;
    font: bold 18px/140% ${font("primary")};
    padding: 6px 0;
    margin: 0;
    align-items: center;
  }
  .CommentsExplanations-text {
    padding: 6px 0;
    margin: 0;
  }

  ${context("for-phone")`
    display: block;
    .CommentsExplanations-actions {
      padding-left: 0;
      text-align: left;
    }
  `}
`;

const FormButtonIcon = styled(FormButton)`
  --icon-transition: opacity 0.3s ease;
  &:hover,
  &:focus {
    --icon-hover: 1;
  }
  ${Icon} {
    margin: -12px 0 -9px 0;
    vertical-align: middle;
  }
`;

const SeeMoreContainer = styled.div`
  text-align: center;
  margin: 12px 0;
`;

const Comment = styled(function({
  article,
  id,
  author,
  date,
  editable,
  onUpdate,
  onAlert,

  className,
  message: originalMessage,
}) {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const form = useCommentForm({ message: originalMessage });
  const {
    register,
    watch,
    reset,
    formState: { errors },
  } = form;
  const message = watch("message");

  useEffect(() => reset({ message: originalMessage }), [originalMessage]);

  const actionDeleteComment = useArticleDeleteComment(article?.id, id);
  const [onDeleteComment, { loading: loadingDeleteComment }] = useFormAction(
    form,
    actionDeleteComment,
    {
      transformData: () => {},
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre commentaire n'a pas pu être supprimé. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        // en cas de succès, fermer la dialog
        setDeleting(false);
        // recharger l'article
        if (onUpdate) onUpdate();
      },
    }
  );

  const actionUpdateComment = useArticleUpdateComment(article?.id, id);
  const [onUpdateComment, { loading: loadingUpdateComment }] = useFormAction(
    form,
    actionUpdateComment,
    {
      transformData: (data) => sanitize(data.message),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre commentaire n'a pas pu être mis à jour. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        // en cas de succès, fermer la dialog
        setEditing(false);
        // recharger l'article
        if (onUpdate) onUpdate();
      },
    }
  );
  return (
    <>
      <ConfirmationPopup
        title="Confirmation de suppression"
        open={deleting}
        onClose={() => setDeleting(false)}
        actions={
          <>
            <FormButton
              mini
              text
              key="confirm"
              onClick={() => {
                onDeleteComment();
                setDeleting(true);
              }}
            >
              Je confirme la suppression
            </FormButton>
            <FormButton mini key="cancel" onClick={() => setDeleting(false)}>
              Annuler
            </FormButton>
          </>
        }
      >
        <Typography>
          <p>
            Voulez-vous réellement supprimer ce commentaire ? Cette action est
            irréversible.
          </p>
        </Typography>
      </ConfirmationPopup>
      <Form
        className={className}
        onSubmit={onUpdateComment}
        loading={loadingUpdateComment || loadingDeleteComment}
      >
        <div className="Comment-header">
          <div className="Comment-headline">
            <div className="Comment-author">{author}</div>
            <div className="Comment-secondary">
              <span className="Comment-date">{date}</span>
              {" - "}
              <button className="Comment-alert" type="button" onClick={onAlert}>
                Signaler un abus
              </button>
            </div>
          </div>
          {!!editable && (
            <div className="Comment-actions">
              {editing ? (
                <>
                  <FormButton
                    mini
                    text
                    onClick={() => setEditing(false)}
                    key="cancel"
                  >
                    Annuler
                  </FormButton>
                  <FormButtonIcon
                    mini
                    onClick={() => setEditing(true)}
                    key="validate"
                  >
                    <Icon
                      name="check"
                      size="24"
                      variant="white"
                      hoverVariant="red"
                    />
                    Valider
                  </FormButtonIcon>
                </>
              ) : (
                <>
                  <FormButton
                    mini
                    text
                    key="delete"
                    onClick={() => setDeleting(true)}
                  >
                    Supprimer
                  </FormButton>
                  <FormButtonIcon
                    mini
                    onClick={() => setEditing(true)}
                    key="edit"
                  >
                    <Icon
                      name="pen"
                      size="24"
                      variant="white"
                      hoverVariant="red"
                    />
                    Modifier
                  </FormButtonIcon>
                </>
              )}
            </div>
          )}
        </div>
        <div className="Comment-content">
          {editing ? (
            <FormField errors={errors} name="message">
              {({ name, ...childProps }) => (
                <FormTextarea
                  placeholder="Rédigez ici votre message..."
                  info={`${String(message || "").length ||
                    0}/${MAX_LENGTH_COMMENT}`}
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
          ) : (
            sanitize(originalMessage)
          )}
        </div>
      </Form>
    </>
  );
})`
  background-color: ${color("greyBg2")};
  padding: 20px 24px;
  border-left: 4px solid ${color("orange")};

  & + & {
    margin-top: 18px;
  }

  .Comment-header {
    display: flex;
    flex-direction: row;
  }

  .Comment-headline {
    flex: 1;
  }

  .Comment-author {
    font-family: ${font("secondary")};
    font-size: 18px;
    line-height: 20px;
    font-weight: bolder;
  }
  .Comment-secondary {
    font-size: 12px;
    color: ${color("greyTxt")};
    margin-bottom: 1.5em;
  }
  .Comment-date {
  }
  .Comment-alert {
    background: none;
    border: 0;
    color: ${color("greyTxt")};
    font-size: 12px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .Comment-content {
    white-space: pre-wrap;
  }
`;

export default function ArticleComments({
  article,
  introduction = null,
  onUpdate,
}) {
  const [commentsLimit, setCommentsLimit] = useState(DEFAULT_COMMENTS_LIMIT);
  const [currentAlertComment, setCurrentAlertComment] = useState();

  const [pageRegistration] = useOneOfAKindPage("signup");
  const [session] = useUserSession();
  const { openPopin } = useLogin();

  const formAddComment = useCommentForm();
  const {
    register,
    watch,
    formState: { errors },
  } = formAddComment;
  const message = watch("message");

  const [successAddComment, setSuccessAddComment] = useState();

  const actionAddComment = useArticleAddComment(article?.id);
  const [onAddComment, { loading: loadingAddComment }] = useFormAction(
    formAddComment,
    actionAddComment,
    {
      transformData: (data) => sanitize(data.message),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre commentaire n'a pas pu être envoyé. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        // en cas de succès, afficher une dialog avec le résultat
        setSuccessAddComment(true);
        // recharger l'article
        if (onUpdate) onUpdate();
      },
    }
  );

  return (
    <>
      <ConfirmationPopup
        title="Commentaire ajouté"
        open={successAddComment}
        onClose={() => setSuccessAddComment(false)}
      >
        <Typography>
          Votre commentaire a bien été enregistré. Merci pour votre
          participation.
        </Typography>
      </ConfirmationPopup>
      <AlertCommentPopup
        key={currentAlertComment ? currentAlertComment.id : "none"}
        comment={currentAlertComment}
        article={article}
        onClose={() => setCurrentAlertComment(null)}
        actions={
          <>
            <FormButton
              mini
              textkey="cancel"
              onClick={() => setCurrentAlertComment(null)}
            >
              Annuler
            </FormButton>
            <FormButton
              mini
              key="confirm"
              onClick={() => {
                // onAlertComment();
                setCurrentAlertComment(null);
              }}
            >
              J'envoie le signalement
            </FormButton>
          </>
        }
      ></AlertCommentPopup>
      <CommentsHeadline>
        Commentaires{" "}
        {!!article.comments?.edges?.length &&
          `(${article.comments?.edges?.length})`}
      </CommentsHeadline>
      <Separator />
      {!!session ? (
        <>
          <CommentsExplanations>
            <p className="CommentsExplanations-headline">
              <Icon
                name={"comment"}
                alt={`Commentaires`}
                size={32}
                variant="red"
              />
              Réagissez à cet article
            </p>
            <p className="CommentsExplanations-text">
              Vous commentez en tant que{" "}
              {`${session.firstname} ${session.lastname}`.trim() ||
                session.account?.username ||
                session.email}
              .
            </p>
            {introduction}
          </CommentsExplanations>
          <Form
            onSubmit={onAddComment}
            loading={loadingAddComment}
            errors={errors}
          >
            <FormField errors={errors} name="message">
              {({ name, ...childProps }) => (
                <FormTextarea
                  placeholder="Rédigez ici votre message..."
                  info={`${String(message || "").length ||
                    0}/${MAX_LENGTH_COMMENT}`}
                  {...register(name)}
                  {...childProps}
                />
              )}
            </FormField>
            <FormField>
              <FormButton type="submit">Envoyer</FormButton>
            </FormField>
          </Form>
        </>
      ) : (
        <>
          <CommentsExplanations
            actions={
              <>
                <CommentActionLink onClick={() => openPopin()}>
                  Se connecter
                </CommentActionLink>
                {!!pageRegistration && (
                  <CommentActionLink
                    forwardedAs={PageLink}
                    ghost
                    to={pageRegistration}
                  >
                    S'inscrire
                  </CommentActionLink>
                )}
              </>
            }
          >
            <p className="CommentsExplanations-headline">
              <Icon
                name={"comment"}
                alt={`Commentaires`}
                size={32}
                variant="red"
              />
              Réagissez à cet article
            </p>
            <p className="CommentsExplanations-text">
              Vous devez être connecté(e) pour poster un commentaire
            </p>
          </CommentsExplanations>
        </>
      )}
      {Boolean(commentsLimit < (article.comments?.edges?.length || 0)) && (
        <SeeMoreContainer>
          <FormButton
            mini
            ghost
            onClick={() => setCommentsLimit(commentsLimit + COMMENTS_PER_PAGE)}
          >
            Voir les commentaires les plus anciens
          </FormButton>
        </SeeMoreContainer>
      )}
      {(article.comments?.edges || [])
        .slice(-commentsLimit)
        .map(({ node: comment }) => (
          <Comment
            key={comment.id}
            id={comment.id}
            article={article}
            author={
              comment.author
                ? `${comment.author.firstname} ${comment.author.lastname}`.trim() ||
                  "Anonyme"
                : "Presselib"
            }
            date={comment.date && formatDistance(comment.date, new Date())}
            editable={!!session && comment.author?.id === session?.id}
            onUpdate={onUpdate}
            onAlert={() => setCurrentAlertComment(comment)}
            message={comment.text}
          />
        ))}
    </>
  );
}
