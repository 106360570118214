/**
 * Build a clean url with paths
 * @param $paths {string}
 * @returns string
 */
export function url(...$paths) {
  return $paths
    .join("/")
    .replace(/\/+/g, "/")
    .replace(/^(\w+):\//, "$1://");
}

/**
 * Fixes security issues with including scripts with unmanaged contents
 * @param {string} js JavaScript to include on the webpage
 * @returns {string}
 */
export function script(js) {
  return `/*<!--*/
${(js || "").replace(/(<\/)(script\W)/gim, "<\\/$2")}
/*-->*/`;
}
