import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import {
  paginatedGql,
  tilesFragment,
  toStandardResult,
} from "../util/graphql.js";

const articleSchema = `
  id
  alias
  kind
  date
  publishedAt
  publishedLastAt
  title
  summary
  image { id name version credits description }
  imageCaption
  tags
  isCurrentlyFeatured
  authors { id name }
  hideFromSearchEngine
  seo { title description keywords useFullTitle }
`;

const articlesListQuery = gql`
  query FindArticles($filter: ArticlesFilterPublicInput, $pagination: PaginationInput) {
    public {
      articles {
        list: findArticles(filter: $filter, pagination: $pagination) {
          ${paginatedGql(articleSchema)}
        }
      }
    }
  }
`;

const articlesSearchQuery = gql`
  query SearchArticles($filter: ArticlesFilterPublicInput, $pagination: PaginationInput) {
    public {
      articles {
        list: searchArticles(filter: $filter, pagination: $pagination) {
          ${paginatedGql(articleSchema)}
        }
      }
    }
  }
`;

export function useArticlesList(
  {
    categories,
    locations,
    additionalTags,
    tags,
    kinds,
    featured,
    home,
    maxDays,
  } = {},
  pagination,
  skip = false
) {
  const result = useQuery(articlesListQuery, {
    variables: {
      pagination,
      filter: {
        ...(!!home && { home: "yes" }),
        ...(!!featured && { featured: "yes" }),
        ...(!!kinds && { kinds }),
        ...(!!maxDays && { maxDays }),
        tags: tags || [
          ...(categories || []).map((category) => `category:${category}`),
          ...(locations || []).map((location) => `location:${location}`),
          ...(additionalTags || []).map((tag) => `tag:${tag}`),
        ],
      },
    },
    skip,
  });

  return toStandardResult(result, "public.articles.list");
}

export function useHomeArticlesList(pagination) {
  return useArticlesList({ home: true }, pagination);
}

const articleByAliasQuery = gql`
  query Article($alias: String!, $previewToken: String) {
    public {
      articles {
        detail: fetchArticleByAlias(alias: $alias, previewToken: $previewToken) {
          ${articleSchema}
          introduction
          contents {
            ${tilesFragment()}
          }
          comments(pagination: {after: 0, limit: 9999}) { 
            ${paginatedGql(`id text date author { id lastname firstname }`)}
          }
        }
      }
    }
  }`;

export function useArticleByAlias(alias, previewToken = null) {
  const result = useQuery(articleByAliasQuery, {
    skip: !alias,
    variables: { alias, previewToken },
  });

  return toStandardResult(result, "public.articles.detail");
}

export function useArticlesSearch({ search } = {}, pagination) {
  const result = useQuery(articlesSearchQuery, {
    variables: {
      pagination,
      filter: {
        ...(!!search && { search }),
      },
    },
  });

  return toStandardResult(result, "public.articles.list");
}

const similarArticlesListQuery = gql`
  query FindSimilarArticles($article: ID!, $pagination: PaginationInput) {
    public {
      articles {
        list: findSimilarArticles(article: $article, pagination: $pagination) {
          ${paginatedGql(articleSchema)}
        }
      }
    }
  }
`;

export function useSimilarArticlesList(article, pagination = { limit: 10 }) {
  const [query, variables] =
    article?.kind === "short"
      ? [
          articlesListQuery, // Cas particulier Twittolib : On prend les plus récents (< 24h)
          {
            pagination,
            filter: {
              kinds: ["short"],
              maxDays: 1,
            },
          },
        ]
      : [
          similarArticlesListQuery,
          {
            pagination,
            article: article?.id,
          },
        ];

  const rawResult = useQuery(query, {
    skip: !article?.id,
    variables,
  });

  const [results, ...rest] = toStandardResult(
    rawResult,
    "public.articles.list"
  );
  return [
    results
      ? {
          ...results,
          edges: results.edges.filter(({ node }) => node.id !== article.id), // On enlève l'article actuel (car Twittolib)
        }
      : null,
    ...rest,
  ];
}

export function useArticleAddComment(article) {
  const [mutation, result] = useMutation(gql`
    mutation AddComment($article: ID!, $text: String!) {
      private {
        articles {
          success: addComment(article: $article, text: $text)
        }
      }
    }
  `);

  return [
    (text) => mutation({ variables: { article, text } }),
    ...toStandardResult(result, "private.articles.success"),
  ];
}

export function useArticleDeleteComment(article, comment) {
  const [mutation, result] = useMutation(gql`
    mutation AddComment($article: ID!, $comment: ID!) {
      private {
        articles {
          success: removeComment(article: $article, comment: $comment)
        }
      }
    }
  `);

  return [
    () => mutation({ variables: { article, comment } }),
    ...toStandardResult(result, "private.articles.success"),
  ];
}

export function useArticleUpdateComment(article, comment) {
  const [mutation, result] = useMutation(gql`
    mutation AddComment($article: ID!, $comment: ID!, $text: String!) {
      private {
        articles {
          success: updateComment(
            article: $article
            comment: $comment
            text: $text
          )
        }
      }
    }
  `);

  return [
    (text) => mutation({ variables: { article, comment, text } }),
    ...toStandardResult(result, "private.articles.success"),
  ];
}

export function useSendAlertComment(article) {
  const [mutation, result] = useMutation(gql`
    mutation AlertComment(
      $article: ID!
      $comment: ID!
      $email: String!
      $message: String
    ) {
      public {
        articles {
          success: alertComment(
            article: $article
            comment: $comment
            email: $email
            message: $message
          )
        }
      }
    }
  `);

  return [
    (comment, email, message) =>
      mutation({
        variables: { article: article.id, comment: comment.id, email, message },
      }),
    ...toStandardResult(result, "public.articles.success"),
  ];
}

const fetchFavoritesQuery = gql`
  query FavoriteArticles {
    private {
      articles {
        list: fetchFavoriteArticles {
          article {
            ${articleSchema}
          }
        }
      }
    }
  }
`;

export function useArticleAddToFavorite(articleId) {
  const [mutation, result] = useMutation(
    gql`
      mutation AddToFavorite($article: ID!) {
        private {
          articles {
            success: addArticleToFavorite(article: $article)
          }
        }
      }
    `
  );

  return [
    () => mutation({ variables: { article: articleId } }),
    ...toStandardResult(result, "private.articles.success"),
  ];
}

export function useArticleRemoveFromFavorite(articleId) {
  const [mutation, result] = useMutation(
    gql`
      mutation RemoveFromFavorite($article: ID!) {
        private {
          articles {
            success: removeArticleFromFavorite(article: $article)
          }
        }
      }
    `
  );

  return [
    () => mutation({ variables: { article: articleId } }),
    ...toStandardResult(result, "private.articles.success"),
  ];
}

export function useFavoriteArticles(pagination = {}) {
  const result = useQuery(fetchFavoritesQuery, {
    fetchPolicy: "cache-and-network",
  });

  return toStandardResult(result, "private.articles.list", {
    transformData: (data) =>
      data && {
        totalCount: data.length,
        pageInfo: {
          endCursor: pagination.after || 0,
          hasPreviousPage: (pagination.after || 0) > 0,
          hasNextPage:
            (pagination.after || 0) + (pagination.limit || 999) < data.length,
        },
        edges: data
          .slice(
            pagination.after || 0,
            (pagination.after || 0) + (pagination.limit || 999)
          )
          .map(({ article }) => ({ node: article })),
      },
  });
}

export function useCheckFavorite(articleId) {
  const result = useQuery(fetchFavoritesQuery, { fetchPolicy: "cache-first" });

  return toStandardResult(result, "private.articles.list", {
    transformData: (data) =>
      data &&
      (data || []).some(({ article }) => articleId && article.id === articleId),
  });
}
