import { format, parseISO } from "date-fns";
import frLocale from "date-fns/locale/fr";

import typographyStyle from "../../../../../app/templates/common/TypographyStyle.jsx";
import {
  color,
  context,
  font,
  is,
  styled,
} from "../../../../../app/util/style.js";
import { Image } from "../image/ReactImageTile.jsx";

const Container = styled.div`
  display: flex;
  & > .column:first-child {
    flex: 2;
    flex-basis: 66%;
  }
  & > .column:last-child {
    flex: 1;
    flex-basis: 33%;
  }

  margin: 0 -10px;
  & > .column {
    margin: 0 10px;
  }

  ${context("for-phone")`
    flex-direction: column-reverse;

    margin: -10px 0;
    & > .column {
      margin: 10px 0;
    }
  `}
`;

const TitleContainer = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.h2`
  font-size: 16px !important;
  line-height: 24px !important;
  margin-bottom: 0 !important;
`;

const Text = styled.div`
  ${typographyStyle}
  color: ${color("greyTxt")} !important;

  &,
  & > * {
    font-size: 14px !important;
    line-height: 22px !important;
  }
`;

const Date = styled.div`
  color: ${color("greyTag")};
  font-family: ${font("secondary")};
`;

const Link = styled.a`
  display: inline-block;
  margin-top: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

export default styled(function ReactShortArticleTile({
  tile,
  options,
  className,
}) {
  return (
    <article className={`${className || ""} tile-container tile-short-article`}>
      <Container>
        <div className="column">
          <TitleContainer>
            {!!tile.title && <Title>{tile.title}</Title>}
            {!!tile.date && (
              <Date>
                {format(parseISO(tile.date), "'Le' P", { locale: frLocale })}
              </Date>
            )}
          </TitleContainer>
          <Text dangerouslySetInnerHTML={{ __html: tile.text }} />
          {!!tile.withLink && (
            <Link
              href={tile.link}
              target={tile.linkTarget}
              title={tile.linkTitle}
            >
              En savoir plus
            </Link>
          )}
        </div>
        {!!tile.withImage && (
          <div className="column">
            <Image image={tile.image} caption={tile.caption} />
          </div>
        )}
      </Container>
    </article>
  );
})`
  background-color: ${color("greyBg2")};
  border-left: 5px solid ${color("border")};
  padding: 24px;

  ${context("for-desktop")`
    ${Container} {
      flex-direction: column-reverse;  
      margin: -10px 0;
      & > .column {
        margin: 10px 0;
      }
    }
  `}
  ${context("for-tablet-landscape")`
    ${Container} {
      flex-direction: row-reverse;  
      margin: 0 -10px;
      & > .column {
        margin: 0 10px;
      }
    }
  `}
  ${context("for-phone")`
    ${Container} {
      flex-direction: column-reverse;  
      margin: -10px 0;
      & > .column {
        margin: 10px 0;
      }
    }
  `}

  ${is("options.aside")`
    ${Container} {
      flex-direction: column-reverse;  
      margin: -10px 0;
      & > .column {
        margin: 10px 0;
      }
    }
  `}
`;
