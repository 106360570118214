import _mapValues from "lodash/mapValues";
import _pick from "lodash/pick";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Controller,
  Yup,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import ConfirmationPopup from "../components/ConfirmationPopup.jsx";
import {
  Form,
  FormButton,
  FormField,
  FormInput,
  FormRadio,
  FormSection,
  FormSelect,
  FormSeparator,
  useFormAction,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import NewsletterPreferencesForm from "../components/NewsletterPreferencesForm.jsx";
import Tiles from "../components/Tiles.jsx";
import * as validator from "../components/validators.js";
import {
  useUserDelete,
  useUserSelf,
  useUserSession,
  useUserSignOut,
  useUserUpdateEmail,
  useUserUpdatePassword,
  useUserUpdateProfile,
} from "../services/auth.js";
import { useNavigationPage } from "../services/navigation.js";
import {
  modelToType as newsletterModelToType,
  typeToModel as newsletterTypeToModel,
} from "../services/newsletter.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const PageForm = styled.div`
  max-width: 555px;
  margin-bottom: 40px;
`;

function useAccountForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        phone: validator.phone.nullable(),
      })
    ),
  });
}

function useUpdateEmailForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required("Veuillez entrer une adresse email"),
        password: Yup.string()
          .nullable()
          .required(
            "Veuillez entrer votre mot de passe actuel pour modifier vos informations"
          ),
      })
    ),
  });
}

function useUpdatePasswordForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        newPassword: Yup.string().required(
          "Veuillez entrer un nouveau mot de passe"
        ),
        confirmation: Yup.string().oneOf(
          [Yup.ref("newPassword"), null],
          "Les mots de passe doivent correspondre"
        ),
        password: Yup.string()
          .nullable()
          .required(
            "Veuillez entrer votre mot de passe actuel pour modifier vos informations"
          ),
      })
    ),
  });
}
export default function Account() {
  const page = useNavigationPage();
  const [success, setSuccess] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [session] = useUserSession();
  const [signOut] = useUserSignOut();

  const [self, { loading: loadingData }] = useUserSelf();

  const getDefaultValues = useCallback(() => {
    return {
      ...self,
      address: _pick(self?.address || {}, ["street", "postalCode", "city"]),
      ...newsletterModelToType(self),
    };
  }, [self]);

  const form = useAccountForm(getDefaultValues());
  const {
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = form;

  const actionUpdateProfile = useUserUpdateProfile();
  const [onUpdateProfile, { loading: loadingUpdateProfile }] = useFormAction(
    form,
    actionUpdateProfile,
    {
      transformData: (data) => ({
        ..._pick(data, ["phone", "profileKind", "civility", "address"]),
        ...newsletterTypeToModel(data),
      }),
      onError: (err, setError) => {
        setError(err?.path || "_global", {
          message: err || {
            message:
              "Votre profil n'a pas pu être mis à jour. Veuillez réessayer plus tard.",
          },
        });
      },
      onSuccess: () => {
        // en cas de succès, affiche une popup
        setSuccess(
          "Votre profil et vos préférences de newsletter ont été mis à jour."
        );
      },
    }
  );

  const formEmail = useUpdateEmailForm({ email: self?.email });
  const {
    register: registerEmail,
    reset: resetEmail,
    setValue: setValueEmail,
    watch: watchEmail,
    formState: { errors: errorsEmail },
  } = formEmail;

  const updatingEmail = watchEmail("updatingEmail");

  const actionUpdateEmail = useUserUpdateEmail();
  const [onUpdateEmail, { loading: loadingUpdateEmail }] = useFormAction(
    formEmail,
    actionUpdateEmail,
    {
      transformData: (data) =>
        console.log({ data }) || {
          ..._pick(data, ["password", "email"]),
        },
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre email n'a pas pu être mis à jour. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        // en cas de succès, affiche une popup
        setSuccess(`
          Vous allez prochainement recevoir un email contenant un lien pour
          valider votre changement d'email. Si vous ne le recevez pas, vérifiez vos
          courriers indésirables ou que l'adresse que vous avez saisie est
          correcte.
        `);
      },
    }
  );

  const formPassword = useUpdatePasswordForm({ password: null });
  const {
    register: registerPassword,
    setValue: setValuePassword,
    watch: watchPassword,
    formState: { errors: errorsPassword },
  } = formPassword;

  const updatingPassword = watchPassword("updatingPassword");

  const actionUpdatePassword = useUserUpdatePassword();
  const [onUpdatePassword, { loading: loadingUpdatePassword }] = useFormAction(
    formPassword,
    actionUpdatePassword,
    {
      transformData: (data) => ({
        ..._pick(data, ["password", "newPassword"]),
      }),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre mot de passe n'a pas pu être mis à jour. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        // en cas de succès, affiche une popup
        setSuccess("Votre mot de passe a été mis à jour.");
      },
    }
  );

  const [doDeleteUser] = useUserDelete();

  const onDeleteUser = async function() {
    try {
      await doDeleteUser();
      setDeleting(false);
      signOut();
    } catch (e) {}
  };

  useEffect(() => {
    reset(getDefaultValues());
    resetEmail({ email: self?.email });
  }, [reset, getDefaultValues]);

  return (
    <TwoColumnsPageTemplate>
      <ConfirmationPopup
        title="Confirmation de suppression"
        open={Boolean(deleting)}
        onClose={() => setDeleting(false)}
        actions={
          <>
            <FormButton
              mini
              text
              key="confirm"
              onClick={() => {
                onDeleteUser();
                setDeleting(true);
              }}
            >
              Je confirme la suppression
            </FormButton>
            <FormButton mini key="cancel" onClick={() => setDeleting(false)}>
              Annuler
            </FormButton>
          </>
        }
      >
        Voulez-vous réellement supprimer votre compte ? Vous ne pourrez plus
        vous connecter au site avec ce compte. Cette action est irréversible.
      </ConfirmationPopup>
      <ConfirmationPopup
        title="Mise à jour effectuée"
        open={Boolean(success)}
        onClose={() => setSuccess(false)}
      >
        {success}
      </ConfirmationPopup>
      <PageForm>
        <FormSection title="Identifiant de votre compte">
          <Form
            onSubmit={onUpdateEmail}
            loading={loadingUpdateEmail || loadingData}
            errors={errorsEmail}
          >
            <Row>
              <Col span={8} sm={12} fixed>
                <FormField
                  label={
                    <>
                      Adresse email (
                      <FormButton
                        type="button"
                        text
                        onClick={() =>
                          setValueEmail("updatingEmail", !updatingEmail)
                        }
                      >
                        {updatingEmail ? "annuler" : "modifier"}
                      </FormButton>
                      )
                    </>
                  }
                  errors={errorsEmail}
                  name="email"
                >
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="Ex : c.vaultier@email.com"
                      disabled={!updatingEmail}
                      {...registerEmail(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
            </Row>
            {Boolean(updatingEmail) && (
              <FormField
                label="Mot de passe actuel"
                required
                errors={errorsEmail}
                type="password"
                name="password"
              >
                {({ name, ...childProps }) => (
                  <Row>
                    <Col span={6} sm={12} fixed>
                      <FormInput
                        placeholder="*******"
                        {...registerEmail(name)}
                        {...childProps}
                      />
                    </Col>
                    <Col span={6} sm={12}>
                      <FormButton type="submit">Enregistrer</FormButton>
                    </Col>
                  </Row>
                )}
              </FormField>
            )}
          </Form>
          {Boolean(updatingEmail) && <FormSeparator small />}
          <Form
            onSubmit={onUpdatePassword}
            loading={loadingUpdatePassword || loadingData}
            errors={errorsPassword}
          >
            <Row>
              <Col span={6} sm={12} fixed>
                <FormField
                  label={
                    <>
                      Mot de passe (
                      <FormButton
                        type="button"
                        text
                        onClick={() =>
                          setValuePassword(
                            "updatingPassword",
                            !updatingPassword
                          )
                        }
                      >
                        {updatingPassword ? "annuler" : "modifier"}
                      </FormButton>
                      )
                    </>
                  }
                  errors={errorsPassword}
                  type="password"
                  name="newPassword"
                >
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="*******"
                      disabled={!updatingPassword}
                      {...registerPassword(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
              {Boolean(updatingPassword) && (
                <Col span={6} sm={12}>
                  <FormField
                    label="Confirmer le mot de passe"
                    errors={errorsPassword}
                    type="password"
                    name="confirmation"
                  >
                    {({ name, ...childProps }) => (
                      <FormInput
                        placeholder="*******"
                        disabled={!updatingPassword}
                        {...registerPassword(name)}
                        {...childProps}
                      />
                    )}
                  </FormField>
                </Col>
              )}
            </Row>
            {Boolean(updatingPassword) && (
              <FormField
                label="Mot de passe actuel"
                required
                errors={errorsPassword}
                type="password"
                name="password"
              >
                {({ name, ...childProps }) => (
                  <Row>
                    <Col span={6} sm={12} fixed>
                      <FormInput
                        placeholder="*******"
                        {...registerPassword(name)}
                        {...childProps}
                      />
                    </Col>
                    <Col span={6} sm={12}>
                      <FormButton type="submit">Enregistrer</FormButton>
                    </Col>
                  </Row>
                )}
              </FormField>
            )}
          </Form>
          {Boolean(updatingPassword) && <FormSeparator small />}
          <Form>
            <FormSection>
              <FormButton
                text
                type="button"
                onClick={() => {
                  setDeleting(true);
                }}
              >
                Je veux supprimer mon compte
              </FormButton>
            </FormSection>
          </Form>
        </FormSection>
        <FormSeparator />
        <Form
          onSubmit={onUpdateProfile}
          loading={loadingUpdateProfile || loadingData}
          errors={errors}
        >
          <FormSection title="Informations de profil" optional>
            <Row>
              <Col span={8} sm={12} fixed>
                <FormField label="Civilité" errors={errors} name="civility">
                  {({ name, ...childProps }) => (
                    <Row>
                      <Col span={6} sm={6} xs={12} fixed>
                        <FormRadio
                          {...register(name)}
                          value="mr"
                          {...childProps}
                        >
                          Monsieur
                        </FormRadio>
                      </Col>
                      <Col span={6} sm={6} xs={12} fixed>
                        <FormRadio
                          {...register(name)}
                          value="mrs"
                          {...childProps}
                        >
                          Madame
                        </FormRadio>
                      </Col>
                    </Row>
                  )}
                </FormField>
              </Col>
            </Row>
            <Row>
              <Col span={6} sm={12}>
                <FormField label="Prénom" errors={errors} name="firstname">
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="Ex : Camille"
                      {...register(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
              <Col span={6} sm={12}>
                <FormField label="Nom" errors={errors} name="lastname">
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="Ex : Vaultier"
                      {...register(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
            </Row>
            <Row>
              <Col span={8} sm={12} fixed>
                <FormField
                  label="C'est un mail"
                  errors={errors}
                  name="profileKind"
                >
                  {({ name, ...childProps }) => (
                    <Controller
                      name={name}
                      control={control}
                      render={({ field }) => (
                        <FormSelect
                          placeholder="Choisir..."
                          {...field}
                          {...childProps}
                          options={[
                            {
                              value: "personal",
                              label: "personnel",
                            },
                            {
                              value: "professional",
                              label: "professionnel",
                            },
                          ]}
                        />
                      )}
                    />
                  )}
                </FormField>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <FormField
                  label="Adresse"
                  errors={errors}
                  name="address.street"
                >
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="Ex : 2, rue de La Boétie"
                      {...register(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
            </Row>

            <Row>
              <Col span={4} sm={12}>
                <FormField
                  label="Code postal"
                  errors={errors}
                  name="address.postalCode"
                >
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="Ex : 64000"
                      {...register(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
              <Col span={8} sm={12}>
                <FormField label="Ville" errors={errors} name="address.city">
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="Ex : Pau"
                      {...register(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
            </Row>

            <Row>
              <Col span={8} fixed>
                <FormField label="Téléphone" errors={errors} name="phone">
                  {({ name, ...childProps }) => (
                    <FormInput
                      placeholder="Ex : 01 02 03 04 05"
                      {...register(name)}
                      {...childProps}
                    />
                  )}
                </FormField>
              </Col>
            </Row>
          </FormSection>

          <NewsletterPreferencesForm
            {...{ control, register, watch, errors }}
          />

          <FormSection>
            <FormButton type="submit">Enregistrer</FormButton>
          </FormSection>
        </Form>
      </PageForm>
      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
