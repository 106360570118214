import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";

const searchQuery = gql`
  query AdsByTags($tags: [String]) {
    public {
      advertisements {
        list: fetchAds(tags: $tags) {
          id
          tags
          contexts
          htmlCode
        }
      }
    }
  }
`;

export function useAds(tags) {
  const result = useQuery(searchQuery, {
    variables: { tags },
  });

  return toStandardResult(result, "public.advertisements.list");
}
