import Ads from "../components/Ads.jsx";
import ArticlesList from "../components/ArticlesList.jsx";
import ArticlesListSpecial from "../components/ArticlesListSpecial.jsx";
import FeaturedPages from "../components/FeaturedPages.jsx";
import { Col, Container, Row } from "../components/layout.jsx";
import Pagination, { usePagination } from "../components/Pagination.jsx";
import Tiles from "../components/Tiles.jsx";
import aboutImage from "../img/about-Presselib.png";
import aboutImage2x from "../img/about-Presselib@2x.png";
import aboutBg from "../img/about-PresselibBg.jpg";
import aboutBg2x from "../img/about-PresselibBg@2x.jpg";
import { useAds } from "../services/ads.js";
import { useArticlesList, useHomeArticlesList } from "../services/articles.js";
import {
  useNavigationPage,
  useNavigationPageParameters,
  usePagesByTag,
} from "../services/navigation.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import { color, context, is, styled } from "../util/style.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const DEFAULT_LIMIT_HOME = 12;
const DEFAULT_LIMIT_SPECIAL = 8;

const FeaturedPagesContainer = styled.div`
  margin-top: -56px;
  margin-bottom: 24px;
  text-align: right;
  ${context("for-tablet-portrait")`
    display: none;
  `}
  ${is("withImage")`
    margin-top: -86px;
  `}
`;

const About = styled.div`
  background-color: #ebebeb;
  background-image: url(${aboutBg});
  ${context("retina")`
    background-image: url(${aboutBg2x});
  `}
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const AboutContainer = styled.div`
  padding: 56px 0 40px;
  position: relative;
  &::before {
    background-color: ${color("orange")};
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const AdsContainer = styled.div``;

const Typography = styled.div`
  ${TypographyStyle}
  p {
    margin-bottom: 1em;
  }
  a {
    color: ${color("orange")};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  max-width: 560px;
`;

const AboutImageContainer = styled.div`
  margin: auto;
  max-width: 322px;
`;

const AboutImage = styled.img`
  height: auto;
  width: 100%;
`;

export default function Home() {
  const page = useNavigationPage();
  const pageParameters = useNavigationPageParameters();
  const [featured] = usePagesByTag("nav:featured");
  const specialPage = pageParameters.specialPage;
  const [ads] = useAds([
    "index:middle",
    "index:articles-start",
    "index:articles-end",
  ]);

  const paginationHomeArticles = usePagination(DEFAULT_LIMIT_HOME);
  const [
    paginatedHomeArticles,
    { ready: homeArticlesReady },
  ] = useHomeArticlesList(paginationHomeArticles);

  const paginationSpecialArticles = usePagination(DEFAULT_LIMIT_SPECIAL);
  const [
    paginatedSpecialArticles,
    { ready: specialArticlesReady },
  ] = useArticlesList(
    specialPage && specialPage.parameters,
    paginationSpecialArticles,
    !specialPage
  );

  const homeArticles =
    paginatedHomeArticles?.edges?.map(({ node }) => node) || [];
  const specialArticles =
    paginatedSpecialArticles?.edges
      ?.map(({ node }) => node)
      // Pas de résumé affiché sur les articles spéciaux de la page d'accueil
      .map((article) => ({ ...article, summary: "" })) || [];

  return (
    <TwoColumnsPageTemplate
      Header={({ withImage }) => (
        <FeaturedPagesContainer withImage={withImage}>
          <FeaturedPages title="En ce moment :" pages={featured} />
        </FeaturedPagesContainer>
      )}
      Footer={
        <About>
          <Container>
            <AboutContainer>
              <Row>
                <Col span={7} sm={12}>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: pageParameters.aboutHomeText || "",
                    }}
                  />
                </Col>
                <Col span={5} sm="hidden">
                  <AboutImageContainer>
                    <AboutImage
                      src={aboutImage}
                      srcset={`${aboutImage2x} 2x`}
                      alt="PresseLib, unique, positif, innovant"
                    />
                  </AboutImageContainer>
                </Col>
              </Row>
            </AboutContainer>
          </Container>
        </About>
      }
    >
      {homeArticlesReady && (
        <>
          {ads?.length > 0 && (
            <AdsContainer>
              <Ads list={ads} tag={"index:articles-start"} />
            </AdsContainer>
          )}

          <ArticlesList
            mainTitle="À la Une"
            articles={homeArticles.length ? homeArticles : []}
            forceFirstFeatured={paginationHomeArticles.after === 0}
          >
            {ads?.length > 0 && (
              <AdsContainer>
                <Ads list={ads} tag={"index:middle"} />
              </AdsContainer>
            )}
            {paginationHomeArticles.after === 0 &&
              !!specialPage &&
              Boolean(specialArticlesReady) &&
              Boolean(specialArticles?.length) && (
                <ArticlesListSpecial
                  title={specialPage && (specialPage.title || specialPage.name)}
                  titleIcon="presselib"
                  articles={specialArticles}
                  link={specialPage}
                  linkTitle="Voir toutes les brèves"
                />
              )}
          </ArticlesList>

          {ads?.length > 0 && (
            <AdsContainer>
              <Ads list={ads} tag={"index:articles-end"} />
            </AdsContainer>
          )}

          {Boolean(homeArticles.length) ? (
            <Pagination
              result={paginatedHomeArticles}
              pagination={paginationHomeArticles}
            />
          ) : (
            !!specialPage &&
            Boolean(specialArticlesReady) &&
            specialArticles?.length && (
              <ArticlesListSpecial
                title={specialPage && (specialPage.title || specialPage.name)}
                titleIcon="presselib"
                articles={specialArticles}
                link={specialPage}
                linkTitle="Voir toutes les brèves"
              />
            )
          )}
        </>
      )}

      {Boolean(paginationHomeArticles.after === 0 && page.contents) && (
        <Tiles tiles={page.contents} />
      )}
    </TwoColumnsPageTemplate>
  );
}
