import Popup from "reactjs-popup";
import { styled, color } from "../util/style.js";
import Icon from "./Icon.jsx";

export default styled(function({ title, actions, children, ...props }) {
  return (
    <Popup modal closeOnDocumentClick lockScroll {...props}>
      {(close) => (
        <>
          <header>
            {title || ""}
            <button onClick={close}>
              <Icon name="close" variant="black" hoverVariant="red" />
            </button>
          </header>
          <main>{children}</main>
          {!!actions && <footer>{actions}</footer>}
        </>
      )}
    </Popup>
  );
})`
  &-overlay {
    background-color: ${color("overlayBackground")};
  }

  &-content {
    position: relative;
    background: white;
    max-width: 400px;

    header,
    main,
    footer {
      padding: 20px;
    }

    header {
      background: ${color("greyBg")};
      padding-right: 56px;

      button {
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }
    footer {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
