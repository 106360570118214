import { useMemo } from "react";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/Breadcrumb.jsx";
import { Container } from "../../components/layout.jsx";
import dashedBackground from "../../img/motif-lines.svg";
import { useNavigationPage } from "../../services/navigation.js";
import { getImageProps, getMediaUrl } from "../../util/medias.js";
import { color, context, font, is, styled } from "../../util/style.js";

const Background = styled.div`
  position: relative;
  ${is(({ page }) => page.image && page.imageAsBackground)`
    background-image: ${({ page }) =>
      `url('${getMediaUrl(page.image, "headerAsBackground")}')`};
    background-size: 2400px 730px;
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 250px;
    ${context("retina")`
      background-image: ${({ page }) =>
        `url('${getMediaUrl(page.image, "headerAsBackground@2x")}')`};
    `}
    ${context("for-desktop")`
      padding-top: calc(1050px * 250 / 1260);
      background-size: calc(1050px * 2400 / 1260) calc(1050px * 730 / 1260);
    `}
    ${context("for-tablet-landscape")`
      padding-top: calc(810px * 250 / 1260);
      background-size: calc(810px * 2400 / 1260) calc(810px * 730 / 1260);
    `}
    ${context("for-tablet-portrait")`
      padding-top: max(calc(100vw * 250 / 1260), 100px);
      background-size: calc(100vw * 2400 / 1260) calc(100vw * 730 / 1260);
    `}
  `}
`;

const PageBackgroundLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 730px;
  ${context("for-desktop")`
    height: calc(1050px * 730 / 1260);
  `}
  ${context("for-tablet-landscape")`
    height: calc(810px * 730 / 1260);
  `}
  ${context("for-tablet-portrait")`
    height: calc(100vw * 730 / 1260);
  `}
`;

const PageContainer = styled.div`
  background-color: white;
  margin: 0 auto;
  max-width: 1260px;
  position: relative;
  ${context("for-desktop")`
    max-width: 1050px;
  `}
  ${context("for-tablet-landscape")`
    max-width: 810px;
  `}
`;

const PageCover = styled.div``;

const Cover = styled.img`
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
`;

const PageContent = styled.div`
  padding: 56px 0;
  ${context("for-phone")`
    padding: 24px 0;
  `}
`;

const TitleContainer = styled(Container)`
  margin-bottom: 24px;
  position: relative;
  ${is("withImage")`
    margin-bottom: 0;
    ${context("for-tablet-portrait")`
      margin-bottom: 30px;
    `}
    ${context("for-phone")`
      margin-bottom: 20px;
    `}
  `}
`;

const DefaultTitleContainer = styled.div`
  ${is("withImage")`
    margin-top: -56px;
    transform: translateY(-50%);
    ${context("for-tablet-portrait")`
      transform: none;
    `}
    ${context("for-phone")`
      margin-top: -40px;
    `}
  `}
`;

const PageTitle = styled.h1`
  background-color: ${color("black")};
  color: ${color("white")};
  display: inline-block;
  font-family: ${font("secondary")};
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2em;
  margin: 0;
  padding: 0.35em 0.7em;

  ${context("for-tablet-landscape")`
    font-size: 24px;
  `}

  ${context("for-phone")`
    font-size: 20px;
  `}

  &:after {
    background-color: white;
    background-image: url(${dashedBackground});
    background-repeat: repeat;
    background-size: 4px 4px;
    content: "";
    height: 10px;
    display: block;
    margin: 0.35em -0.7em -0.35em -0.7em;
  }
`;

const BreadcrumbContainer = styled.div`
  float: right;
  position: relative;
  top: -0.35em;

  ${context("for-tablet-portrait")`
    top: 0;
    text-align: right;
  `}
`;

const TopContainer = styled.div`
  ins:not(:empty) {
    display: block;
    padding: 0 56px;
  }

  ${context("for-tablet-landscape")`
    ins:not(:empty) {
      padding: 0 45px;
    }
  `}
  ${context("for-tablet-portrait")`
    ins:not(:empty) {
      padding: 0 30px;
    }
  `}
`;
const BottomContainer = TopContainer;

const ContentContainer = styled(Container)`
  clear: both;
`;

export default function SimplePageTemplate({
  Header,
  Footer,
  Top,
  Bottom,
  title,
  children,
}) {
  const page = useNavigationPage();

  const DefaultHeader = useMemo(() => {
    const breadcrumbPages = [...(page.ancestors || [])];
    return (
      <DefaultTitleContainer withImage={!!page.image}>
        <PageTitle>{title || page.title}</PageTitle>
        <BreadcrumbContainer>
          <Breadcrumb pages={breadcrumbPages} />
        </BreadcrumbContainer>
      </DefaultTitleContainer>
    );
  }, [page, title]);

  return (
    <Background page={page}>
      {!!page.image && page.imageAsBackground && (
        <PageBackgroundLink
          {...(page.imageLinkParameters?.link !== null
            ? {
                as: "a",
                href: page.imageLinkParameters.link,
                target: page.imageLinkParameters.target,
                rel:
                  page.imageLinkParameters.target === "_blank"
                    ? "nofollow noreferrer"
                    : "",
                alt: page.imageLinkParameters.title,
                title: page.imageLinkParameters.title,
              }
            : {})}
        />
      )}
      <PageContainer>
        {!!page.image && !page.imageAsBackground && (
          <PageCover
            {...(page.imageLinkParameters?.link !== null
              ? {
                  as: "a",
                  href: page.imageLinkParameters.link,
                  target: page.imageLinkParameters.target,
                  rel:
                    page.imageLinkParameters.target === "_blank"
                      ? "nofollow noreferrer"
                      : "",
                  alt: page.imageLinkParameters.title,
                  title: page.imageLinkParameters.title,
                }
              : {})}
          >
            <Cover
              width={1260}
              height={250}
              {...getImageProps(page.image, "header")}
            />
          </PageCover>
        )}
        <PageContent>
          {(!!Header || Header === undefined) && (
            <TitleContainer withImage={!!page.image}>
              {Header !== undefined
                ? typeof Header === "function"
                  ? Header({ withImage: !!page.image, children: DefaultHeader })
                  : Header
                : DefaultHeader}
            </TitleContainer>
          )}
          {Boolean(Top) && <TopContainer>{Top}</TopContainer>}
          <ContentContainer>{children}</ContentContainer>
          {Boolean(Bottom) && <BottomContainer>{Bottom}</BottomContainer>}
        </PageContent>
      </PageContainer>
      {!!Footer && Footer}
    </Background>
  );
}
