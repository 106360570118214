import { useEffect, useState } from "react";
import styled from "styled-components";
import _pick from "lodash/pick";
import _mapValues from "lodash/mapValues";

import {
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
} from "../components/base-forms/forms.js";
import Tiles from "../components/Tiles.jsx";
import { useNavigationPage } from "../services/navigation.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";
import { useUserValidateEmail } from "../services/auth.js";
import { useLocationQuery } from "../util/router.js";
import { useLogin } from "../components/auth/Login.jsx";
import ContentLoading from "../components/ContentLoading.jsx";

import ok from "../img/ok@2x.png";
import ko from "../img/ko@2x.png";

const Typography = styled.div`
  margin-top: -4px;
  ${TypographyStyle}
`;

export default function ValidateEmail() {
  const page = useNavigationPage();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const query = useLocationQuery();
  const token = query.get("token");

  const { openPopin } = useLogin();

  const [
    validateEmail,
    validateEmailResult,
    { loading, called, graphQLErrors: validateEmailErrors },
  ] = useUserValidateEmail(token);

  useEffect(
    function() {
      validateEmail();
    },
    [token]
  );

  useEffect(
    function() {
      if (loading) return setError();
      if (!called) return;
      filterErrorMessages(validateEmailErrors).forEach((err) => {
        switch (err.code) {
          case "token-no-match":
          case "invalid-token":
            setError("Le lien de validation a déjà été utilisé ou a expiré.");
            return;
          default:
            setError(err.message);
            return;
        }
      });
      filterBadUserInputErrors(validateEmailErrors).forEach((err) =>
        setError(err.message)
      );
      if (validateEmailResult === false) {
        setError("Impossible de valider l'email. Veuillez réessayer plus tard");
      }
      if (hasErrors(validateEmailErrors) || !validateEmailResult) return;

      // en cas de succès, affiche une popup
      setSuccess(true);
    },
    [loading, validateEmailResult, validateEmailErrors]
  );

  return (
    <TwoColumnsPageTemplate>
      {success ? (
        <Typography>
          <p>
            <img src={ok} style={{ width: 100 }} />
          </p>
          <h2>Votre email a été validé</h2>
          <p>
            Vous pouvez désormais{" "}
            <a style={{ cursor: "pointer" }} onClick={() => openPopin()}>
              vous connecter
            </a>{" "}
            en utilisant votre nouvel email comme identifiant.
          </p>
        </Typography>
      ) : error ? (
        <Typography>
          <p>
            <img src={ko} style={{ width: 100 }} />
          </p>
          <h2>Votre email n'a pas été validé</h2>
          <p>
            Nous n'avons pas pu valider votre email pour la raison suivante :{" "}
            <strong>{error}</strong>
          </p>
        </Typography>
      ) : (
        <ContentLoading />
      )}

      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
