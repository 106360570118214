import Icon from "../../components/Icon.jsx";
import { Col, Container, Row } from "../../components/layout.jsx";
import OriginalSeparator from "../../components/Separator.jsx";
import { color, context, font, styled, theme } from "../../util/style.js";
import Contacts from "./Contacts.jsx";
import { LogoClassic, LogoWithBaseLine } from "./Logos.jsx";
import MenuNav from "./MenuNav.jsx";
import MenuSecondaryNav from "./MenuSecondaryNav.jsx";
import SearchBar from "./SearchBar.jsx";
import SocialMedias from "./SocialMedias.jsx";

const CloseBar = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-bottom: 16px;
`;

const CloseButton = styled.button`
  transition: color 0.3s ease;
  font-weight: 700;
  &:hover {
    color: ${color("orange")};
    transition: color 0.3s ease;
    --icon-transition: opacity 0.3s ease;
    --icon-hover: 1;
  }
`;

const CloseIcon = styled(Icon).attrs({
  name: "close",
  variant: "black",
  hoverVariant: "red",
  size: 22,
})`
  vertical-align: middle;
`;

const LogoContainer = styled.div`
  max-width: 300px;
  ${context("for-tablet-portrait")`
    margin-bottom: 24px;
    max-width: 200px;
  `}
`;

const SearchContainer = styled.div`
  width: 40%;
  ${context("for-tablet-portrait")`
    width: 100%;
  `}
`;

const LogoBar = styled(function({ className }) {
  return (
    <div className={className}>
      <LogoContainer>
        <LogoWithBaseLine />
        <LogoClassic />
      </LogoContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
    </div>
  );
})`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  ${context("for-tablet-portrait")`
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
  `}
`;

const Separator = styled(OriginalSeparator)`
  margin: 0;
  margin-bottom: 40px;
  ${context("for-phone")`
    margin-bottom: 24px;
  `}
`;

const Footer = styled.div`
  background-color: ${color("greyBg")};
`;

const FooterContainer = styled.div`
  padding-bottom: 40px;
  ${context("for-phone")`
    padding-bottom: 24px;
  `}
`;

const ContactsContainer = styled.div`
  text-align: right;
  margin-top: 24px;
  ${context("for-tablet-portrait")`
    margin-top: 24px;
    text-align: left;
  `}
`;

const SocialBlock = styled.div`
  text-align: right;
  ${context("for-tablet-portrait")`
    text-align: left;
    margin-top: 24px;
  `}
`;

const SocialTitle = styled.div`
  font-family: ${font("secondary")};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`;

export default styled(function Menu({ className, onClose }) {
  return (
    <div className={className}>
      <Container>
        <CloseBar>
          <CloseButton onClick={onClose}>
            Fermer <CloseIcon />
          </CloseButton>
        </CloseBar>
        <LogoBar />
        <Separator />
        <MenuNav />
      </Container>
      <Footer>
        <Container>
          <Separator />
          <FooterContainer>
            <Row>
              <Col span="6" sm="12">
                <MenuSecondaryNav onOpenLogin={onClose} />
              </Col>
              <Col span="6" sm="12">
                <SocialBlock>
                  <SocialTitle>Suivez-nous sur les réseaux sociaux</SocialTitle>
                  <SocialMedias />
                </SocialBlock>
                <ContactsContainer>
                  <Contacts />
                </ContactsContainer>
              </Col>
            </Row>
          </FooterContainer>
        </Container>
      </Footer>
    </div>
  );
})`
  background-color: white;
  font-size: 16px;
  left: 0;
  line-height: 22px;
  max-height: 100%;
  overflow-y: auto;
  padding: 40px 0 0 0;
  z-index: ${theme("z.menu")};
  ${context("for-tablet-portrait")`
    padding: 24px 0 0;
  `}
`;
