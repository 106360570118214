import { Helmet } from "react-helmet";

export default function MatomoAnalytics({ domain }) {
  return (
    !!domain && (
      <Helmet>
        <script>{`
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="https://${domain}/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src='//cdn.matomo.cloud/${domain}/matomo.js'; s.parentNode.insertBefore(g,s);
          })();
        `}</script>
      </Helmet>
    )
  );
}
