import styled, { useTheme } from "styled-components";
import Loader from "react-spinners/DotLoader";

export default styled(function ContentLoading({ className }) {
  const theme = useTheme();
  return (
    <div className={className}>
      <Loader size={60} color={theme.colors.orange} css="display: block;" />
    </div>
  );
})`
  margin: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
