import _partition from "lodash/partition";
import { Fragment, useMemo } from "react";

import dashedBackground from "../img/motif-lines.svg";
import { color, context, font, styled } from "../util/style.js";
import Article from "./ArticleInList.jsx";
import Icon from "./Icon.jsx";
import { Col, Container, Row } from "./layout.jsx";
import PageLink from "./PageLink.jsx";

const ArticleContainer = styled.div``;

const Separator = styled.div`
  background-color: ${color("border")};
  border: 0;
  height: 1px;
  margin: 16px 0;
`;

const MainLink = styled(PageLink)`
  font-family: ${font("secondary")};
  color: ${color("greyTxt")};
  &:hover {
    color: ${color("orange")};
    text-decoration: underliner;
  }
`;

const TitleContainer = styled.div`
  margin: 20px 0 40px;
  text-align: center;
`;

const Title = styled(function Title({
  className,
  icon = "presselib",
  children,
}) {
  return (
    <div className={className}>
      <h3>
        <Icon name={icon} variant="red" />
        {children}
      </h3>
    </div>
  );
})`
  h3 {
    align-items: center;
    display: flex;
    font-family: ${font("secondary")};
    font-size: 24px;
    justify-content: center;
    line-height: 28px;
  }
`;

export default styled(function ArticlesListSpecial({
  className,
  title,
  titleIcon,
  articles,
  link,
  linkTitle,
}) {
  const [featuredList, nonFeaturedList] = useMemo(
    function() {
      const totalArticles = articles?.length || 0;
      if (!totalArticles) return [];
      const [featuredArticles, nonFeaturedArticles] = _partition(
        articles,
        "isCurrentlyFeatured"
      );
      // On essaie d'avoir autant d'articles à gauche (featured) qu'à droite (non featured)
      if (!featuredArticles.length) {
        // Si pas de featured, on en met un en priorité depuis le début de la liste
        featuredArticles.push(nonFeaturedArticles.shift());
      }
      if (totalArticles > 1) {
        while (
          featuredArticles.length > 1 &&
          nonFeaturedArticles.length / featuredArticles.length < 1
        ) {
          nonFeaturedArticles.push(featuredArticles.shift());
        }
        while (
          nonFeaturedArticles.length > 1 &&
          featuredArticles.length / nonFeaturedArticles.length < 1
        ) {
          featuredArticles.push(nonFeaturedArticles.shift());
        }
      }

      return [featuredArticles, nonFeaturedArticles];
    },
    [articles]
  );

  if (!featuredList && !nonFeaturedList) return null;

  return (
    <section className={`ArticlesListSpecial ${className || ""}`}>
      <div className="Container">
        {Boolean(title || link) && (
          <TitleContainer>
            {Boolean(title) && <Title icon={titleIcon}>{title}</Title>}
            {Boolean(link) && <MainLink to={link}>{linkTitle}</MainLink>}
          </TitleContainer>
        )}
        <div>
          <Container>
            <Row>
              <Col span="7" sm="12">
                {featuredList.map((article, index) => (
                  <Fragment key={article.id}>
                    {index > 0 && <Separator />}
                    <ArticleContainer>
                      <Article {...article} mini featured={false} />
                    </ArticleContainer>
                  </Fragment>
                ))}
              </Col>
              <Col span="5" sm="12" className="RightColumn">
                {nonFeaturedList.map((article, index) => (
                  <Fragment key={article.id}>
                    {index > 0 && <Separator />}
                    <ArticleContainer>
                      <Article {...article} mini featured={false} noImage />
                    </ArticleContainer>
                  </Fragment>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
})`
  background-color: ${color("greyBg2")};
  margin: 0 0 40px 0;

  .Container {
    padding: 16px 0;
  }

  &::before {
    background-image: url(${dashedBackground});
    background-repeat: repeat;
    background-size: 4px 4px;
    content: "";
    display: block;
    height: 10px;
    width: 100%;
  }

  .RightColumn {
    border-left: 1px solid ${color("border")};
    ${context("for-tablet-portrait")`
      border-left: 0;
      &::before {
        content: '';
        display: block;
        background-color: #dfdfdf;
        border: 0;
        height: 1px;
        margin: 16px 0;
      }
    `}
  }
`;
