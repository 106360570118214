import normalize from "!!raw-loader!./normalize.css"; // eslint-disable-line import/no-unresolved
import { Helmet } from "react-helmet";
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from "styled-components";

import { color, font } from "../../util/style.js";
import IconsStyle from "./IconsStyle.js";

export const colors = {
  greyBg: "#f5f4f4",
  greyBg2: "#f7f7f7",
  greyBgDarker: "#e5e4e4",
  greyTxt: "#666666",
  greyTag: "#8c8583",
  border: "#dfdfdf",
  orange: "#f64829",
  orangeDarker: "#bb2409",
  black: "#2a2a2a",
  blackLight: "#666666",
  green: "#008c59",
  blue: "#1971c3",
  yellow: "#e3c72d",
  white: "#ffffff",
  grey: "#666666",
  greyDarker: "#444444",
};

const fonts = {
  primary: "'Noto Sans', Calibri, Verdana, sans-serif",
  secondary: "'Zilla Slab', Georgia, 'Times New Roman', serif",
};

export const theme = {
  fonts: {
    ...fonts,
    dataLabel: `
      font-family: ${fonts.secondary};
      font-size: 18px;
      font-weight: bolder;
    `,
    form: {
      label: `
        font-family: ${fonts.secondary};
        font-size: 16px;
        font-weight: normal;
      `,
      input: `
        font-family: ${fonts.primary};
        font-size: 14px;
        line-height: 20px;
        font-weight: bolder;
      `,
      button: `
        font-family: ${fonts.primary};
        font-size: 14px;
        line-height: 20px;
      `,
    },
  },

  colors: {
    ...colors,

    overlayBackground: "rgba(78,78,78,0.8)",

    articles: {
      kinds: {
        entrepreneur: {
          background: "#008C59",
          border: "#006338",
          color: colors.white,
        },
        sponsored: {
          background: "#8F7C11",
          border: "#554905",
          color: colors.white,
        },
        infomercial: {
          background: "#347789",
          border: "#255F6F",
          color: colors.white,
        },
        solidarity: {
          background: "#7A4DCB",
          border: "#4F2E8B",
          color: colors.white,
        },
      },
    },

    form: {
      required: colors.orange,
      error: colors.orange,
      input: {
        background: colors.border,
        focus: {
          border: colors.greyTag,
        },
        error: {
          background: "#ffedea",
          border: colors.orange,
        },
        placeholder: colors.greyTag,
        checked: colors.orange,
      },
      button: {
        background: colors.orange,
        color: colors.white,
        hover: {
          background: colors.white,
          color: colors.orange,
          border: colors.orange,
        },

        ghost: {
          background: colors.white,
          color: colors.orange,
          border: colors.orange,
          hover: {
            background: colors.orange,
            color: colors.white,
            border: "transparent",
          },
        },
        black: {
          background: colors.black,
          color: colors.white,
          border: "transparent",
          hover: {
            background: colors.grey,
            color: colors.white,
            border: "transparent",
          },
        },
        white: {
          background: colors.white,
          color: colors.black,
          border: "transparent",
          hover: {
            background: colors.greyBg,
            color: colors.black,
            border: "transparent",
          },
        },
      },
      separator: colors.border,
    },
    image: {
      placeholder: colors.greyBg2,
    },
  },

  contexts: {
    "for-small-phone": "(max-width: 380px)",
    "for-phone": "(max-width: 576px)",
    "for-tablet-portrait": "(max-width: 768px)",
    "for-tablet-landscape": "(max-width: 992px)",
    "for-desktop": "(max-width: 1200px)",
    retina:
      "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2) only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2) only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)",
  },

  z: [
    "popin",
    "menu",
    "notifications",
    "header",
    "titleHeadline",
    "articleKind",
  ]
    .reverse()
    .reduce((z, key, index) => ({ ...z, [key]: index + 100 }), {}),
};

const GlobalStyle = createGlobalStyle`
  ${normalize}

  *, *:before, *:after {
    box-sizing: inherit;
  }

  html {
      box-sizing: border-box;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
  }

  body {
    background-color: ${color("greyBg2")};
    color: ${color("black")};
    font-family: ${font("primary")};
    font-size: 14px;
    line-height: 22px;
  }

  a {
    color: ${color("orange")};
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }

  button {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline: none;
    padding: 0; 
    & > [class^="icon-"], [class*=" icon-"] {
      cursor: pointer;
    }
  }

  button, input[type="submit"] {
    cursor: pointer;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  input {
    outline: none;
  }
`;

export const ThemeProvider = function({ children }) {
  // https://www.smashingmagazine.com/2019/06/optimizing-google-fonts-performance/
  return (
    <StyledThemeProvider theme={theme}>
      <>
        <Helmet>
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com/"
            crossOrigin
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Zilla+Slab:wght@400;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <GlobalStyle />
        <IconsStyle />
        {children}
      </>
    </StyledThemeProvider>
  );
};
