/* eslint-disable */
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { toStandardResult } from "../util/graphql.js";

const JWT_ACCESS_TOKEN_USER_COOKIE =
  process.env.JWT_ACCESS_TOKEN_USER_COOKIE || "user-session";
const JWT_REFRESH_TOKEN_USER_COOKIE =
  process.env.JWT_REFRESH_TOKEN_USER_COOKIE || "user-refresh";

const tokenGqlProperties = `accessToken expiresIn refreshToken refreshExpiresIn`;

const SessionContext = createContext();

const hasLocalStorage = typeof localStorage !== "undefined" && !!localStorage;

export function SessionProvider({ session: providedSession, ...props }) {
  const cacheSession =
    typeof window !== "undefined" ? window.__SESSION__ : null;
  const [session, setSession] = useState(providedSession || cacheSession);
  return (
    <SessionContext.Provider
      value={{ cache: Boolean(cacheSession), session, setSession }}
      {...props}
    />
  );
}

function useTokens() {
  const [cookies, setCookie, removeCookie] = useCookies([
    JWT_ACCESS_TOKEN_USER_COOKIE,
    JWT_REFRESH_TOKEN_USER_COOKIE,
  ]);

  return [
    function(tokens) {
      if (!tokens) {
        if (hasLocalStorage)
          localStorage.removeItem(JWT_ACCESS_TOKEN_USER_COOKIE);
        removeCookie(JWT_REFRESH_TOKEN_USER_COOKIE, { path: "/", maxAge: 0 });
        removeCookie(JWT_ACCESS_TOKEN_USER_COOKIE, { path: "/", maxAge: 0 });
        return;
      }
      const { accessToken, expiresIn, refreshToken, refreshExpiresIn } = tokens;
      setCookie(JWT_REFRESH_TOKEN_USER_COOKIE, refreshToken, {
        path: "/",
        maxAge: parseInt(refreshExpiresIn, 10),
      });
      setCookie(JWT_ACCESS_TOKEN_USER_COOKIE, accessToken, {
        path: "/",
        maxAge: parseInt(expiresIn, 10),
      });
      if (hasLocalStorage)
        localStorage.setItem(JWT_ACCESS_TOKEN_USER_COOKIE, accessToken);
    },
    (hasLocalStorage
      ? localStorage.getItem(JWT_ACCESS_TOKEN_USER_COOKIE)
      : null) || cookies[JWT_ACCESS_TOKEN_USER_COOKIE],
    cookies[JWT_REFRESH_TOKEN_USER_COOKIE],
  ];
}

export function useSessionData() {
  const { session } = useContext(SessionContext);
  return session?.data;
}

export function useUserSession() {
  const {
    session: currentSession,
    cache: sessionCached,
    setSession,
  } = useContext(SessionContext);
  const [setTokens, accessToken, refreshToken] = useTokens("user");

  const [
    refreshTokensBase,
    { called: refreshCalled, loading: refreshLoading, data: refreshData },
  ] = useMutation(
    gql`
    mutation($refreshToken: ID!) {
      public {
        private {
          refreshTokens(refreshToken: $refreshToken) {
            ${tokenGqlProperties}
          }
        }
      }
    }
  `
  );
  const refreshTokens = useCallback(
    function() {
      refreshTokensBase({ variables: { refreshToken } }).catch(() => {});
    },
    [refreshToken]
  );
  const refreshedTokens = refreshData?.public?.private?.refreshTokens;
  useEffect(
    function() {
      if (!refreshCalled || refreshLoading) return;
      setTokens(refreshedTokens);
    },
    [refreshCalled, refreshLoading, refreshedTokens]
  );

  // On récupère les éléments de session si aucune n'est en cache
  const {
    loading: sessionLoading,
    error: sessionError,
    data,
    refetch: refetchSession,
  } = useQuery(
    gql`
      query {
        private {
          session {
            expiresIn
            data
          }
        }
      }
    `,
    {
      // "skip" cannot be used with "refetch" so we use this hack
      fetchPolicy: sessionCached || !accessToken ? "cache-only" : "no-cache",
      errorPolicy: "all", // Masque les erreurs d'authentification console
      ssr: false, // Pas nécessaire côté serveur
    }
  );

  // Si on a des résultats de session, on les récupère et on les stocke
  const session = data?.private?.session || currentSession;
  const hasUnauthenticatedError = sessionError?.graphQLErrors?.find(
    (err) => err?.extensions?.code === "UNAUTHENTICATED"
  );

  useEffect(
    function() {
      if (sessionLoading) return;
      if (hasUnauthenticatedError) return setSession(null);
      setSession(session);
    },
    [sessionLoading, hasUnauthenticatedError, session]
  );

  // On tente de rafraîchir la session une seule fois en cas de jeton d'accès expiré
  useEffect(function() {
    if (accessToken || session || !refreshToken) return;
    refreshTokens();
  }, []);

  // Gère l'expiration de la session en provoquant un rafraîchissement
  useEffect(
    function() {
      if (!session || !refreshToken) return;
      const refreshTimeout = setTimeout(
        refreshTokens,
        ((session.expiresIn || 3600) - 5) * 1000
      );
      return () => clearTimeout(refreshTimeout);
    },
    [session, refreshToken]
  );

  // Met à jour les informations de session
  useEffect(
    function() {
      if (!accessToken) return;
      refetchSession();
    },
    [accessToken]
  );

  return [
    session?.data,
    hasUnauthenticatedError
      ? null
      : sessionError?.graphQLErrors && sessionError?.graphQLErrors.length
      ? sessionError?.graphQLErrors
      : null,
    {
      loading: !session && (sessionLoading || refreshLoading),
      sessionLoading,
      refreshLoading,
    },
  ];
}

/**
 * Méthode pour connexion
 *
 * @returns [callback, success?, errors, { ...results }]
 */
export function useUserLogin() {
  const [setTokens] = useTokens("user");

  const [mutate, result] = useMutation(
    gql`
    mutation($username: String!, $password: String!) {
      public {
        private {
          authenticateWithUsernameAndPassword(username: $username, password: $password) {
            ${tokenGqlProperties}
          }
        }
      }
    }
  `
  );
  const authenticationTokens =
    result?.data?.public?.private?.authenticateWithUsernameAndPassword;

  useEffect(
    function() {
      if (!result.called) return;
      setTokens(authenticationTokens);
    },
    [result.called, authenticationTokens]
  );

  return [
    (username, password) =>
      mutate({ variables: { username, password } }).catch(() => {}),
    ...toStandardResult(
      result,
      "public.private.authenticateWithUsernameAndPassword"
    ),
  ];
}

export function useUserSignOut() {
  const [setTokens] = useTokens("user");
  const { setSession } = useContext(SessionContext);

  const [mutate, result] = useMutation(
    gql`
      mutation {
        public {
          private {
            success: signOut
          }
        }
      }
    `
  );

  useEffect(
    function() {
      if (!result.called || result.loading) return;
      setTokens(null);
      setSession(null);
      result.client.resetStore();
    },
    [result.loading, result.called]
  );

  return [
    () => {
      mutate().catch(() => {});
    },
    ...toStandardResult(result, "public.private.success"),
  ];
}

export function useUserCompleteAccount(token) {
  const [mutate, result] = useMutation(
    gql`
      mutation($token: ID!) {
        public {
          private {
            success: completeAccount(token: $token)
          }
        }
      }
    `
  );

  return [
    () => {
      mutate({ variables: { token } }).catch(() => {});
    },
    ...toStandardResult(result, "public.private.success"),
  ];
}

export function useUserValidateEmail(token) {
  const [mutate, result] = useMutation(
    gql`
      mutation($token: ID!) {
        public {
          private {
            success: confirmEmailAddressUpdate(token: $token)
          }
        }
      }
    `
  );

  return [
    () => {
      mutate({ variables: { token } }).catch(() => {});
    },
    ...toStandardResult(result, "public.private.success"),
  ];
}

export function useUserPasswordResetRequest() {
  const [mutate, result] = useMutation(
    gql`
      mutation($username: String!) {
        public {
          private {
            success: forgotPassword(username: $username)
          }
        }
      }
    `
  );

  return [
    (username) => {
      mutate({ variables: { username } }).catch(() => {});
    },
    ...toStandardResult(result, "public.private.success"),
  ];
}

export function useUserPasswordReset(token) {
  const [mutate, result] = useMutation(
    gql`
      mutation($token: ID!, $password: String!) {
        public {
          private {
            success: changePassword(token: $token, newPassword: $password)
          }
        }
      }
    `
  );

  return [
    (password) => {
      mutate({ variables: { token, password } }).catch(() => {});
    },
    ...toStandardResult(result, "public.private.success"),
  ];
}

export function useUserSignup() {
  const [mutation, result] = useMutation(gql`
    mutation($data: RegisterPrivateInput!) {
      public {
        private {
          success: register(data: $data)
        }
      }
    }
  `);

  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "public.private.success"),
  ];
}

export function useUserUpdateProfile() {
  const [mutation, result] = useMutation(gql`
    mutation($data: UserPrivateInput!) {
      private {
        users {
          user: updateUser(data: $data) {
            id
            civility
            lastname
            firstname
            job
            phone
            email
            companyName
            profileKind
            address {
              id
              company
              recipient
              location
              street
              additional
              postalCode
              city
              cedex
              country
            }
            subscribedToNewsletter
            subscriptionKinds
            interests
          }
        }
      }
    }
  `);

  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "private.users.user"),
  ];
}

export function useUserUpdateEmail() {
  const [mutation, result] = useMutation(gql`
    mutation($password: String!, $email: String!) {
      private {
        users {
          user: updateUserEmail(password: $password, email: $email) {
            id
            email
          }
        }
      }
    }
  `);

  return [
    ({ password, email }) => mutation({ variables: { password, email } }),
    ...toStandardResult(result, "private.users.user"),
  ];
}

export function useUserUpdatePassword() {
  const [mutation, result] = useMutation(gql`
    mutation($password: String!, $newPassword: String!) {
      private {
        users {
          user: updateUserPassword(
            password: $password
            newPassword: $newPassword
          ) {
            id
          }
        }
      }
    }
  `);

  return [
    ({ password, newPassword }) =>
      mutation({ variables: { password, newPassword } }),
    ...toStandardResult(result, "private.users.user"),
  ];
}

export function useUserDelete() {
  const [mutation, result] = useMutation(gql`
    mutation {
      private {
        users {
          success: removeUser
        }
      }
    }
  `);

  return [
    () => mutation(),
    ...toStandardResult(result, "private.users.success"),
  ];
}
export function useUserSelf() {
  const result = useQuery(
    gql`
      query {
        private {
          users {
            user: self {
              id
              civility
              lastname
              firstname
              job
              phone
              email
              companyName
              profileKind
              address {
                id
                company
                recipient
                location
                street
                additional
                postalCode
                city
                cedex
                country
              }
              subscribedToNewsletter
              subscriptionKinds
              interests
            }
          }
        }
      }
    `,
    { fetchPolicy: "network-only" }
  );

  return toStandardResult(result, "private.users.user");
}
