import { Helmet } from "react-helmet";

import { typeByAlias } from "../../common/tags.js";
import ArticlesList from "../components/ArticlesList.jsx";
import Pagination, { usePagination } from "../components/Pagination.jsx";
import Tiles from "../components/Tiles.jsx";
import { useArticlesList } from "../services/articles.js";
import { useNavigation } from "../services/navigation.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const DEFAULT_LIMIT = 20;

function replaceTags(txt, { type, tag }) {
  return (txt || "")
    .replace(new RegExp("{type}", "img"), type)
    .replace(new RegExp("{tag}", "img"), tag);
}

export default function ArticlesByTag() {
  const [{ page, parameters = {} }] = useNavigation();
  const pagination = usePagination(DEFAULT_LIMIT);
  const [articles, { ready }] = useArticlesList(
    {
      tags: [
        `${typeByAlias[parameters.type] || parameters.type}:${parameters.tag}`,
      ],
    },
    pagination
  );

  return (
    <TwoColumnsPageTemplate
      summary={replaceTags(page.summary, parameters)}
      title={replaceTags(page.title, parameters)}
    >
      <Helmet>
        {/* On empêche l'indexation (mais pas le crawl) des plus anciennes pages pour éviter de gréver le budget SEO */}
        {pagination.after > 100 && (
          <meta name="robots" contents="noindex, follow" />
        )}
        <title>
          {replaceTags(page.seo?.title || page.title || page.name, parameters)}
        </title>
        <meta
          name="description"
          content={replaceTags(
            page.seo?.description || page.summary || "",
            parameters
          )}
        />
        <meta
          name="keywords"
          content={replaceTags(page.seo?.keywords || "", parameters)}
        />
        <meta
          property="og:title"
          content={replaceTags(
            page.seo?.title || page.title || page.name,
            parameters
          )}
        />
        <meta
          property="og:description"
          content={replaceTags(
            page.seo?.description || page.summary || "",
            parameters
          )}
        />
      </Helmet>
      {ready &&
        (articles?.edges?.length ? (
          <>
            <ArticlesList
              articles={(articles?.edges || []).map(({ node }) => node)}
              forceFirstFeatured={pagination.after === 0}
            >
              {pagination.after === 0 && <Tiles tiles={page.contents} />}
            </ArticlesList>
            <Pagination result={articles} pagination={pagination} />
          </>
        ) : (
          <div>Aucun article à afficher</div>
        ))}
    </TwoColumnsPageTemplate>
  );
}
