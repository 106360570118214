import { context, css, styled } from "../util/style.js";

const colsConfiguration = {
  main: {
    span: 8,
    md: 12,
  },
  aside: {
    span: 4,
    md: 12,
  },
};

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1170px;
  padding: 0 15px;
  width: 100%;
  ${context("for-desktop")`
    max-width: 970px;
  `}
  ${context("for-tablet-landscape")`
    max-width: 750px;
  `}
  ${context("for-tablet-portrait")`
    max-width: 100%;
    padding: 0 30px;
  `}
  ${context("for-phone")`
    max-width: 100%;
    padding: 0 30px;
  `}
  ${context("for-small-phone")`
    padding: 0 16px;
  `}
`;

function cssFlex(field) {
  return ({ kind, fixed, ...props }) => {
    const nFromKind = kind && colsConfiguration[kind][field];
    const nFromProps = props[field];

    const n = nFromProps || nFromKind;

    if (!n) return ``;

    const size = (n / 12) * 100;

    return n === "hidden"
      ? css`
          display: none;
        `
      : css`
          display: block;
          flex: 1 0 ${size}%;
          width: ${size}%;
          ${Boolean(fixed)
            ? css`
                max-width: ${size}%;
              `
            : ``}
        `;
  };
}

function cssOffset(field) {
  return ({ kind, fixed, ...props }) => {
    const nFromKind = kind && colsConfiguration[kind][field];
    const nFromProps = props[field];

    const n = nFromProps || nFromKind;

    if (!n) return ``;

    const size = (n / 12) * 100;

    return css`
      margin-left: ${size}%;
    `;
  };
}
export const Col = styled(function({
  kind = "div",
  span,
  offset,
  md,
  lg,
  sm,
  mdOffset,
  lgOffset,
  smOffset,
  fixed,
  ...props
}) {
  const CustomTag = `${kind || "div"}`;
  return <CustomTag {...props} />;
})`
  padding: 0 15px;
  
  ${cssFlex("span")}
  ${cssOffset("offset")}

  ${context("for-desktop")`
    ${cssFlex("lg")}
    ${cssOffset("lg-offset")}
  `}

  ${context("for-tablet-landscape")`
    ${cssFlex("md")}
    ${cssOffset("md-offset")}
  `}

  ${context("for-tablet-portrait")`
    ${cssFlex("sm")}
    ${cssOffset("sm-offset")}
  `}

  ${context("for-phone")`
    ${cssFlex("xs")}
    ${cssOffset("xs-offset")}
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;
