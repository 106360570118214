import { useMemo, useState } from "react";
import Slider from "react-slick";
import Popup from "reactjs-popup";
import styled, { css } from "styled-components";

import TypographyStyle from "../../../../../app/templates/common/TypographyStyle.jsx";
import { getImageProps } from "../../../../../app/util/medias.js";
import nextArrowIcon from "./assets/arrow-next-white.svg";
import previousArrowIcon from "./assets/arrow-prev-white.svg";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Gallery = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;

  figure {
    margin: 0 !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Slide = styled.div`
  ${TypographyStyle}
`;

const Carousel = styled(function({
  initialSlide = 0,
  imageMode = "gallery",
  onSelect,
  className,
  images,
}) {
  const ImageWrapper = useMemo(
    () =>
      onSelect
        ? ({ index, children }) => (
            <button onClick={() => onSelect(index)}>{children}</button>
          )
        : ({ children }) => <>{children}</>,
    [onSelect]
  );

  return (
    <div className={className}>
      <Slider
        {...settings}
        initialSlide={initialSlide}
        nextArrow={<SliderArrow next />}
        prevArrow={<SliderArrow previous />}
      >
        {(images || []).map((image, index) => (
          <Slide key={image.id}>
            <figure
              key={image.id}
              className={onSelect ? "clickable-figure" : ""}
            >
              <div className="ratio-169">
                <ImageWrapper index={index}>
                  <img
                    loading="lazy"
                    alt=""
                    width={750}
                    height={420}
                    {...getImageProps(image, imageMode)}
                  />
                </ImageWrapper>
              </div>
              {!!image.credits && (
                <cite className="credits">{image.credits}</cite>
              )}
            </figure>
          </Slide>
        ))}
      </Slider>
    </div>
  );
})`
  /* Slider */
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
    width: 100%;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background-color: rgba(0, 0, 0, 0.6);
  }
  &-content {
    max-width: 750px;
    width: 100%;

    figure {
      cite {
        color: white;
      }
    }
  }
`;

export default function ReactImagesTile({
  tile,
  options: { aside } = {},
  className,
}) {
  const [modalCarouselOpened, setModalCarouselOpened] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0);

  function displayModalCarousel(index) {
    setSelectedSlide(index);
    setModalCarouselOpened(true);
  }
  function closeModalCarousel() {
    setModalCarouselOpened(false);
    setSelectedSlide(0);
  }

  return (
    <div
      className={`${className || ""} tile-container tile-images ${
        aside ? "tile--aside" : ""
      }`}
    >
      {tile.layout === "gallery" && (
        <>
          <Gallery className="tile--typography tile-gallery">
            {(tile.images || []).map((image, index) => (
              <figure key={image.id}>
                <button onClick={() => displayModalCarousel(index)}>
                  <img
                    loading="lazy"
                    alt=""
                    width={750}
                    height={420}
                    {...getImageProps(image, "gallery")}
                  />
                </button>
                {/* Pas nécessaire sur la galerie car dans la popin
                 !!image.credits && (
                  <cite className="credits">{image.credits}</cite>
                )*/}
              </figure>
            ))}
          </Gallery>
        </>
      )}

      {tile.layout === "carousel" && (
        <Carousel
          images={tile.images}
          onSelect={(index) => displayModalCarousel(index)}
        />
      )}

      <StyledPopup
        modal
        lockScroll
        closeOnDocumentClick
        open={modalCarouselOpened}
        onClose={closeModalCarousel}
      >
        <Carousel images={tile.images} initialSlide={selectedSlide} />
      </StyledPopup>
    </div>
  );
}

const colors = {
  orange: "#f64829",
  black: "#2a2a2a",
};

const SliderArrow = styled.button`
  background-color: ${colors.orange};
  border: 1px solid ${colors.orange};
  height: 40px;
  transition: background-color 0.3s ease;
  width: 40px;
  &:hover {
    background-color: ${colors.black};
    transition: background-color 0.3s ease;
  }
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
      
  ${(props) =>
    !!props.previous &&
    css`
      background-image: url(${previousArrowIcon});
      left: 0;
    `}
  ${(props) =>
    !!props.next &&
    css`
      background-image: url(${nextArrowIcon});
      right: 0;
    `}
}
`;
