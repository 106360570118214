import { useLocation } from "react-router";

import { color, context, styled } from "../util/style.js";
import Icon from "./Icon.jsx";

const SocialShareItemLink = styled.a`
  align-items: center;
  background-color: ${color("orange")};
  border-radius: 50px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: 36px;
  justify-content: center;
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  width: 36px;
  &:hover {
    background-color: ${color("orangeDarker")};
    transition: all 0.3s ease;
  }
`;
const CommentLink = styled(SocialShareItemLink)`
  background-color: ${color("greyBg")};
  &:hover {
    background-color: ${color("greyBg2")};
  }
`;

const SocialShareItem = styled(function({ alt, icon, ...props }) {
  return (
    <SocialShareItemLink {...props}>
      <Icon name={icon} alt={alt} size={32} variant="white" />
    </SocialShareItemLink>
  );
})``;

const CommentsIcon = styled.span`
  border-radius: 99px;
  background-color: ${color("orange")};
  color: ${color("white")};
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 1.5em;
  line-height: 1.2em;
  padding: 0.15em;
  text-align: center;
  top: -0.25em;
  right: -0.25em;
  min-width: 1.5em;
  position: absolute;
`;
function Comments({ count = 0, ...props }) {
  return (
    <CommentLink href="#comments" {...props}>
      <Icon
        name={"comment"}
        alt={`Commentaires (${count || 0})`}
        size={32}
        variant="black"
      />
      {Boolean(count) && (
        <CommentsIcon>{count > 9999 ? "9999+" : count}</CommentsIcon>
      )}
    </CommentLink>
  );
}

const APP_BASE_URI = (process.env.WEBSITE_URI || "").replace(/\/$/, "");

export default styled(function SocialShare({
  article,
  commentsCount,
  className,
}) {
  const location = useLocation();
  const fullUrl = APP_BASE_URI + location.pathname;

  return (
    <ul className={className}>
      <li>
        <Comments count={commentsCount || 0} title="Voir les commentaires" />
      </li>
      <li>
        <SocialShareItem
          href={`https://facebook.com/sharer.php?u=${fullUrl}`}
          target="_blank"
          rel="nofollow noopener"
          icon="facebook"
          title="Partager sur Facebook"
          alt="Facebook"
        />
      </li>
      <li>
        <SocialShareItem
          href={`https://twitter.com/intent/tweet?url=${fullUrl}&hashtags=presselib&via=presselib`}
          target="_blank"
          rel="nofollow noopener"
          icon="twitter"
          title="Partager sur Twitter"
          alt="Twitter"
        />
      </li>
      <li>
        <SocialShareItem
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${fullUrl}`}
          target="_blank"
          rel="nofollow noopener"
          icon="linkedin"
          title="Partager sur LinkedIn"
          alt="LinkedIn"
        />
      </li>
      {/* <li>
      <SocialShareItem
        href={`https://www.instagram.com/explore/tags/presselib/`}
        target="_blank"
        rel="nofollow noopener"
        icon="instagram"
        alt="Partager sur Instagram"
      />
      </li> */}
      <li>
        <SocialShareItem
          href={`mailto:?subject=${article.title}&body=${fullUrl}`}
          target="_blank"
          rel="nofollow noopener"
          icon="mail"
          alt="Envoyer un mail"
          title="Mail"
        />
      </li>
    </ul>
  );
})`
  display: flex;
  flex-direction: column;
  margin: -8px;

  li {
    display: inline-block;
    padding: 8px;
  }

  ${context("for-tablet-landscape")`
    flex-direction: row;
  `}
`;
