import Tiles from "../components/Tiles.jsx";
import { useNavigationPage } from "../services/navigation.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

export default function DefaultPage() {
  const page = useNavigationPage();
  return (
    <TwoColumnsPageTemplate>
      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
