import { add, isAfter } from "date-fns";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Popup from "reactjs-popup";
import { keyframes } from "styled-components";

import { useUserLogin } from "../../services/auth.js";
import {
  useNavigationIndexParameters,
  useOneOfAKindPage,
} from "../../services/navigation.js";
import { color, font, is, styled } from "../../util/style.js";
import {
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../base-forms/forms.js";
import {
  Form,
  FormButton,
  FormField,
  FormFieldLink,
  FormInput,
} from "../forms.jsx";
import Icon from "../Icon.jsx";
import PageLink from "../PageLink.jsx";
import Separator from "../Separator.jsx";
import * as validator from "../validators.js";

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
`;

const TitleContainer = styled.div`
  position: absolute;
  right: 0;
  top: -20px;
  display: flex;
  align-items: stretch;
  z-index: 1;
`;

const Title = styled.div`
  background-color: ${color("black")};
  color: ${color("white")};
  display: inline-block;
  font-family: ${font("secondary")};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2em;
  padding: 0.35em 0.7em;
  height: 40px;
`;

const CloseButton = styled.button`
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${color("orange")};
  transition: background-color 0.3s ease;

  &:hover {
    background: ${color("orangeDarker")};
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const PopupText = styled.p`
  margin-bottom: 12px;
  text-align: center;
  font-size: 14px;
`;

const PopupTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4px;
`;

const PopupTitleIcon = styled(Icon)`
  color: ${color("orange")};
  margin-right: 4px;
`;

const PopupTitle = styled.h2`
  font-family: ${font("secondary")};
  font-size: 18px;
  line-height: 24px;
`;

function useLoginForm() {
  return useForm({
    mode: "onTouched",
    resolver: yupResolver(
      Yup.object().shape({
        email: validator.email.required("Veuillez renseigner votre email"),
        password: validator.password.required(
          "Veuillez renseigner votre mot de passe"
        ),
      })
    ),
  });
}

const LoginContext = createContext();

export function useLogin() {
  return useContext(LoginContext);
}

export const LoginProvider = styled(function({ children }) {
  const [open, setOpen] = useState(false);
  const [popinData, setPopinData] = useState({
    introduction:
      "Connectez-vous à votre espace lecteur en renseignant vos identifiants.",
  });

  const closePopin = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const openPopin = useCallback(
    (data = {}) => {
      setPopinData((popinData) => ({ ...popinData, ...data }));
      setOpen(true);
    },
    [setOpen]
  );

  return (
    <>
      <LoginPopup
        open={open}
        {...popinData}
        onClose={() => closePopin()}
        onSuccess={() => closePopin()}
      />
      <LoginContext.Provider value={{ openPopin, open, closePopin }}>
        {children}
      </LoginContext.Provider>
    </>
  );
})``;

const LoginPopup = styled(function({
  introduction,
  open,
  onClose,
  onSuccess,
  className,
}) {
  const [pageRegistration] = useOneOfAKindPage("signup");
  const [pagePasswordResetRequest] = useOneOfAKindPage("passwordResetRequest");

  const {
    register,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    handleSubmit,
  } = useLoginForm();

  const [
    login,
    loginResult,
    { loading, called, graphQLErrors: loginErrors },
  ] = useUserLogin();

  const onSubmit = useCallback(
    function(data) {
      login(data.email, data.password);
    },
    [clearErrors, login]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(loginErrors).forEach((err) => {
        switch (err.code) {
          /*
          case "user-duplicate":
            setError("_global", {
              message: "Vous êtes déjà inscrit(e) à notre newsletter",
            });
            break;
          */
          default:
            setError("_global", err);
        }
      });
      filterBadUserInputErrors(loginErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (loginResult === null) {
        setError("_global", {
          message: "Vos identifiants sont invalides.",
        });
      }
      if (hasErrors(loginErrors) || !loginResult) return;

      // en cas de succès, appeler le callback
      onSuccess();
      // clear le form
      try {
        reset();
      } catch (e) {
        console.log(e);
      }
    },
    [loading, called, loginResult, loginErrors]
  );

  useEffect(() => {
    // Fermeture de la popin, reset de champs
    if (!open) {
      clearErrors();
      reset();
    }
  }, [open]);

  return (
    <Popup
      className={className}
      onClose={onClose}
      open={open}
      closeOnDocumentClick
    >
      <TitleContainer>
        <Title>Se connecter</Title>
        <CloseButton onClick={onClose}>
          <Icon name="close" size={24} variant="white" />
        </CloseButton>
      </TitleContainer>

      {!!introduction && <PopupText>{introduction}</PopupText>}

      <Form
        onSubmit={handleSubmit(onSubmit)}
        loading={String(loading ? "loading" : "")}
        errors={errors}
      >
        <FormField label="Adresse email" required errors={errors} name="email">
          {({ name, ...childProps }) => (
            <FormInput
              {...register(name)}
              {...childProps}
              placeholder="Ex : c.vaultier@email.com"
            />
          )}
        </FormField>
        <FormField
          label="Mot de passe"
          required
          errors={errors}
          name="password"
        >
          {({ name, ...childProps }) => (
            <>
              <FormInput
                type="password"
                {...register(name)}
                {...childProps}
                placeholder="******"
              />
              {!!pagePasswordResetRequest && (
                <FormFieldLink
                  forwardedAs={PageLink}
                  to={pagePasswordResetRequest}
                  onClick={onClose}
                >
                  Mot de passe oublié ?
                </FormFieldLink>
              )}
            </>
          )}
        </FormField>

        <ButtonContainer>
          <FormButton type="submit" fullWidth>
            Se connecter
          </FormButton>
        </ButtonContainer>
      </Form>

      {!!pageRegistration && (
        <>
          <Separator />

          <PopupTitleContainer>
            <PopupTitleIcon size={22} name="presselib" variant="red" />
            <PopupTitle>Vous n'avez pas de compte ?</PopupTitle>
          </PopupTitleContainer>

          <PopupText>
            Vous pouvez en créer un en cliquant sur le bouton ci-dessous, c'est
            gratuit et pratique.
          </PopupText>

          <ButtonContainer>
            <FormButton
              forwardedAs={PageLink}
              to={pageRegistration}
              type="button"
              ghost
              onClick={onClose}
            >
              S'inscrire
            </FormButton>
          </ButtonContainer>
        </>
      )}
    </Popup>
  );
})`
  &-overlay {
    animation: ${appear} 0.3s backwards;
    background-color: ${color("overlayBackground")};
  }

  &-content {
    animation: ${slideIn} 0.3s backwards;
    background: white;
    max-width: 380px;
    width: 100%;
    padding: 40px 30px;
  }
`;
