import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Article from "../components/ArticleInList.jsx";
import Page from "../components/ElementInList.jsx";
import { Col, Row } from "../components/layout.jsx";
import Pagination, { usePagination } from "../components/Pagination.jsx";
import Tiles from "../components/Tiles.jsx";
import { useNavigation } from "../services/navigation.js";
import { useSearch } from "../services/search.js";
import SearchBar from "../templates/common/SearchBar.jsx";
import { useLocationQuery } from "../util/router.js";
import { color, context, font, isnot, styled } from "../util/style.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const HeaderBar = styled.div`
  ${isnot("withImage")`
    margin-top: 24px; 
  `}

  ${context("for-tablet-portrait")`
    margin-top: 24px;
  `}

  padding: 30px;
  background: ${color("greyBg")};
`;

const SearchInfo = styled.div`
  ${font("dataLabel")};
  margin: 30px 0;
`;
const Emphased = styled.span`
  color: ${color("orange")};
`;

const DEFAULT_LIMIT = 20;

const List = styled.div``;

const Separator = styled.hr`
  border: 0;
  display: block;
  border-top: 1px solid ${color("border")};
  margin: 20px 0;
`;

export default function Search() {
  const query = useLocationQuery();
  const defaultValue = query.get("q");
  const [{ page } = {}, { ready: pageReady }] = useNavigation();

  const [search, setSearch] = useState(defaultValue || "");

  const pagination = usePagination(DEFAULT_LIMIT);
  const [searchResults, { ready }] = useSearch(
    {
      // on force une recherche exacte si un seul mot est donné
      search: search.trim().split(/\s+/).length === 1 ? `"${search}"` : search,
    },
    pagination
  );

  useEffect(
    function() {
      if (!pageReady || !defaultValue) return;
      setSearch(defaultValue);
    },
    [pageReady, defaultValue]
  );

  return (
    <TwoColumnsPageTemplate
      Header={({ children, withImage }) => (
        <>
          {children}
          <HeaderBar withImage={withImage}>
            <Row>
              <Col span={6} md={12} fixed>
                <SearchBar />
              </Col>
            </Row>
          </HeaderBar>
        </>
      )}
    >
      <Helmet>
        {/* On empêche l'indexation (mais pas le crawl) des plus anciennes pages pour éviter de gréver le budget SEO */}
        {pagination.after > 100 && (
          <meta name="robots" contents="noindex, follow" />
        )}
      </Helmet>

      {searchResults ? (
        <>
          <SearchInfo>
            Résultat de votre recherche {!!search && `« ${search} » `}:{" "}
            <Emphased>
              {searchResults?.totalCount > 1
                ? `${searchResults?.totalCount} résultats`
                : searchResults?.totalCount > 0
                ? `${searchResults?.totalCount} résultat`
                : "Aucun résultat"}
            </Emphased>
          </SearchInfo>
          {ready && (
            <>
              <List>
                {(searchResults?.edges || []).map(({ node: result }, index) => {
                  const Component = result.kind === "article" ? Article : Page;
                  return (
                    <Fragment key={`${result.kind}-${result.id || index}`}>
                      <Separator />
                      <Component
                        mini
                        {...result}
                        featured={false}
                        withDate={true}
                        page={result}
                      />
                    </Fragment>
                  );
                })}
              </List>
              <Pagination
                result={searchResults}
                pagination={pagination}
                queryParameters={{ q: search }}
              />
            </>
          )}
        </>
      ) : (
        <Tiles tiles={page.contents} />
      )}
    </TwoColumnsPageTemplate>
  );
}
