import _mapValues from "lodash/mapValues";
import React from "react";

import { INTERESTS } from "../services/newsletter.js";
import {
  FormCheckbox,
  FormField,
  FormRadio,
  FormSection,
  FormSubFields,
} from "./forms.jsx";
import { Col, Row } from "./layout.jsx";

export default function NewsletterPreferencesForm({ watch, register, errors }) {
  const dailyNewsletter = watch("subscriptionKinds.daily");

  return (
    <>
      <FormSection title="Mes abonnements">
        <Row>
          <Col span={12} fixed>
            <FormField errors={errors} name="subscriptionKinds.daily">
              {({ name, ...childProps }) => (
                <>
                  <FormCheckbox {...register(name)} {...childProps}>
                    Abonnement au <strong>journal quotidien</strong>
                  </FormCheckbox>
                  <FormSubFields>
                    <FormField
                      label="Heure d'envoi"
                      errors={errors}
                      name="time"
                      disabled={!dailyNewsletter}
                    >
                      {({ name, ...childProps }) => (
                        <Row>
                          {INTERESTS.times.map((location) => (
                            <Col
                              span={4}
                              sm={4}
                              xs={12}
                              key={location.key}
                              fixed
                            >
                              <FormRadio
                                {...register(name)}
                                value={location.key}
                                {...childProps}
                              >
                                {location.name}
                              </FormRadio>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </FormField>
                  </FormSubFields>
                </>
              )}
            </FormField>

            <FormField errors={errors} name="subscriptionKinds.flash">
              {({ name, ...childProps }) => (
                <FormCheckbox {...register(name)} {...childProps}>
                  Abonnement aux <strong>flashs</strong>
                </FormCheckbox>
              )}
            </FormField>

            <FormField errors={errors} name="subscriptionKinds.partners">
              {({ name, ...childProps }) => (
                <FormCheckbox {...register(name)} {...childProps}>
                  Abonnement aux <strong>offres partenaires</strong>
                </FormCheckbox>
              )}
            </FormField>
          </Col>
        </Row>
      </FormSection>

      <FormSection title="Mes intérêts" optional>
        <FormField
          label="Choix des territoires"
          errors={errors}
          name="locations"
        >
          {({ name, ...childProps }) => (
            <Row>
              {INTERESTS.locations.map((location) => (
                <Col span={4} sm={6} xs={12} key={location.key} fixed>
                  <FormCheckbox
                    {...register(`${name}.${location.key}`)}
                    {...childProps}
                  >
                    {location.name}
                  </FormCheckbox>
                </Col>
              ))}
            </Row>
          )}
        </FormField>

        <FormField label="Choix des thèmes" errors={errors} name="categories">
          {({ name, ...childProps }) => (
            <Row>
              {INTERESTS.categories.map((location) => (
                <Col span={4} sm={6} xs={12} key={location.key} fixed>
                  <FormCheckbox
                    {...register(`${name}.${location.key}`)}
                    {...childProps}
                  >
                    {location.name}
                  </FormCheckbox>
                </Col>
              ))}
            </Row>
          )}
        </FormField>
      </FormSection>
    </>
  );
}
