import Icon from "../../components/Icon.jsx";
import { Col, Row } from "../../components/layout.jsx";
import PageLink from "../../components/PageLink.jsx";
import {
  useNavigationIndexParameters,
  usePagesByTag,
} from "../../services/navigation.js";
import { color, context, font, is, styled } from "../../util/style.js";

const MenuNavTitle = styled.div`
  font-family: ${font("secondary")};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 32px;
  ${context("for-tablet-portrait")`
    margin-bottom: 16px;
  `}
  ${context("for-phone")`
    font-size: 18px;
    line-height: 24px;
  `}
`;

const MenuNavItemLink = styled(PageLink)`
  color: ${color("black")};
  position: relative;
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    text-decoration: underline;
    transition: color 0.3s ease;
  }
`;

const MenuNavItemLinkIcon = styled(Icon)`
  margin: -8px -2px -8px -6px;
`;

const MenuNavItem = styled(function({ icon, children, to, className }) {
  return (
    <li className={className}>
      {!!icon && <MenuNavItemLinkIcon name={icon} size={28} variant="red" />}
      <MenuNavItemLink to={to}>{children}</MenuNavItemLink>
    </li>
  );
})`
  ${is("emphased")`
    ${MenuNavItemLink} {
      font-weight: 700;
    }
  `}
`;

const MenuNavList = styled.ul`
  & > ${MenuNavItem} {
    margin-bottom: 16px;
  }
`;

const MenuNavCloud = styled.ul`
  & > ${MenuNavItem} {
    display: inline-block;
    font-size: 13px;
    line-height: 17px;
    margin-right: 16px;
  }
`;

const Block = styled.div`
  margin-bottom: 40px;
`;

export default styled(function MenuNav({ className }) {
  const indexParameters = useNavigationIndexParameters();
  const mainMenu = indexParameters.mainMenu;
  const [featured] = usePagesByTag("nav:featured");

  const mainMenuVisible = (mainMenu || [])
    .filter((page) => !page.hideFromNavigation)
    .map((page) => ({
      ...page,
      children: (page.children || []).filter(
        (page) => !page.hideFromNavigation
      ),
    }));

  const totalPages = mainMenuVisible.reduce((totalPages, page) => {
    return totalPages + (page.children.length || 0) + 1;
  }, 0);

  const maxElementsPerColumn = Math.max(
    totalPages / 4, // trying to make 4 columns
    ...mainMenuVisible.map((page) => (page.children.length || 0) + 1) // if a page has a lot of children, we're trying to balance the columns
  );

  const columns = (mainMenuVisible || []).reduce((columns, page) => {
    if (!columns.length) columns.push([]);
    let currentColumn = columns[columns.length - 1];

    if (
      currentColumn.length + (page.children.length || 0) + 1 >
      maxElementsPerColumn
    ) {
      columns.push([]);
      currentColumn = columns[columns.length - 1];
    }

    currentColumn.push({ ...page, group: true }, ...page.children);

    return columns;
  }, []);

  return (
    <nav className={className}>
      <MenuNavTitle>Actualités</MenuNavTitle>
      <Row>
        {columns.map((pages, index) => (
          <Col span={12 / columns.length} sm="6" xs="12" key={index}>
            <Block>
              <MenuNavList>
                {pages.map((page) => (
                  <MenuNavItem key={page.id} to={page} emphased={!!page.group}>
                    {page.title || page.name}
                  </MenuNavItem>
                ))}
              </MenuNavList>
            </Block>
          </Col>
        ))}
      </Row>

      <Block>
        <MenuNavTitle>En ce moment</MenuNavTitle>
        <MenuNavCloud>
          {(featured || []).map((page) => (
            <MenuNavItem key={page.id} to={page}>
              {page.title || page.name}
            </MenuNavItem>
          ))}
        </MenuNavCloud>
      </Block>
    </nav>
  );
})``;
