import HttpStatusRoute from "../components/HttpStatusRoute.jsx";
import Tiles from "../components/Tiles.jsx";
import { useOneOfAKindPage } from "../services/navigation.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

export default function Error403() {
  const [page] = useOneOfAKindPage("error403", true);

  return (
    <HttpStatusRoute statusCode={403}>
      <TwoColumnsPageTemplate>
        <Tiles tiles={page?.contents} />
      </TwoColumnsPageTemplate>
    </HttpStatusRoute>
  );
}
