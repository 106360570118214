import _uniqueId from "lodash/uniqueId";
import _get from "lodash/get";
import { forwardRef, useEffect } from "react";
import Select from "react-select";

import { color, font, ifelse, is, eq, styled, isnot } from "../util/style.js";
import ContentLoading from "./ContentLoading.jsx";
import Icon from "./Icon.jsx";
import {
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
} from "./base-forms/forms.js";
import { Button } from "./buttons.jsx";

const FormLoading = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FormContent = styled.div`
  ${is("isLoading")`
    opacity: 0.6;
    pointer-events: none;
  `}
`;

export function useFormAction(
  form,
  action,
  { onError, onSuccess, transformData = (data) => data } = {}
) {
  const [fct, result, { loading, called, graphQLErrors }] = action;

  useEffect(
    function() {
      if (loading) return form.clearErrors();
      if (!called) return;

      filterErrorMessages(graphQLErrors).forEach((err) =>
        onError ? onError(err, form.setError) : form.setError("_global", err)
      );
      filterBadUserInputErrors(graphQLErrors).forEach((err) =>
        onError ? onError(err, form.setError) : form.setError(err.path, err)
      );
      if (result === false) {
        onError
          ? onError(null, form.setError)
          : form.setError(
              "_global",
              "Votre action n'a pas pu être réalisée. Veuillez réessayer plus tard."
            );
      }
      if (hasErrors(graphQLErrors) || !result) return;

      if (onSuccess) onSuccess(result);

      try {
        form.reset();
      } catch (e) {
        console.log(e);
      }
    },
    [loading, result, graphQLErrors]
  );

  return [form.handleSubmit((data) => fct(transformData(data))), action[2]];
}

export const Form = styled(
  forwardRef(function Form(
    {
      loading,
      error,
      errors,
      errorColor,
      errorName = "_global",
      children,
      ...props
    },
    ref
  ) {
    const errorMessage =
      error ||
      (errorName && errors ? errors[errorName]?.message : null) ||
      null;
    return (
      <form ref={ref} {...props}>
        <FormContent isLoading={loading}>{children}</FormContent>
        {!!loading && (
          <FormLoading>
            <ContentLoading />
          </FormLoading>
        )}
        {Boolean(errorMessage) && (
          <ErrorMessage color={errorColor}>{errorMessage}</ErrorMessage>
        )}
      </form>
    );
  })
)`
  position: relative;
`;

export const FormSeparator = styled.hr`
  margin: 35px 0 55px 0;
  border: 0;
  border-bottom: 1px solid ${color("form.separator")};
  display: inline-block;
  width: 100%;
  ${is("small")`
    display: inline-block !important;
    margin: 15px 0 25px 0;
  `}
`;

export const FormTitle = styled.h3`
  font-size: 1.3em;
  line-height: 120%;
  margin-bottom: 1.7em;
  small {
    font-weight: normal;
  }
`;

export const FormSection = styled(function({
  optional,
  title,
  className,
  children,
}) {
  return (
    <div className={className}>
      <FormSeparator />
      {!!title && (
        <FormTitle>
          {title} {!!optional && <small>(facultatif)</small>}
        </FormTitle>
      )}
      {children}
    </div>
  );
})`
  ${FormSeparator} {
    display: none;
  }
  & + & {
    ${FormSeparator} {
      display: block;
    }
  }
`;

export const FormButton = styled(
  forwardRef(function FormButton(
    { className, ghost, mini, white, black, ...props },
    ref
  ) {
    return (
      <div className={className}>
        <Button
          {...props}
          ref={ref}
          $mini={mini}
          $variant={
            white ? "white" : black ? "black" : ghost ? "ghost" : undefined
          }
        />
      </div>
    );
  })
)`
  display: inline-block;
  ${is("fullWidth")`
    &, ${Button} {
      display: block;
      width: 100%;
    }
  `}
  ${is("loading")`
    opacity: 0.7;
  `}
`;

const Label = styled.label`
  display: block;
  ${font("form.label")}
  margin-bottom: 8px;

  ${is("disabled")`
    opacity: 0.3;
  `}
`;

const Required = styled.label`
  color: ${color("form.required")};
`;

export const ErrorMessage = styled.p`
  ${font("form.label")}
  color: ${({ color, theme }) => color || theme.colors?.form?.error};
  display: block;
  margin-top: 4px;
  padding: 0;
`;

const InfoMessage = styled.p`
  color: #666666;
  display: block;
  font-size: 11px;
  margin: 0;
  padding: 0;
`;

export const FormField = styled(function FormField({
  label,
  name,
  required,
  error,
  errorColor,
  errors,
  info,
  children,
  className,
  ...props
}) {
  const errorMessage =
    error || (name && errors ? _get(errors, name)?.message : null) || null;
  return (
    <div className={className}>
      {!!label && (
        <Label htmlFor={name} disabled={props.disabled}>
          {label}
          {!!required && <Required>*</Required>}
        </Label>
      )}
      {typeof children === "function"
        ? children({ name, error: Boolean(errorMessage), ...props })
        : children}
      {Boolean(errorMessage) ? (
        <ErrorMessage color={errorColor}>{errorMessage}</ErrorMessage>
      ) : (
        Boolean(info) && <InfoMessage>{info}</InfoMessage>
      )}
    </div>
  );
})`
  margin-bottom: 24px;
  position: relative;
`;

export const FormSubFields = styled.div`
  margin-left: 32px;
  border-left: 1px solid ${color("border")};
  padding-left: 32px;
  ${FormField} {
    ${Label} {
      font-size: 14px;
    }
  }
`;

export const FormInput = styled.input`
  ${font("form.input")}
  background-color: ${color("form.input.background")};
  border: 1px solid transparent;
  width: 100%;
  max-width: 100%;
  padding: 10px 16px;
  transition: border-color 0.3s ease, background 0.3s ease;

  &::placeholder {
    color: ${color("form.input.placeholder")};
  }

  ${ifelse("disabled")`
    opacity: 0.3;
  ``
    ${ifelse("readonly")`
      opacity: 0.7;
    ``
      &:hover,
      &:focus {
        border-color: ${color("form.input.focus.border")};
        background-color: ${color("form.input.background")};
      }
    `}
  `}

  ${is("error")`
    background-color: ${color("form.input.error.background")} !important;
    border-color: ${color("form.input.error.border")} !important;
  `}
`;

export const FormTextarea = styled(
  forwardRef(function FormTextarea({ className, info, ...props }, ref) {
    return (
      <div className={className}>
        <FormInput as="textarea" ref={ref} {...props} />
        {!!info && <div className="FormTextarea-info">{info}</div>}
      </div>
    );
  })
)`
  position: relative;

  ${FormInput} {
    min-width: 100%;
    min-height: 160px;
    outline: 0;
  }

  .FormTextarea-info {
    position: absolute;
    bottom: 10px;
    right: 25px;
    font-size: 12px;
    color: ${color("greyTxt")};
    pointer-events: none;
  }
`;

const Checkbox = styled(
  forwardRef(function Checkbox({ error, className, ...props }, ref) {
    return (
      <div className={className}>
        <input type="checkbox" ref={ref} {...props} />
        <div className="fake">
          <Icon name="check" variant="red" size={22} />
        </div>
      </div>
    );
  })
)`
  input {
    position: absolute;
    left: -9999px;
  }
  .fake {
    width: 20px;
    height: 20px;
    border: 1px solid transparent;
    background: ${color("form.input.background")};
    transition: border 0.3s ease;
    position: relative;

    ${Icon} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input:focus + .fake {
    border-color: ${color("form.input.focus.border")};
  }

  ${is("error")`
    .fake {
      border-color: ${color("form.input.error.border")};
      background: ${color("form.input.error.background")};
    }
  `}

  input:checked + .fake {
    ${Icon} {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  ${font("form.label")}
  margin-left: 12px;
`;

export const FormCheckbox = styled(
  forwardRef(function FormCheckbox(
    { className, name, children, ...props },
    ref
  ) {
    const id = _uniqueId();
    return (
      <label className={className} htmlFor={`checkbox-${id}`}>
        <Checkbox {...props} ref={ref} id={`checkbox-${id}`} name={name} />
        <Content>{children}</Content>
      </label>
    );
  })
)`
  display: flex;
  cursor: pointer;
  margin: 3px 0;

  ${ifelse("disabled")`
    opacity: 0.3;
  ``
    &:hover,
    &:focus {
      ${Checkbox} .fake {
        border-color: ${color("form.input.focus.border")};
      }
    }
  `}
`;

const Radio = styled(
  forwardRef(function Radio({ error, className, ...props }, ref) {
    return (
      <div className={className}>
        <input type="radio" ref={ref} {...props} />
        <div className="fake">
          <Icon name="check" variant="red" size={22} />
        </div>
      </div>
    );
  })
)`
  input {
    position: absolute;
    left: -9999px;
  }
  .fake {
    width: 22px;
    height: 22px;
    border: 1px solid transparent;
    background: ${color("form.input.background")};
    transition: border 0.3s ease;
    position: relative;
    border-radius: 100%;

    ${Icon} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input:focus + .fake {
    border-color: ${color("form.input.focus.border")};
  }

  ${is("error")`
    .fake {
      border-color: ${color("form.input.error.border")};
      background: ${color("form.input.error.background")};
    }
  `}

  input:checked + .fake {
    ${Icon} {
      opacity: 1;
    }
  }
`;

export const FormRadio = styled(
  forwardRef(function FormRadio({ className, children, ...props }, ref) {
    const id = _uniqueId();
    return (
      <label className={className} htmlFor={`radio-${id}`}>
        <Radio {...props} id={`radio-${id}`} ref={ref} />
        <Content>{children}</Content>
      </label>
    );
  })
)`
  display: flex;
  cursor: pointer;
  margin: 3px 0;

  ${ifelse("disabled")`
    opacity: 0.3;
  ``
    &:hover,
    &:focus {
      ${Radio} .fake {
        border-color: ${color("form.input.focus.border")};
      }
    }
  `}
`;

export const FormSelect = styled(
  forwardRef(function FormSelect(
    { className, value, onChange, onBlur, disabled, ...props },
    ref
  ) {
    const selectedOption =
      (props.options || []).find((option) => option.value === value) || null;
    const handleSelect = function(option) {
      if (onChange && option) onChange(option.value);
    };

    return (
      <div className={className}>
        <Select
          classNamePrefix="select"
          isDisabled={disabled}
          {...props}
          value={selectedOption}
          onChange={handleSelect}
          ref={ref}
        />
      </div>
    );
  })
)`
.select {
  &__control {
    ${font("form.input")}
    background-color: ${color("form.input.background")};
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    transition: border-color 0.3s ease, background 0.3s ease;

    ${ifelse("disabled")`
      opacity: 0.3;
    ``
      ${ifelse("readonly")`
        opacity: 0.7;
      ``
      &:hover,
        &:focus {
          border-color: ${color("form.input.focus.border")};
          background-color: ${color("form.input.background")};
        }
      `}
    `}

    ${is("error")`
      background-color: ${color("form.input.error.background")} !important;
      border-color: ${color("form.input.error.border")} !important;
    `}
    
  }
  &__value-container {
    padding: 6px 14px;
  }
  &__placeholder {
    color: ${color("form.input.placeholder")};
  }
}
`;

const FormFileUploadButton = styled.button`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 42px;
  bottom: -1px;
  background: ${color("orange")};
  transition: background 0.3s;
`;

export const FormFileUpload = styled(
  forwardRef(function FormFileUpload(
    { error, className, value, onChange, onBlur, placeholder, ...props },
    ref
  ) {
    const id = _uniqueId();
    return (
      <label className={className} htmlFor={`upload-${id}`}>
        <input
          type="file"
          onChange={(e) => onChange(e.target.files?.[0])}
          onBlur={onBlur}
          {...props}
          id={`upload-${id}`}
          ref={ref}
        />
        <FormInput error={error} as="div" className="FileUploadContainer">
          {value?.name || placeholder}
          <FormFileUploadButton>
            <Icon name="file-add" variant="white" />
          </FormFileUploadButton>
        </FormInput>
      </label>
    );
  })
)`
  display: flex;
  cursor: pointer;
  margin: 3px 0;

  input {
    position: absolute;
    left: -9999px;
  }

  ${isnot("value")`
    ${FormInput} {
      color: ${color("form.input.placeholder")};
    }
  `}

  ${FormInput} {
    position: relative;
  }

  ${FormFileUploadButton} {
    pointer-events: none;
  }

  ${ifelse("disabled")`
    opacity: 0.3;
  ``
    &:hover,
    &:focus {
      ${FormFileUploadButton}{
        background: ${color("orangeDarker")};
      }
    }
  `}
`;

export const FormFieldLink = styled(function({ className, as, ...props }) {
  const Component = as || "a";
  return (
    <div className={className}>
      <Component {...props} />
    </div>
  );
})`
  font-size: 12px;
  text-align: right;
`;
