import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";

const tenderInvitationsQuery = gql`
  mutation SendTenderInvitation($data: TenderInvitationsPublicInput!) {
    public {
      tenderInvitations {
        success: createInvitationToTender(data: $data)
      }
    }
  }
`;

export function useTenderInvitations() {
  const [mutation, result] = useMutation(tenderInvitationsQuery);

  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "public.tenderInvitations.success"),
  ];
}
