import {
  useContext,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";

import Icon from "./components/Icon.jsx";
import { color, context } from "./util/style.js";

const PwaContext = createContext();

export function usePwa() {
  return useContext(PwaContext);
}

export const PwaProvider = styled(function PwaProvider({
  className,
  children,
}) {
  const [pwaPrompt, setPwaPrompt] = useState();
  const [registration, setRegistration] = useState();

  useEffect(function() {
    if (typeof window === "undefined") return;

    function promptInstallPWA(e) {
      e.preventDefault();
      setPwaPrompt(e);
    }

    function pwaInstalled() {
      setPwaPrompt(null);
    }

    window.addEventListener("beforeinstallprompt", promptInstallPWA);
    window.addEventListener("appinstalled", pwaInstalled);

    if ("serviceWorker" in window.navigator) {
      // Register a service worker hosted at the root of the
      // site using the default scope.
      // eslint-disable-next-line promise/catch-or-return
      window.navigator.serviceWorker.register("/service-worker.js").then(
        function(registration) {
          setRegistration(registration);
          console.log("Service worker registration succeeded:", registration);
          // registration.addEventListener("updatefound", function() {
          //   console.log("new version detected!");
          // });
          return;
        },
        /*catch*/ function(error) {
          console.log("Service worker registration failed:", error);
        }
      );
    }

    return () => {
      window.removeEventListener("beforeinstallprompt", promptInstallPWA);
      window.removeEventListener("appinstalled", pwaInstalled);
    };
  }, []);

  const handlePromptPwa = useCallback(() => {
    pwaPrompt.prompt();
    // eslint-disable-next-line promise/catch-or-return
    pwaPrompt.userChoice.then(() => {
      /*      
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      */
      setPwaPrompt(false);
      return;
    });
  }, [pwaPrompt]);

  return (
    <PwaContext.Provider value={{ registration }}>
      {children}
      {!!pwaPrompt && (
        <div className={className}>
          <AddButton onClick={() => handlePromptPwa()}>
            <Icon name="presselib" variant="red" size={32} />
            Ajouter Presselib à l'écran d'accueil
          </AddButton>
          <CloseButton onClick={() => setPwaPrompt(false)}>
            <Icon name="close" variant="white" size={32} />
          </CloseButton>
        </div>
      )}
    </PwaContext.Provider>
  );
})`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: ${color("green")};
  display: none;
  flex-direction: row;

  ${context("for-tablet-landscape")`
    display: flex;
  `}
`;

const AddButton = styled.button`
  flex: 1;
  color: white;
  height: 60px;
  padding: 0;
  text-align: center;
  ${Icon} {
    vertical-align: middle;
  }
`;
const CloseButton = styled.button`
  width: 60px;
  height: 60px;
`;
