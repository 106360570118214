import { context, styled } from "../util/style.js";

export default styled(function({ kind, container: Container, ...props }) {
  let name = null;
  switch (kind) {
    case "entrepreneur":
      name = "Créateurs et Passionnés";
      break;
    case "solidarity":
      name = "Initiatives RSE";
      break;
    case "sponsored":
      name = "Contenu partenaire";
      break;
    case "infomercial":
      name = "Publi-reportage";
      break;
  }
  if (!name) return null;
  if (!Container) Container = "div";
  return (
    <Container>
      <div {...props}>{name}</div>
    </Container>
  );
})`
  background-color: ${({ kind, theme }) =>
    theme.colors.articles.kinds[kind]?.background || theme.colors.green};
  border: 1px solid
    ${({ kind, theme }) =>
      theme.colors.articles.kinds[kind]?.border || theme.colors.border};
  color: ${({ kind, theme }) =>
    theme.colors.articles.kinds[kind]?.color || theme.colors.white};
  display: inline-block;
  font-size: 11px;
  line-height: 14px;
  padding: 4px;
  text-align: center;
  text-transform: uppercase;
  ${context("for-phone")`
    font-size: 10px;
  `}
`;
