import { Redirect } from "react-router-dom";

import { buildPath } from "../../common/alias.js";

export default function PageRedirect({ to, parameters, ...props }) {
  const redirectPageUrl = to
    ? buildPath((to && to.alias) || to, parameters)
    : null;

  return !to ? (
    <span>Redirection en cours&hellip;</span>
  ) : (
    <Redirect to={redirectPageUrl} {...props} />
  );
}
