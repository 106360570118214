export function tagsAsStringList(tags) {
  return (tags || []).map((tag) => tag.replace(/^\w+:/, ""));
}

export const aliasByType = {
  category: "categorie",
  location: "zone",
  tag: "theme",
};
export const typeByAlias = Object.keys(aliasByType).reduce(
  (all, type) => ({ ...all, [aliasByType[type]]: type }),
  {}
);
