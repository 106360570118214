import { useCallback, useEffect, useState } from "react";

export function useMediaQuery(mediaQuery) {
  const [mediaQueryMatches, setMediaQueryMatches] = useState(false);
  const updateMediaQueryMatches = useCallback(
    function() {
      setMediaQueryMatches(matchMedia(mediaQuery).matches);
    },
    [mediaQuery, setMediaQueryMatches]
  );

  useEffect(() => {
    if (typeof window === "undefined") return;
    window.addEventListener("resize", updateMediaQueryMatches);
    updateMediaQueryMatches();
    return () => window.removeEventListener("resize", updateMediaQueryMatches);
  }, [updateMediaQueryMatches]);

  return mediaQueryMatches;
}

export function useMediaContext(
  contextsWidths = { mobile: 480, tablet: 1024, desktop: 1824, large: 9999 }
) {
  const [context, setContext] = useState(null);
  const updateContext = useCallback(
    function() {
      let context = null;
      for (context of Object.keys(contextsWidths)) {
        const contextMatch = matchMedia(
          `(max-width: ${contextsWidths[context]}px)`
        ).matches;
        if (!contextMatch) continue;
        setContext(context);
        return;
      }
    },
    [contextsWidths, setContext]
  );

  useEffect(() => {
    if (typeof window === "undefined") return;
    window.addEventListener("resize", updateContext);
    updateContext();
    return () => window.removeEventListener("resize", updateContext);
  }, [updateContext]);

  return context;
}
