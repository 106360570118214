import iconsSheet from "../img/icons-presselib.png";
import { ifelse, is, prop, styled } from "../util/style.js";

const icons = [
  "pen",
  "menu",
  "micro",
  "newsletter",
  "lightbulb",
  "location",
  "search",
  "bookmark",
  "presselib",
  "user",
  "facebook",
  "twitter",
  "linkedin",
  "mail",
  "instagram",
  "close",
  "arrow-left",
  "quote-start",
  "quote-end",
  "check",
  "home",
  "comment",
  "bookmark-simple",
  "bookmark-fill",
  "logout",
  "alert",
  "file-add",
];

const variants = ["black", "red", "white", "green", "grey"];

const DEFAULT_SIZE = 32;

export default styled(function Icon({ className }) {
  return <span className={className} />;
})`
  position: relative;
  ${ifelse("block")`
    display: block;
  ``
    display: inline-block;
  `}

  width: ${({ size = DEFAULT_SIZE }) => size}px;
  height: ${({ size = DEFAULT_SIZE }) => size}px;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ size = DEFAULT_SIZE }) => size}px;
    height: ${({ size = DEFAULT_SIZE }) => size}px;
    transition: var(--icon-transition, opacity 0.3s ease);

    background-image: url(${iconsSheet});
    background-size: ${({ size = DEFAULT_SIZE }) => size * icons.length}px
      ${({ size = DEFAULT_SIZE }) => size * variants.length}px;
    background-repeat: no-repeat;

    /* prevent blur on scale */
    transform: translate(-50%, -50%) translateZ(0);
    image-rendering: -webkit-optimize-contrast;
    will-change: transform;
  }

  &::before {
    background-position: ${({ name, size = DEFAULT_SIZE }) =>
      icons.indexOf(name) * size * -1}px
      ${({ variant = variants[0], size = DEFAULT_SIZE }) =>
        variants.indexOf(variant) * size * -1}px;
    opacity: 1;
  }

  &::after {
    opacity: 0;
  }

  ${is(({ hoverVariant, hoverName }) => hoverVariant || hoverName)`
    &::after {
      background-position: ${({ hoverName, name, size = DEFAULT_SIZE }) =>
        icons.indexOf(hoverName || name) * size * -1}px
        ${({ hoverVariant = variants[0], size = DEFAULT_SIZE }) =>
          variants.indexOf(hoverVariant) * size * -1}px;
    }

    &::after {
      opacity: var(--icon-hover, 0);
    }
    &::before {
      opacity: calc(1 - var(--icon-hover, 0));
    }
    &:hover::after {
      opacity: 1;
    }
    &:hover::before {
      opacity: 0;
    }
  `}
`;
