import Pagination, { usePagination } from "../components/Pagination.jsx";
import SimpleArticlesList from "../components/SimpleArticlesList.jsx";
import Tiles from "../components/Tiles.jsx";
import { useFavoriteArticles } from "../services/articles.js";
import { useUserSession } from "../services/auth.js";
import { useNavigationPage } from "../services/navigation.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const DEFAULT_LIMIT = 10;

export default function FavoritesPage() {
  const page = useNavigationPage();
  const [session] = useUserSession();
  const pagination = usePagination(DEFAULT_LIMIT);
  const [articles, { ready }] = useFavoriteArticles(pagination);

  return (
    <TwoColumnsPageTemplate>
      <SimpleArticlesList
        title="Mes articles favoris"
        icon="bookmark-simple"
        empty="Vous n'avez enregistré aucun article favori"
        articles={(articles?.edges || []).map(({ node }) => node)}
        loading={!ready}
      />
      {Boolean(ready) && (
        <Pagination result={articles} pagination={pagination} />
      )}
      <Tiles tiles={page.contents} />
    </TwoColumnsPageTemplate>
  );
}
