import Icon from "../../../../../app/components/Icon.jsx";
import PageLink from "../../../../../app/components/PageLink.jsx";
import {
  useNavigationIndex,
  useNavigationPage,
  usePagesTreeByPageId,
} from "../../../../../app/services/navigation.js";
import { color, font, is, styled } from "../../../../../app/util/style.js";
import dashedBorder from "./assets/motif-lines.svg";

const Container = styled.div`
  padding: 24px;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  font-family: ${font("secondary")};
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 16px;
`;

const TitleIcon = styled(Icon)`
  color: ${color("orange")};
  margin-right: 4px;
`;

const Link = styled(PageLink)`
  color: ${color("greyTxt")};
  display: inline-block;
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    text-decoration: underline;
    transition: color 0.3s ease;
  }

  ${is("$active")`
    font-weight: 600;
    pointer-events: none;
  `}
`;

const Item = styled.li`
  display: block;
`;

const List = styled.ul`
  ${Item}:not(:first-child) {
    margin-top: 12px;
  }

  & & {
    border-left: 1px solid ${color("border")};
    margin-bottom: 8px;
    margin-top: 12px;
    padding-left: 16px;
  }
`;

const PageTitleLink = styled(PageLink)`
  color: ${color("black")};
  display: inline-block;
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    text-decoration: underline;
    transition: color 0.3s ease;
  }
`;

function ChildrenPages({ page, currentPage }) {
  if (!page || !page.children) return null;
  const children = page.children.filter(
    ({ hideFromNavigation }) => !hideFromNavigation
  );
  return (
    Boolean(children.length) && (
      <List>
        {children.map((child) => (
          <Item key={child.id}>
            <Link to={child} $active={child.id === currentPage.id}>
              {child.title}
            </Link>
            <ChildrenPages page={child} currentPage={currentPage} />
          </Item>
        ))}
      </List>
    )
  );
}

const Menu = styled(function({ page, className }) {
  const currentPage = useNavigationPage();

  return (
    <div className={`${className || ""} tile-container tile-menu`}>
      <Container>
        <Title>
          <TitleIcon name="presselib" size={22} variant="red" />
          <PageTitleLink to={page}>{page?.title}</PageTitleLink>
        </Title>
        <ChildrenPages page={page} currentPage={currentPage} />
      </Container>
    </div>
  );
})`
  background-color: ${color("greyBg2")};
  &::before {
    background-image: url(${dashedBorder});
    background-repeat: repeat;
    background-size: 4px 4px;
    content: "";
    display: block;
    height: 10px;
    width: 100%;
  }
`;

function SpecificPageMenu({ pageId, levels }) {
  const [page, { ready }] = usePagesTreeByPageId(pageId, levels);
  return Boolean(ready) && <Menu page={page} />;
}

function RootMenu({ levels }) {
  const rootId = useNavigationIndex().id;
  return <SpecificPageMenu pageId={rootId} levels={levels} />;
}

function CurrentPageMenu({ levels }) {
  const currentPageId = useNavigationPage().id;
  return <SpecificPageMenu pageId={currentPageId} levels={levels} />;
}

function ParentPageMenu({ levels, ancestorLevel }) {
  const currentPage = useNavigationPage();
  const ancestors = currentPage.ancestors;
  if (!ancestors?.length) return <RootMenu levels={levels} />;

  const ancestorsAndCurrentPage = [...(ancestors || []), currentPage];

  const parentPage =
    ancestorsAndCurrentPage[Math.min(ancestors.length, ancestorLevel || 0)];
  return <SpecificPageMenu pageId={parentPage.id} levels={levels} />;
}

export default function ReactMenuTile({ tile, options }) {
  const { target = "current", levels, page = null, ancestorLevel } = tile;

  return target === "root" ? (
    <RootMenu levels={levels} />
  ) : target === "page" ? (
    <SpecificPageMenu pageId={page} levels={levels} />
  ) : target === "parent" ? (
    <ParentPageMenu levels={levels} ancestorLevel={ancestorLevel} />
  ) : (
    <CurrentPageMenu levels={levels} />
  );
}
