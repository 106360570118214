import { Helmet } from "react-helmet";

import Ads from "../components/Ads.jsx";
import ArticlesList from "../components/ArticlesList.jsx";
import PagesGrid from "../components/PagesGrid.jsx";
import Pagination, { usePagination } from "../components/Pagination.jsx";
import Separator from "../components/Separator.jsx";
import Tiles from "../components/Tiles.jsx";
import { useAds } from "../services/ads.js";
import { useArticlesList } from "../services/articles.js";
import { useNavigationPage } from "../services/navigation.js";
import { styled } from "../util/style.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const DEFAULT_LIMIT = 20;

const AdsContainer = styled.div``;

export default function Articles() {
  const page = useNavigationPage();
  const pagination = usePagination(DEFAULT_LIMIT);
  const [articles, { ready }] = useArticlesList(page.parameters, pagination);
  const [ads] = useAds([
    "articles:middle",
    "articles:articles-start",
    "articles:articles-end",
  ]);

  const subPages = (page.children || []).filter(
    (page) => !page.hideFromNavigation
  );

  return (
    <TwoColumnsPageTemplate>
      <Helmet>
        {/* On empêche l'indexation (mais pas le crawl) des plus anciennes pages pour éviter de gréver le budget SEO */}
        {pagination.after > 100 && (
          <meta name="robots" contents="noindex, follow" />
        )}
      </Helmet>
      {subPages.length > 0 && (
        <>
          <PagesGrid pages={subPages} />
          <Separator />
        </>
      )}
      {pagination.after === 0 && <Tiles tiles={page.contents} />}
      {ready &&
        (articles?.edges?.length ? (
          <>
            {ads?.length > 0 && (
              <AdsContainer>
                <Ads list={ads} tag={"articles:articles-start"} />
              </AdsContainer>
            )}
            <ArticlesList
              articles={(articles?.edges || []).map(({ node }) => node)}
              forceFirstFeatured={pagination.after === 0}
            >
              {ads?.length > 0 && (
                <AdsContainer>
                  <Ads list={ads} tag={"articles:middle"} />
                </AdsContainer>
              )}
            </ArticlesList>
            {ads?.length > 0 && (
              <AdsContainer>
                <Ads list={ads} tag={"articles:articles-end"} />
              </AdsContainer>
            )}
            <Pagination result={articles} pagination={pagination} />
          </>
        ) : (
          <div>Aucun article à afficher</div>
        ))}
    </TwoColumnsPageTemplate>
  );
}
