import { Button } from "../components/buttons.jsx";

import PageLink from "../components/PageLink.jsx";
import PagesGrid from "../components/PagesGrid.jsx";
import Separator from "../components/Separator.jsx";
import Tiles from "../components/Tiles.jsx";
import {
  useNavigationPage,
  useNavigationPageParameters,
} from "../services/navigation.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import { getImageProps } from "../util/medias.js";
import { styled, color, context, font } from "../util/style.js";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const Typography = styled.div`
  margin-top: -4px;
  ${TypographyStyle}
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  > * {
    flex: 1;
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
    padding: 10px;
    position: relative;

    ${context("for-tablet-portrait")`
      flex-basis: 50%;
      max-width: 50%;
    `}
    ${context("for-phone")`
      flex-basis: 100%;
      max-width: 100%;
    `}
  }
`;

export default function Notices() {
  const page = useNavigationPage();
  const pageParameters = useNavigationPageParameters();

  const noticesPages = pageParameters.noticesPages || [];

  const categories = (page.children || []).filter(
    (page) => !page.hideFromNavigation && page.children?.length > 0
  );

  return (
    <TwoColumnsPageTemplate>
      <Tiles tiles={page.contents} />

      {Boolean(noticesPages.length > 0) && (
        <>
          <Typography>
            <h2>Consulter les annonces</h2>
          </Typography>
          <PagesGrid pages={noticesPages} />
          <Separator />
        </>
      )}
      {Boolean(categories.length > 0) && (
        <>
          <Typography>
            <h2>Publier une annonce</h2>
          </Typography>
          {categories.map((categoryPage) => (
            <Section key={categoryPage.id}>
              <Typography>
                <h3>{categoryPage.title}</h3>
              </Typography>
              <Grid>
                {(categoryPage.children || [])
                  .filter((page) => !page.hideFromNavigation)
                  .map((subPage) => (
                    <div key={subPage.id}>
                      <ButtonImage
                        page={subPage}
                        button={
                          <Button as={PageLink} to={subPage}>
                            Publier
                          </Button>
                        }
                      />
                    </div>
                  ))}
              </Grid>
            </Section>
          ))}
        </>
      )}
    </TwoColumnsPageTemplate>
  );
}

const Section = styled.div`
  margin-bottom: 60px;
`;

const ButtonImage = styled(function({ page, button, className }) {
  const { title, image } = page;
  return (
    <div className={className}>
      <div className="ButtonImage-container">
        <div className="ButtonImage-image-container">
          <img
            alt={title}
            className="ButtonImage-image"
            width={750}
            height={420}
            {...getImageProps(image, "list")}
          />
          <div className="ButtonImage-image-overlay" />
        </div>
        <div className="ButtonImage-title">{title}</div>
      </div>
      <div className="ButtonImage-button">{button}</div>
    </div>
  );
})`
  .ButtonImage {
    &-container {
      position: relative;
    }
    &-image-container {
      overflow: hidden;
      position: relative;
      padding-top: 56.25%; /* 16/9 */
    }
    &-image {
      background-color: ${color("image.placeholder")};
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.3s ease;
      object-fit: cover;
      width: 100%;
    }
    &-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: black;
      opacity: 0.7;
    }
    &-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      padding: 0.125em 0.35em;
      font-weight: bolder;
      font-family: ${font("secondary")};
      font-size: 16px;
      width: auto;
      text-align: center;
      div {
        width: 100%;
      }
    }
    &-button {
      margin-top: -20px;
      z-index: 1;
      position: relative;
      text-align: center;
    }
  }
`;
