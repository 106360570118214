import styled from "styled-components";

import { getImageProps } from "../util/medias.js";
import { color, context, font } from "../util/style.js";
import PageLink from "./PageLink.jsx";

const PageImageContainer = styled.div`
  position: relative;
`;

const PageImage = styled.img`
  background-color: ${color("image.placeholder")};
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease;
  object-fit: cover;
  width: 100%;
`;

const PageImageMask = styled.div`
  overflow: hidden;
  position: relative;
  padding-top: 56.25%; /* 16/9 */

  &:hover {
    ${PageImage} {
      opacity: 0.7;
    }
  }
`;
const PageTitle = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: ${color("orange")};
  color: white;
  padding: 0.125em 0.35em;
  font-weight: bolder;
  font-family: ${font("secondary")};
  font-size: 16px;
`;
const ChildPage = styled.li``;
const ChildPageLink = styled(PageLink)`
  color: ${color("greyTag")};
  font-family: ${font("secondary")};
  font-size: 14px;
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    text-decoration: underline;
    transition: color 0.3s ease;
  }
`;
const ChildrenPages = styled(function({ pages, className }) {
  return (
    <ul className={className}>
      {pages.map((page) => (
        <ChildPage key={page.id}>
          <ChildPageLink to={page}>{page.title}</ChildPageLink>
        </ChildPage>
      ))}
    </ul>
  );
})`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  & > ${ChildPage} + ${ChildPage}::before {
    background-color: ${color("greyTag")};
    border-radius: 50px;
    content: "";
    display: inline-block;
    margin: 0 6px;
    height: 4px;
    position: relative;
    top: -1px;
    width: 4px;
  }
`;
const PageItem = styled(function({ page, className }) {
  const { title, image } = page;
  return (
    <div className={className}>
      <PageLink to={page}>
        <PageImageContainer>
          <PageImageMask>
            <PageImage
              width={750}
              height={420}
              {...getImageProps(image, "list")}
            />
          </PageImageMask>
          <PageTitle>{title}</PageTitle>
        </PageImageContainer>
      </PageLink>
      {page.children?.length > 0 && (
        <ChildrenPages pages={page.children || []} />
      )}
    </div>
  );
})`
  flex: 1;
  flex-basis: 33.3333333%;
  max-width: 33.3333333%;
  position: relative;
  ${context("for-tablet-portrait")`
    flex-basis: 50%;
    max-width: 50%;
  `}
  ${context("for-phone")`
    flex-basis: 100%;
    max-width: 100%;
  `}
`;
export default styled(function PagesGrid({ pages, className }) {
  return (
    <div className={className}>
      {pages.map((page) => (
        <PageItem key={page.id} page={page} />
      ))}
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  ${PageItem} {
    padding: 10px;
  }
`;
