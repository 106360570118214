import _mapValues from "lodash/mapValues";
import _pick from "lodash/pick";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Controller,
  Yup,
  filterBadUserInputErrors,
  filterErrorMessages,
  hasErrors,
  useForm,
  yupResolver,
} from "../components/base-forms/forms.js";
import ConfirmationPopup from "../components/ConfirmationPopup.jsx";
import {
  Form,
  FormButton,
  FormCheckbox,
  FormField,
  FormInput,
  FormRadio,
  FormSection,
  FormSelect,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import NewsletterPreferencesForm from "../components/NewsletterPreferencesForm.jsx";
import Tiles from "../components/Tiles.jsx";
import * as validator from "../components/validators.js";
import { useUserSignup } from "../services/auth.js";
import {
  useNavigationPage,
  useNavigationPageParameters,
} from "../services/navigation.js";
import {
  INTERESTS,
  typeToModel as newsletterTypeToModel,
} from "../services/newsletter.js";
import TypographyStyle from "../templates/common/TypographyStyle.jsx";
import TwoColumnsPageTemplate from "./templates/TwoColumnsPageTemplate.jsx";

const SignupForm = styled(Form)`
  max-width: 555px;
  margin-bottom: 40px;
`;

const Typography = styled.div`
  margin-top: -4px;
  ${TypographyStyle}
`;

function getDefaultValues() {
  return {
    email: "",
    firstname: "",
    lastname: "",
    profileKind: null,
    subscriptionKinds: {
      daily: false,
      flash: false,
      partners: false,
    },
    ..._mapValues(
      INTERESTS,
      (interests) =>
        interests.reduce(
          (interests, interest) => ({
            ...interests,
            [interest.key]: false,
          }),
          {}
        ),
      {}
    ),
    time: INTERESTS.times[0].key,
  };
}

function useSignupForm(defaultValues = {}) {
  return useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        email: validator.email.required("Veuillez renseigner votre email"),
        phone: validator.phone.nullable(),
        password: Yup.string().required("Veuillez entrer un mot de passe"),
        confirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe doivent correspondre"
        ),
        /*
        firstname: validator.firstname.required(
          "Veuillez renseigner votre prénom"
        ),
        lastname: validator.lastname.required("Veuillez renseigner votre nom"),
        profileKind: Yup.string()
          .oneOf(
            ["professional", "personal"],
            "Veuillez choisir un profil valide"
          )
          .required("Veuillez choisir votre profil"),
          */
        consent: Yup.boolean().oneOf(
          [true],
          "Veuillez accepter les conditions"
        ),
      })
    ),
  });
}

export default function Signup() {
  const page = useNavigationPage();
  const pageParameters = useNavigationPageParameters();
  const [success, setSuccess] = useState(false);

  const {
    register,
    setError,
    watch,
    clearErrors,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useSignupForm(getDefaultValues());

  const [
    signup,
    signupResult,
    { loading, called, graphQLErrors: signupErrors },
  ] = useUserSignup();

  const onSubmit = useCallback(
    function(data) {
      signup({
        ..._pick(data, ["email", "phone", "address", "password", "civility"]),
        ...newsletterTypeToModel(data),
      });
    },
    [clearErrors, signup]
  );

  useEffect(
    function() {
      if (loading) return clearErrors();
      if (!called) return;
      filterErrorMessages(signupErrors).forEach((err) => {
        switch (err.code) {
          case "user-duplicate":
            setError("_global", {
              message: "Vous êtes déjà inscrit(e) à notre newsletter",
            });
            break;
          default:
            setError("_global", err);
        }
      });
      filterBadUserInputErrors(signupErrors).forEach((err) =>
        setError(err.path, err)
      );
      if (signupResult === false) {
        setError("_global", {
          message: "L'inscription a échoué. Veuillez réessayer plus tard.",
        });
      }
      if (hasErrors(signupErrors) || !signupResult) return;

      // en cas de succès, affiche une popup
      setSuccess(true);

      // clear le form
      try {
        reset(getDefaultValues());
      } catch (e) {
        console.log(e);
      }
    },
    [loading, signupResult, signupErrors]
  );

  return (
    <TwoColumnsPageTemplate>
      <Tiles tiles={page.contents} />

      <ConfirmationPopup
        title="Inscription réussie"
        open={success}
        onClose={() => setSuccess(false)}
      >
        Vous allez prochainement recevoir un email contenant un lien pour
        finaliser votre compte. Si vous ne le recevez pas, vérifiez vos
        courriers indésirables ou que l'adresse que vous avez saisie est
        correcte.
      </ConfirmationPopup>
      <SignupForm
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        errors={errors}
      >
        <FormSection title="Informations obligatoires pour votre compte">
          <Row>
            <Col span={8} sm={12} fixed>
              <FormField
                label="Adresse email"
                required
                errors={errors}
                name="email"
              >
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : c.vaultier@email.com"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={6} sm={12}>
              <FormField
                label="Mot de passe"
                required
                errors={errors}
                type="password"
                name="password"
              >
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="*******"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
            <Col span={6} sm={12}>
              <FormField
                label="Confirmer le mot de passe"
                required
                errors={errors}
                type="password"
                name="confirmation"
              >
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="*******"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>
        </FormSection>

        <FormSection title="Informations complémentaires" optional>
          <Row>
            <Col span={8} sm={12} fixed>
              <FormField label="Civilité" errors={errors} name="civility">
                {({ name, ...childProps }) => (
                  <Row>
                    <Col span={6} sm={6} xs={12} fixed>
                      <FormRadio {...register(name)} value="mr" {...childProps}>
                        Monsieur
                      </FormRadio>
                    </Col>
                    <Col span={6} sm={6} xs={12} fixed>
                      <FormRadio
                        {...register(name)}
                        value="mrs"
                        {...childProps}
                      >
                        Madame
                      </FormRadio>
                    </Col>
                  </Row>
                )}
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={6} sm={12}>
              <FormField label="Prénom" errors={errors} name="firstname">
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : Camille"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
            <Col span={6} sm={12}>
              <FormField label="Nom" errors={errors} name="lastname">
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : Vaultier"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>
          <Row>
            <Col span={8} sm={12} fixed>
              <FormField
                label="C'est un mail"
                errors={errors}
                name="profileKind"
              >
                {({ name, ...childProps }) => (
                  <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                      <FormSelect
                        placeholder="Choisir..."
                        {...field}
                        {...childProps}
                        options={[
                          {
                            value: "personal",
                            label: "personnel",
                          },
                          {
                            value: "professional",
                            label: "professionnel",
                          },
                        ]}
                      />
                    )}
                  />
                )}
              </FormField>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <FormField label="Adresse" errors={errors} name="address.street">
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : 2, rue de La Boétie"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>

          <Row>
            <Col span={4} sm={12}>
              <FormField
                label="Code postal"
                errors={errors}
                name="address.postalCode"
              >
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : 64000"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
            <Col span={8} sm={12}>
              <FormField label="Ville" errors={errors} name="address.city">
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : Pau"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>

          <Row>
            <Col span={8} fixed>
              <FormField label="Téléphone" errors={errors} name="phone">
                {({ name, ...childProps }) => (
                  <FormInput
                    placeholder="Ex : 01 02 03 04 05"
                    {...register(name)}
                    {...childProps}
                  />
                )}
              </FormField>
            </Col>
          </Row>
        </FormSection>

        <NewsletterPreferencesForm {...{ control, register, watch, errors }} />

        <FormSection>
          <FormField errors={errors} name="consent">
            {({ name, ...childProps }) => (
              <FormCheckbox {...register(name)} {...childProps}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: pageParameters.consentText || "",
                  }}
                />
              </FormCheckbox>
            )}
          </FormField>

          <FormButton type="submit">S'inscrire</FormButton>
        </FormSection>
      </SignupForm>
    </TwoColumnsPageTemplate>
  );
}
