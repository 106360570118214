import { aliasByType } from "../../common/tags.js";
import { useOneOfAKindPage } from "../services/navigation.js";
import { color, font, is, styled } from "../util/style.js";
import PageLink from "./PageLink.jsx";

const MAX_TAGS_TO_DISPLAY = 10;

const Tag = styled.li``;

const TagLink = styled(PageLink)`
  color: ${color("greyTag")};
  font-family: ${font("secondary")};
  transition: color 0.3s ease;
  &:hover {
    color: ${color("orange")};
    text-decoration: underline;
    transition: color 0.3s ease;
  }
`;

export default styled(function TagList({ tags, className }) {
  const [pageArticlesByTag] = useOneOfAKindPage("articlesByTag");
  const tagsToDisplay = (tags || [])
    .filter((tag) => !tag.startsWith("category:")) // Volonté client de ne pas afficher les tag catégories
    .slice(0, MAX_TAGS_TO_DISPLAY)
    .map((fullTag) => {
      const [type, tag] = fullTag.split(":", 2);
      return {
        label: tag,
        type: aliasByType[type] || type,
        tag,
      };
    });
  return (
    <ul className={className}>
      {tagsToDisplay.map(({ label, type, tag }) => (
        <Tag key={`${type}:${tag}`}>
          <TagLink
            to={pageArticlesByTag}
            parameters={{ type, tag }}
            itemProp="keywords"
            aria-label={`Voir les articles pour le thème « ${label} »`}
          >
            {label}
          </TagLink>
        </Tag>
      ))}
    </ul>
  );
})`
  display: flex;
  flex-wrap: wrap;

  & > ${Tag} + ${Tag}::before {
    background-color: ${color("greyTag")};
    border-radius: 50px;
    content: "";
    display: inline-block;
    margin: 0 6px;
    height: 4px;
    position: relative;
    top: -1px;
    width: 4px;
  }

  ${is("orange")`
    ${TagLink} {
      color: ${color("orange")}
    }
  `}
`;
